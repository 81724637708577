import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.css']
})
export class CountdownComponent implements OnInit {
  @Input() date_maintenance: string;

  date: string = ''
  constructor() { }

  ngOnInit() {
    this.date = this.date_maintenance
  }


}
