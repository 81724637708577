<app-nav></app-nav>
<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="square-jelly-box"
  [fullScreen]="true"
>
  <p style="color: white">Please Wait...</p>
</ngx-spinner>
<!-- Main form -->
<main class="page page-security-verification" *ngIf="!have_result">
  <!-- <aside class="header-image-half"></aside> -->
  <div *ngIf="maintenance" class="maintenance">
    <app-countdown [date_maintenance]="date_maintenance"></app-countdown>
  </div>

  <div *ngIf="!maintenance">
    <div class="container content-section">
      <h1
        class="content-section-title text-center text-uppercase"
        style="margin-top: 140px"
        translate="retrieve_application_id"
      >
        Retrieve Application ID
      </h1>
      <form
        #checkForm="ngForm"
        id="checkForm"
        (ngSubmit)="checkForm.form.valid && onSubmit(checkForm)"
        novalidate
      >
        <div class="row">
          <div class="col-lg-6 offset-lg-3">
            <input
              id="email"
              required
              [(ngModel)]="model_verify.email"
              #email="ngModel"
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,10}$"
              class="form-control form-control-lg"
              [ngClass]="{
                'is-invalid': checkForm.submitted && email.invalid
              }"
              name="email"
              [placeholder]="'Enter Email' | translate"
              translate
            />
            <div class="invalid-feedback">
              <div *ngIf="email.errors?.required">Email is required</div>
              <div *ngIf="email.errors?.pattern">Email invalid</div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 offset-lg-3">
            <input
              id="passport_number"
              required
              [(ngModel)]="model_verify.passport_number"
              #passport="ngModel"
              pattern="[a-zA-Z0-9]*"
              class="form-control form-control-lg"
              [ngClass]="{
                'is-invalid': checkForm.submitted && passport.invalid
              }"
              name="passport_number"
              [placeholder]="'Enter Passport Number' | translate"
            />
            <div class="invalid-feedback">
              <div *ngIf="checkForm.submitted && passport.errors?.required">
                Passport number is required
              </div>
              <div *ngIf="checkForm.submitted && passport.errors?.pattern">
                Passport number invalid
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 offset-lg-3">
            <div class="border img-rounded p-2 captcha">
              <img *ngIf="captchaImage" [src]="captchaImage" alt="" />
              &nbsp;
              <i
                class="fa fa-refresh"
                (click)="reloadCaptcha()"
                style="cursor: pointer; color: #1a77ba"
              ></i>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 offset-lg-3">
            <input
              autocomplete="off"
              (keyup)="message = ''"
              type="text"
              pattern="[0-9]+"
              maxlength="10"
              id="verify_code"
              required
              [ngClass]="{
                'is-invalid': checkForm.submitted && verify_codes.invalid
              }"
              [(ngModel)]="model_verify.verify_code"
              #verify_codes="ngModel"
              [placeholder]="'Enter security code' | translate"
              name="verify_code"
              class="form-control form-control-lg"
              id="verify_code"
            />
            <div
              *ngIf="
                (checkForm.submitted && verify_codes.invalid) ||
                this.isNotSuccess
              "
              class="invalid-feedback"
            >
              <div *ngIf="verify_codes.invalid">Security code is required</div>
            </div>
            <div
              style="font-size: 80%"
              class="text-danger"
              *ngIf="verify_codes.valid && this.isNotSuccess"
            >
              <p class="text-dark" [innerHTML]="message"></p>
            </div>
          </div>
        </div>
        <!-- TODO: ADD CAPTCHA -->

        <div class="row">
          <div class="col-lg-6 offset-lg-3 text-center mt-1">
            <button type="submit" class="btn btn-primary" translate>
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</main>

<main class="page page-status-enquiry" *ngIf="have_result">
  <!-- * -->
  <aside class="header-image-half"></aside>
  <div class="container content-section">
    <div class="row box-center">
      <!-- <div class="col-md-4"></div> -->
      <!-- <div class="col-md-4" style="margin-top: 140px"> -->
        <div class="text-center">
          <h1 class="mt-0 pb-3" translate="">We have sent the Application ID(s) to <b>({{your_email}})</b></h1>
          <h3 translate="">Please check your inbox</h3>
        </div>
        <div class="text-center mt-3 mb-3 p-1 ">
            <p *ngIf="isMail.includes('gmail')">
              <button
                (click)="openInbox()"
                class="btn btn-light"
                style="border: 1px solid #b1b3b5"
              >
                <img src="assets/images/gmail.png" height="20" />
                &nbsp;Open Gmail
              </button>
            </p>
            <p
              *ngIf="
                isMail.includes('myyahoo') ||
                isMail.includes('yahoo') ||
                isMail.includes('ymail')
              "
            >
              <button
                (click)="openInbox()"
                class="btn btn-light"
                style="border: 1px solid #b1b3b5"
              >
                <img src="assets/images/yahoo.png" height="20" />
                &nbsp;Open Yahoo
              </button>
            </p>
            <p *ngIf="isMail.includes('outlook') || isMail.includes('hotmail')">
              <button
                (click)="openInbox()"
                class="btn btn-light"
                style="border: 1px solid #b1b3b5"
              >
                <img src="assets/images/outlook.png" height="20" />
                &nbsp;Open Outlook
              </button>
            </p>
            <p *ngIf="isMail.split('.')[0].includes('qq')">
              <button
                (click)="openInbox()"
                class="btn btn-light"
                style="border: 1px solid #b1b3b5"
              >
                <img src="assets/images/qq.png" height="20" />
                &nbsp;Open QQ Mail
              </button>
            </p>
        </div>
      <!-- </div> -->
      <!-- <div class="col-md-4"></div> -->
    </div>

    <button class="btn btn-primary btn-sm pull-right" (click)="back()">
      <i class="fa fa-chevron-left"></i>
      <span translate="back">Back</span>
    </button>
  </div>
</main>
<app-footer></app-footer>
