<app-nav></app-nav>
<br>
<br>
<br>
<br>
<br>
<main class="page page-security-verification">
    <div class="container content-section">
        <div class="row shadow">
            <div class="col-md-12 p-5 text-danger" style="font-size: 22px;">
                <div class="text-center font-weight-bold">
                    <h2 *ngIf="app.SelectedLang == 'en'"><u><b>Government Notice!</b></u></h2>
                    <h2 *ngIf="app.SelectedLang == 'cn'"><u><b>政府公告!</b></u></h2>
                </div>
                <br>
                <p *ngIf="app.SelectedLang == 'en'">
                    Applicant should be informed that all nationalities travelling from China Mainland will not be eligible to apply for Lao eVisa during the outbreak period of Novel coronavirus.
                </p>
                <p *ngIf="app.SelectedLang == 'cn'">
                    我们在此通知各位来自中国大陆的人员知晓， 在新型冠状病毒爆发期间，你们将无法申请电子签证。如果老挝移民局的警察认为申请人来自中国大陆地区，将禁止该申请者进入老挝。
                </p>
                <p *ngIf="app.SelectedLang == 'en'">
                    If the Lao Immigration Officer recognizes that applicant is travelling from China Mainland, applicant will be denied entry to the Lao PDR at the arrival ports.
                </p>

            </div>
            <div class="col-md-12 text-center p-3">
                <div>
                    <div class="form-group form-check">
                        <input [ngModel]="isCheck" (ngModelChange)="onFilterChange($event)" type="checkbox" class="form-check-input" id="confirmCheckBox">
                        <label *ngIf="app.SelectedLang == 'en'" class="form-check-label" for="confirmCheckBox">I have read and understood the above message and confirm that I'm not traveling from China to the Lao PDR.</label>
                        <label *ngIf="app.SelectedLang == 'cn'" class="form-check-label" for="confirmCheckBox">我已经阅读并了解上述内容，并确认我并没有经中国进入老挝。</label>
                      </div>
                </div>
                <div class="d-flex justify-content-center">
                    <a (click)="confirm('yes')" [ngClass]="{'disabled': isCheck == false}" class="btn btn-success2 btn-lg mr-2" tabindex="-1" role="button"
                        aria-disabled="true">Accept</a>
                    <a (click)="confirm('no')" class="btn btn-danger btn-lg" tabindex="-1" role="button"
                        aria-disabled="true">Decline</a>
                </div>
            </div>
        </div>

    </div>
</main>
