<div class="popup-container">
  <div class="bg-color">
    <div class="header col-lg-12 col-md-12 text-center">
      <b>
        <div class="left-logo">
          <img src="../../../assets/images/navbar-logo2.png" style="width: 100%" alt="" />
        </div>

        <div class="warning pt-2 pb-2">
          <h1 class="font">WARNING!</h1>
        </div>

        <div class="side-logo">
          <img src="../../../assets/images/side-logo-1.png" style="width: 100%" alt="" />
        </div>
      </b>
    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 text-center mb-4 mt-4 text-danger">
      <div class="h3">
        <h3 class="font">Duplicate Record Detected</h3>
      </div>
    </div>

    <div class="box-content">
      <div class="text-center font-weight-bold rounded-10 MB-2">
        <h5 class="description">
          The system has detected duplicated passport details in multiple
          applications as the following details:
        </h5>
      </div>

      <div class="duplicate-items justify-content-center row ml-4 mr-4 pl-3 pr-3" *ngIf="checkArrivalDate">
        <div class="duplicate-item-card col-lg-12 col-md-12 col-sm-12 row justify-content-between mt-3 mb-1 pt-1"
          style="background-color: rgb(231, 229, 229)" *ngFor="let item of checkArrivalDate">
          <div class="col-lg-3 col-md-3 col-sm-3">
            <div class="text-center mt-4 mr-2 ml-2 pt-4 pb-4" style="border: solid #515151 2px; border-radius: 15px">
              <h5 class="text-success">{{ item.status }}</h5>
              <li>Application</li>
            </div>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-4 mt-3">
            <h5 class="details-header">Passport Details:</h5>
            <li>Passport No: {{ item.passport_number }}</li>
            <li>Date of Issue: {{ item.issue_date | date : "yyyy-MMM-dd" }}</li>
            <li>
              Date of Exipry: {{ item.expired_date | date : "yyyy-MMM-dd" }}
            </li>
          </div>
          <div class="col-lg-5 col-md-5 col-sm-5 mt-3">
            <h5 class="details-header">Application Details:</h5>
            <li>
              Created Date: {{ item.created_date | date : "yyyy-MMM-dd" }}
            </li>
            <li>Application ID: {{ item.application_id }}</li>
            <li>Payment Status: {{ item.status }}</li>
            <li>
              Date of Entry: {{ item.intended_date | date : "yyyy-MMM-dd" }}
            </li>
          </div>
        </div>
      </div>

      <div class="text-center font-weight-bold rounded-10 MB-2 mt-2">
        <h5 class="description">
          Please contact our support team at
          <a routerLink="/contact">laoevisa.gov.la/contact</a> if you wish to
          proceed with multiple applications.
        </h5>
      </div>

      <!-- <div class="text-center font-weight-bold rounded-10 MB-2 mt-2">
                <h5 class="description">Please check your existing application's status and pay for eVisa fee at <a href="/checkstatus">laoevisa.gov.la/checkstatus</a></h5>
            </div> -->

      <!-- <div class="text-center mt-3">
                <h5 class="description">Please be reminded that eVisa fee is non-refundable</h5>
            </div> -->

      <div class="col-lg-12 row d-flex mt-2 justify-content-center">
        <div class="col-lg-6 col-md-6 col-sm-12 justify-content-center row">
          <button class="btn btn-continue" id="closebutton" mat-dialog-close (click)="onClose()">
            <h4>Close</h4>
          </button>
        </div>
      </div>
    </div>
    <div class="space"></div>
  </div>
</div>