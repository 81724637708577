import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-partner-login',
  templateUrl: './partner-login.component.html',
  styleUrls: ['./partner-login.component.css']
})
export class PartnerLoginComponent implements OnInit {
  model: any = {};
  constructor(private rest: RestService, private router: Router) { }

  ngOnInit() {
  }
  partnerLogin(form: any) {
    this.rest.partnerLogin(form.value).subscribe(result => {
      if (result.status === 'success') {
        localStorage.setItem('partnerAuthen', JSON.stringify(result));
        this.router.navigate(['/partner-dashboard']);
      }
    });
  }
}
