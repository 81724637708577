import { Component, OnInit } from "@angular/core"
import { MatDialog } from "@angular/material"
import { Router } from "@angular/router"
import { EventEmitterService } from "src/app/services/event-emitter.service"
import { RestService } from "src/app/services/rest.service"
import { ServiceSEOService } from "src/app/services/service-seo.service"
import { CheckArrivalComponent } from "../check-arrival/check-arrival.component"
@Component({
  selector: "app-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.css"],
})
export class FaqComponent implements OnInit {
  FAQGroup: any;
  constructor(
    private seo: ServiceSEOService,
    private rest: RestService,
    private eventEmitterService: EventEmitterService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.seo.addMetaTags()
  }

  ngOnInit() {
    if (this.eventEmitterService.subsVar === undefined) {
      this.eventEmitterService.subsVar =
        this.eventEmitterService.invokeFAQComponentFunction.subscribe(
          (language: string) => {
            if (this.router.url === "/faq") {
              // this.getFAQ(language);
              this.getFAQGroup(language);
            }
          }
        )
    }

    const language = localStorage.getItem("selectedLang");
    this.getFAQGroup(language);
    // this.getFAQ(language);
  }

  getFAQGroup(language: string) {
    this.rest.getFAQGroup(language).subscribe((result) => {
      this.FAQGroup = result;
    });
  }

  onOpen() {
    this.dialog.open(CheckArrivalComponent, {
      backdropClass: "backdropBackground",
    })
  }
}
