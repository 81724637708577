<app-nav></app-nav>
<main class="page page-FAQ">
  <aside class="header-image-half"></aside>

  <div class="container content-section">
    <h3 class="content-section-title text-right mt-0" translate="text_faq"></h3>
    <div class="accordion faq-frame" id="jsFAQAccordion">
      <article class="faq-item" *ngFor="let group of FAQGroup; let idx = index">
        <div class="faq-header">
          <h1 class="mb-0 d-flex justify-content-between">
            <button type="button" data-toggle="collapse" [attr.data-target]="'#collapse' + idx" aria-expanded="true"
              class="btn btn-link text-left btn-question accordion-button">
              <h4>{{ group.group_name }}</h4>
            </button>
            <button data-toggle="collapse" [attr.data-target]="'#collapse' + idx"
              class="btn btn-primary btn-toggle collapsed" translate="viewmore"></button>
          </h1>
        </div>

        <div id="collapse{{ idx }}" class="collapse mx-4" data-parent="#jsFAQAccordion" *ngFor="let faq of group.faq">
          <div class="faq-answer">
            <h6 class="mb-0 d-flex justify-content-between">
              {{ faq.faq_title }}
            </h6>
            <div class="shadow-sm round mt-3 faq-anser-detail" >
              <div class="faq-answer text-secondary pl-4" [innerHTML]="faq.faq_answer"></div>
            </div>
          </div>
        </div>
      </article>
    </div>
  </div>
</main>
<app-footer></app-footer>
