import * as tslib_1 from "tslib";
import * as i0 from "@angular/core";
import * as i1 from "./rest.service";
export class CountCartService {
    constructor(rest) {
        this.rest = rest;
        this.countCart = 0;
    }
    funcCountCart() {
        this.rest.getCountCart().subscribe((result) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (result != null) {
                this.countCart = yield result[0].countCart;
            }
        }));
    }
}
CountCartService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CountCartService_Factory() { return new CountCartService(i0.ɵɵinject(i1.RestService)); }, token: CountCartService, providedIn: "root" });
