/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./faq.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../nav/nav.component.ngfactory";
import * as i5 from "../nav/nav.component";
import * as i6 from "../../app.component";
import * as i7 from "../../services/form-data.service";
import * as i8 from "../../services/rest.service";
import * as i9 from "../../services/event-emitter.service";
import * as i10 from "../../services/count-cart.service";
import * as i11 from "@angular/router";
import * as i12 from "../footer/footer.component.ngfactory";
import * as i13 from "../footer/footer.component";
import * as i14 from "./faq.component";
import * as i15 from "../../services/service-seo.service";
import * as i16 from "@angular/material/dialog";
var styles_FaqComponent = [i0.styles];
var RenderType_FaqComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FaqComponent, data: {} });
export { RenderType_FaqComponent as RenderType_FaqComponent };
function View_FaqComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "collapse mx-4"], ["data-parent", "#jsFAQAccordion"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "faq-answer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h6", [["class", "mb-0 d-flex justify-content-between"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "shadow-sm round mt-3 faq-anser-detail"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "faq-answer text-secondary pl-4"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "collapse", _v.parent.context.index, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.faq_title; _ck(_v, 3, 0, currVal_1); var currVal_2 = _v.context.$implicit.faq_answer; _ck(_v, 5, 0, currVal_2); }); }
function View_FaqComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "article", [["class", "faq-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "faq-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "h1", [["class", "mb-0 d-flex justify-content-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-expanded", "true"], ["class", "btn btn-link text-left btn-question accordion-button"], ["data-toggle", "collapse"], ["type", "button"]], [[1, "data-target", 0]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "btn btn-primary btn-toggle collapsed"], ["data-toggle", "collapse"], ["translate", "viewmore"]], [[1, "data-target", 0]], null, null, null, null)), i1.ɵdid(7, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FaqComponent_2)), i1.ɵdid(9, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_3 = "viewmore"; _ck(_v, 7, 0, currVal_3); var currVal_4 = _v.context.$implicit.faq; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = ("#collapse" + _v.context.index); _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.group_name; _ck(_v, 5, 0, currVal_1); var currVal_2 = ("#collapse" + _v.context.index); _ck(_v, 6, 0, currVal_2); }); }
export function View_FaqComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-nav", [], null, null, null, i4.View_NavComponent_0, i4.RenderType_NavComponent)), i1.ɵdid(1, 4308992, null, 0, i5.NavComponent, [i6.AppComponent, i7.FormDataService, i8.RestService, i9.EventEmitterService, i10.CountCartService, i11.Router, i11.ActivatedRoute, i1.PLATFORM_ID], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 7, "main", [["class", "page page-FAQ"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "aside", [["class", "header-image-half"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "container content-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h3", [["class", "content-section-title text-right mt-0"], ["translate", "text_faq"]], null, null, null, null, null)), i1.ɵdid(6, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "accordion faq-frame"], ["id", "jsFAQAccordion"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FaqComponent_1)), i1.ɵdid(9, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-footer", [], null, null, null, i12.View_FooterComponent_0, i12.RenderType_FooterComponent)), i1.ɵdid(11, 114688, null, 0, i13.FooterComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = "text_faq"; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.FAQGroup; _ck(_v, 9, 0, currVal_1); _ck(_v, 11, 0); }, null); }
export function View_FaqComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-faq", [], null, null, null, View_FaqComponent_0, RenderType_FaqComponent)), i1.ɵdid(1, 114688, null, 0, i14.FaqComponent, [i15.ServiceSEOService, i8.RestService, i9.EventEmitterService, i11.Router, i16.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FaqComponentNgFactory = i1.ɵccf("app-faq", i14.FaqComponent, View_FaqComponent_Host_0, {}, {}, []);
export { FaqComponentNgFactory as FaqComponentNgFactory };
