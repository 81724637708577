<app-nav></app-nav>
<main class="page page-contact-us">
  <div>
    <aside class="header-image-half"></aside>

    <div class="container content-section pb-4">
      <h3 class="content-section-title text-right mt-0 text-uppercase" translate>
        Contact us
      </h3>

      <p translate="contact_text1">
        If you have any doubts, suggestions, complaints or need any type of
        information, feel free to contact us! Our staff will be happy to help you.
      </p>
      <p translate="contact_text2">
        Please contact us through our email&nbsp;<strong>support@laoevisa.gov.la</strong>
      </p>
      <p *ngIf="app.SelectedLang == 'cn'">
        为了方便沟通以免出现错误 请您将用英语沟通
      </p>
    </div>

    <!--
     SOCIAL
     ================================================================================
    -->

    <!--<section class="container content-section pt-0">
      <h5 class="font-weight-bold text-center text-lg-left">Follow us on</h5>

      <div class="d-flex flex-column flex-lg-row align-items-center">
        <aside class="mt-2 mt-lg-0">
          <a href="#" target="_blank" class="btn btn-primary btn-icon d-inline-flex">
            <svg aria-hidden="true" class="svg-inline&#45;&#45;fa fa-facebook-f fa-w-10" data-icon="facebook-f" data-prefix="fab" focusable="false" role="img" viewBox="0 0 320 512" xmlns="http://www.w3.org/2000/svg"><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" fill="currentColor"></path></svg>
            <span class="ml-2">eVisa Laos</span>
          </a>
        </aside>
        <aside class="ml-lg-4 mr-lg-4 mt-4 mt-lg-0 mb-4 mb-lg-0">
          <a href="#" target="_blank" class="btn btn-primary btn-icon d-inline-flex">
            <svg aria-hidden="true" class="svg-inline&#45;&#45;fa fa-instagram fa-w-14" data-icon="instagram" data-prefix="fab" focusable="false" role="img" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" fill="currentColor"></path></svg>
            <span class="ml-2">eVisa Laos</span>
          </a>
        </aside>
        <aside class="social-wechat">
          <a href="#" target="_blank">Wechat</a>
        </aside>
      </div>
    </section>-->

    <!--
     CONTACT FORM
     ================================================================================
    -->

    <section class="container content-section pt-0">
      <form *ngIf="isSuccess == false" (ngSubmit)="contactForm.form.valid && onSubmit(contactForm)"
        #contactForm="ngForm" id="contactForm" novalidate>
        <fieldset>
          <legend class="section-header-legend" translate="contact_form_title">
            Send email to our support
          </legend>
          <div class="form-control-wrapper">
            <div class="row">
              <div class="col-lg-6">
                <input type="text" maxlength="100" required pattern="[a-zA-Z ]*" #firstNames="ngModel"
                  [(ngModel)]="firstName" name="firstName" id="firstName" class="form-control" [ngClass]="{
                  'is-invalid': contactForm.submitted && firstNames.invalid
                }" [placeholder]="'Name' | translate" />
                <div class="invalid-feedback">
                  <div *ngIf="firstNames.errors?.pattern">Name is invalid</div>
                  <div *ngIf="firstNames.invalid && !firstNames.errors?.pattern">
                    Name is required
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <input type="email" maxlength="100" required pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                  #emails="ngModel" [(ngModel)]="email" name="email" id="email" class="form-control" [ngClass]="{
                  'is-invalid': contactForm.submitted && emails.invalid
                }" [placeholder]="'Email' | translate" />
                <div class="invalid-feedback">
                  <div *ngIf="emails.errors?.pattern">Email is invalid</div>
                  <div *ngIf="emails.invalid && !emails.errors?.pattern">
                    Email is required
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <input type="text" maxlength="200" required pattern="[a-zA-Z ]*" #titles="ngModel" [(ngModel)]="title"
                  name="title" id="title" class="form-control" [ngClass]="{
                  'is-invalid': contactForm.submitted && titles.invalid
                }" [placeholder]="'Subject' | translate" />
                <div class="invalid-feedback">
                  <div *ngIf="titles.errors?.pattern">Subject is invalid</div>
                  <div *ngIf="titles.invalid && !titles.errors?.pattern">
                    Subject is required
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <textarea name="description" style="height: 150px" maxlength="500" required class="form-control"
                  id="description" #descriptions="ngModel" [(ngModel)]="description" cols="30" rows="10"
                  [placeholder]="'How we can help you?' | translate" [ngClass]="{
                  'is-invalid': contactForm.submitted && descriptions.invalid
                }"></textarea>
                <div class="invalid-feedback">
                  <div *ngIf="descriptions.errors?.pattern">
                    Description is invalid
                  </div>
                  <div *ngIf="descriptions.invalid && !descriptions.errors?.pattern">
                    Description is required
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="border img-rounded p-2 captcha">
                  <img *ngIf="captchaImage" [src]="captchaImage" alt="" />
                  <!-- <img class="bg-light border p-1 mb-1 rounded" style="width:150px;" *ngIf="captchaImage" [src]="captchaImage"> -->
                  &nbsp;
                  <i class="fa fa-refresh" (click)="reloadCaptcha()" style="cursor: pointer; color: #1a77ba"></i>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <input type="text" (keyup)="message = ''" maxlength="10" required pattern="[0-9]*"
                  #verify_codes="ngModel" [(ngModel)]="verify_code" name="verify_code" id="verify_code"
                  class="form-control mt-2" [placeholder]="'Enter security code' | translate" [ngClass]="{
                  'is-invalid': contactForm.submitted && verify_codes.invalid
                }" />
                <div class="invalid-feedback">
                  <div *ngIf="verify_codes.errors?.pattern">
                    Security code is invalid
                  </div>
                  <div *ngIf="verify_codes.invalid && !verify_codes.errors?.pattern">
                    Security code is required
                  </div>
                </div>
              </div>
            </div>
            <!-- <button mat-button (click)="openDialog()">Open dialog</button> -->
            <div class="row">
              <div class="col text-right mt-1">
                <button type="submit" class="btn btn-primary" translate>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </section>

    <!--
     GOOGLE MAP
     ================================================================================
    -->

    <section class="container content-section pt-0">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe width="425" height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
          src="https://www.openstreetmap.org/export/embed.html?bbox=102.61881172657014%2C17.97341368037656%2C102.62264728546143%2C17.97566902992813&amp;layer=mapnik&amp;marker=17.974540252524694%2C102.62072822777554">
        </iframe>
      </div>
    </section>
  </div>

</main>
<app-footer></app-footer>