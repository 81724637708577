<app-nav></app-nav>
<br>
<br>
<br>
<br>
<br>
<main class="page page-security-verification">
    <div class="container content-section">
        <div class="row shadow">
            <div class="col-md-12 p-5 text-dark" style="font-size: 22px;">
                <div class="text-center text-danger font-weight-bold">
                    <h2 *ngIf="app.SelectedLang == 'en'"><u><b>Government Notice!</b></u></h2>
                    <h2 *ngIf="app.SelectedLang == 'cn'"><u><b>政府公告!</b></u></h2>
                </div>
                <br>
                <div *ngIf="app.SelectedLang == 'en'" [innerHTML]="description"></div>    
                <div *ngIf="app.SelectedLang == 'cn'" [innerHTML]="description_cn"></div>    
            </div>
        </div>

    </div>
</main>