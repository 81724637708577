<div class="login-bg d-flex justify-content-center">
  <div class="bg-white col-md-3 p-5 align-self-center rounded">
      <div class="d-flex justify-content-start">
        <img src="assets/images/eVisa.png" width="45" height="40" alt="">
      </div>
   
    <h3 class="mt-3 font-weight-bold">Sign in</h3>
    <form #loginForm="ngForm" id="loginForm" (ngSubmit)="loginForm.form.valid && partnerLogin(loginForm)" class="mt-3">
      <input required autofocus type="text" name="username" [(ngModel)]="model.username" id="username" class="form-control" placeholder="Username">

      <input required type="password" name="password" [(ngModel)]="model.password" id="password" class="form-control mt-2" placeholder="Password">
      <div class="mt-5">
        <button class="btn btn-primary btn-sm pull-right pl-3 pr-3">Sign in</button>
      </div>
    </form>
  </div>
</div>