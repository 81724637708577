<app-nav></app-nav>
<section>
  <app-header></app-header>
</section>
<section class="section-apply-step container content-section text-center">
  <div class="row mt-3 mt-sm-3">
    <div class="col-12 mb-5">
      <h1 class="content-section-title" translate>
        {{
        app.SelectedLang == "en"
        ? "3 SIMPLE STEPS TO APPLY"
        : "电子签证申请的 3 个简易步骤"
        }}
      </h1>
    </div>
    <div class="col-lg-4">
      <div class="apply-step-item d-flex flex-column justify-content-center align-items-center">
        <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="file-alt"
          class="svg-inline--fa fa-file-alt fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512">
          <path fill="currentColor"
            d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zm-22.6 22.7c2.1 2.1 3.5 4.6 4.2 7.4H256V32.5c2.8.7 5.3 2.1 7.4 4.2l83.9 83.9zM336 480H48c-8.8 0-16-7.2-16-16V48c0-8.8 7.2-16 16-16h176v104c0 13.3 10.7 24 24 24h104v304c0 8.8-7.2 16-16 16zm-48-244v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm0 64v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm0 64v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12z">
          </path>
        </svg>
        <div class="apply-step-button text-uppercase" data-index="1">
          {{ app.SelectedLang == "en" ? "Apply" : "申请" }}
        </div>
        <div class="apply-step-desc">
          {{
          app.SelectedLang == "en"
          ? "Fill the application form"
          : "填写详细信息并上传必要的文件"
          }}
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="apply-step-item d-flex flex-column justify-content-center align-items-center">
        <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="credit-card"
          class="svg-inline--fa fa-credit-card fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 576 512">
          <path fill="currentColor"
            d="M528 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM48 64h480c8.8 0 16 7.2 16 16v48H32V80c0-8.8 7.2-16 16-16zm480 384H48c-8.8 0-16-7.2-16-16V224h512v208c0 8.8-7.2 16-16 16zm-336-84v8c0 6.6-5.4 12-12 12h-72c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12zm192 0v8c0 6.6-5.4 12-12 12H236c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12z">
          </path>
        </svg>
        <div class="apply-step-button text-uppercase" data-index="2">
          {{ app.SelectedLang == "en" ? "Pay" : "付款" }}
        </div>
        <div class="apply-step-desc">
          {{
          app.SelectedLang == "en"
          ? "Pay your fees with secured payment method"
          : "再次检查您填写的所有信息，并在线支付费用"
          }}
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="apply-step-item d-flex flex-column justify-content-center align-items-center">
        <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="file-download"
          class="svg-inline--fa fa-file-download fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512">
          <path fill="currentColor"
            d="M369.9 97.98L286.02 14.1c-9-9-21.2-14.1-33.89-14.1H47.99C21.5.1 0 21.6 0 48.09v415.92C0 490.5 21.5 512 47.99 512h288.02c26.49 0 47.99-21.5 47.99-47.99V131.97c0-12.69-5.1-24.99-14.1-33.99zM256.03 32.59c2.8.7 5.3 2.1 7.4 4.2l83.88 83.88c2.1 2.1 3.5 4.6 4.2 7.4h-95.48V32.59zm95.98 431.42c0 8.8-7.2 16-16 16H47.99c-8.8 0-16-7.2-16-16V48.09c0-8.8 7.2-16.09 16-16.09h176.04v104.07c0 13.3 10.7 23.93 24 23.93h103.98v304.01zM208 216c0-4.42-3.58-8-8-8h-16c-4.42 0-8 3.58-8 8v88.02h-52.66c-11 0-20.59 6.41-25 16.72-4.5 10.52-2.38 22.62 5.44 30.81l68.12 71.78c5.34 5.59 12.47 8.69 20.09 8.69s14.75-3.09 20.09-8.7l68.12-71.75c7.81-8.2 9.94-20.31 5.44-30.83-4.41-10.31-14-16.72-25-16.72H208V216zm42.84 120.02l-58.84 62-58.84-62h117.68z">
          </path>
        </svg>
        <div class="apply-step-button text-uppercase" data-index="3">
          {{ app.SelectedLang == "en" ? "Download" : "下载" }}
        </div>
        <div class="apply-step-desc">
          {{
          app.SelectedLang == "en"
          ? "Download the eVisa approval from your email"
          : "通过电子邮件下载电子签证批准函。"
          }}
        </div>
      </div>
    </div>
  </div>
  <a routerLink="/apply" class="btn btn-lg btn-blue apply-step-btn-apply text-uppercase mt-5" translate>Apply now</a>
</section>

<!--
   VIDEO
   ================================================================================
  -->
<!-- GLOBAL VIDEO SECTTION -->
<ng-container *ngIf="webDefaultLang == 'en'">
  <section class="mb-lg-5 container content-section pt-lg-0 pb-lg-0">
    <h3 class="video-title">
      {{
      app.SelectedLang == "en"
      ? "Lao eVisa - Lao China Railway Introduction"
      : "老撾电子签证 - 老中鐵路簡介"
      }}
    </h3>
    <div class="embed-responsive embed-responsive-16by9">
      <iframe *ngIf="app.SelectedLang == 'en'" class="embed-responsive-item"
        src="https://www.youtube.com/embed/P-6lpz2AmBM?rel=0&vq=hd720" allowfullscreen></iframe>
      <iframe *ngIf="app.SelectedLang == 'cn'" class="embed-responsive-item"
        src="https://www.youtube.com/embed/CQ4wL5kD0Po?rel=0&vq=hd720" allowfullscreen></iframe>
    </div>
  </section>
  <br />
  <br />
  <section class="mb-lg-5 container content-section pt-lg-0 pb-lg-0">
    <h3 class="video-title">
      {{ app.SelectedLang == "en" ? "Lao eVisa Services" : "老撾电子签证服務" }}
    </h3>
    <div class="embed-responsive embed-responsive-16by9">
      <iframe *ngIf="app.SelectedLang == 'en'" class="embed-responsive-item"
        src="https://www.youtube.com/embed/xF7hn7PGnqo?rel=0&vq=hd720" allowfullscreen></iframe>
      <iframe *ngIf="app.SelectedLang == 'cn'" class="embed-responsive-item"
        src="https://www.youtube.com/embed/ahO4fvWdxFA?rel=0&vq=hd720" allowfullscreen></iframe>
    </div>
  </section>
  <br />
  <br />
  <section class="mb-lg-5 container content-section pt-lg-0 pb-lg-0">
    <h3 class="video-title">
      {{ app.SelectedLang == "en" ? "The Amazing Laos" : "老撾的神奇" }}
    </h3>
    <div class="embed-responsive embed-responsive-16by9">
      <iframe *ngIf="app.SelectedLang == 'en'" class="embed-responsive-item"
        src="https://www.youtube.com/embed/DUnrPMPTqwA?rel=0&vq=hd720" allowfullscreen></iframe>
      <iframe *ngIf="app.SelectedLang == 'cn'" class="embed-responsive-item"
        src="https://www.youtube.com/embed/pe-q1vCildI?rel=0&vq=hd720" allowfullscreen></iframe>
    </div>
  </section>
</ng-container>
<!-- GLOBAL VIDEO SECTTION -->

<!-- CHINESE VIDEO SECTION -->
<ng-container *ngIf="webDefaultLang == 'cn'">
  <section class="mb-lg-5 container content-section pt-lg-0 pb-lg-0">
    <h3 class="video-title">
      {{
      app.SelectedLang == "en"
      ? "Lao eVisa - Lao China Railway Introduction"
      : "老撾电子签证 - 老中鐵路簡介"
      }}
    </h3>
    <div class="embed-responsive embed-responsive-16by9">
      <iframe frameborder="0" *ngIf="app.SelectedLang == 'en'" class="embed-responsive-item"
        src="https://www.ixigua.com/iframe/7232122211270984231?autoplay=0" referrerpolicy="unsafe-url"
        allowfullscreen></iframe>
      <iframe frameborder="0" *ngIf="app.SelectedLang == 'cn'" class="embed-responsive-item"
        src="https://www.ixigua.com/iframe/7231465902942716419?autoplay=0" referrerpolicy="unsafe-url"
        allowfullscreen></iframe>
    </div>
  </section>
  <br />
  <br />
  <section class="mb-lg-5 container content-section pt-lg-0 pb-lg-0">
    <h3 class="video-title">
      {{ app.SelectedLang == "en" ? "Lao eVisa Services" : "老撾电子签证服務" }}
    </h3>
    <div class="embed-responsive embed-responsive-16by9">
      <iframe frameborder="0" *ngIf="app.SelectedLang == 'en'" class="embed-responsive-item"
        src="https://www.ixigua.com/iframe/7232126762157933060?autoplay=0" referrerpolicy="unsafe-url"
        allowfullscreen></iframe>

      <iframe frameborder="0" *ngIf="app.SelectedLang == 'cn'" class="embed-responsive-item"
        src="https://www.ixigua.com/iframe/7231464394629055037?autoplay=0" referrerpolicy="unsafe-url"
        allowfullscreen></iframe>
    </div>
  </section>
  <br />
  <br />
  <section class="mb-lg-5 container content-section pt-lg-0 pb-lg-0">
    <h3 class="video-title">
      {{ app.SelectedLang == "en" ? "The Amazing Laos" : "老撾的神奇" }}
    </h3>
    <div class="embed-responsive embed-responsive-16by9">
      <iframe frameborder="0" *ngIf="app.SelectedLang == 'en'" class="embed-responsive-item"
        src="https://www.ixigua.com/iframe/7232118103612916280?autoplay=0" referrerpolicy="unsafe-url"
        allowfullscreen></iframe>
      <iframe frameborder="0" *ngIf="app.SelectedLang == 'cn'" class="embed-responsive-item"
        src="https://www.ixigua.com/iframe/7232118103612916280?autoplay=0" referrerpolicy="unsafe-url"
        allowfullscreen></iframe>
    </div>
  </section>
</ng-container>
<!-- CHINESE VIDEO SECTION -->

<!--
   INFORMATION
   ================================================================================
  -->

<section class="section-info-carousel container content-section">
  <h2 class="content-section-title text-right mt-0 text-uppercase">
    {{ app.SelectedLang == "en" ? "Information" : "信息" }}
  </h2>
  <div class="glide" id="jsInformationSlider">
    <div class="glide__track" data-glide-el="track">
      <ul class="glide__slides">
        <li class="info-carousel-item-wrapper">
          <a class="info-carousel-item" routerLink="/info" style="background-color: #1b75ba">
            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="users"
              class="svg-inline--fa fa-users fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512">
              <path fill="currentColor"
                d="M544 224c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-128c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM320 256c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm0-192c44.1 0 80 35.9 80 80s-35.9 80-80 80-80-35.9-80-80 35.9-80 80-80zm244 192h-40c-15.2 0-29.3 4.8-41.1 12.9 9.4 6.4 17.9 13.9 25.4 22.4 4.9-2.1 10.2-3.3 15.7-3.3h40c24.2 0 44 21.5 44 48 0 8.8 7.2 16 16 16s16-7.2 16-16c0-44.1-34.1-80-76-80zM96 224c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-128c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zm304.1 180c-33.4 0-41.7 12-80.1 12-38.4 0-46.7-12-80.1-12-36.3 0-71.6 16.2-92.3 46.9-12.4 18.4-19.6 40.5-19.6 64.3V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-44.8c0-23.8-7.2-45.9-19.6-64.3-20.7-30.7-56-46.9-92.3-46.9zM480 432c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16v-44.8c0-16.6 4.9-32.7 14.1-46.4 13.8-20.5 38.4-32.8 65.7-32.8 27.4 0 37.2 12 80.2 12s52.8-12 80.1-12c27.3 0 51.9 12.3 65.7 32.8 9.2 13.7 14.1 29.8 14.1 46.4V432zM157.1 268.9c-11.9-8.1-26-12.9-41.1-12.9H76c-41.9 0-76 35.9-76 80 0 8.8 7.2 16 16 16s16-7.2 16-16c0-26.5 19.8-48 44-48h40c5.5 0 10.8 1.2 15.7 3.3 7.5-8.5 16.1-16 25.4-22.4z">
              </path>
            </svg>
            <div class="info-carousel-item-label">
              {{ app.SelectedLang == "en" ? "Who can Apply?" : "谁可以申请" }}
            </div>
          </a>
        </li>
        <li class="info-carousel-item-wrapper">
          <a class="info-carousel-item" routerLink="/info" style="background-color: #ffc600">
            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="question-circle"
              class="svg-inline--fa fa-question-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512">
              <path fill="currentColor"
                d="M256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28zm7.67-24h-16c-6.627 0-12-5.373-12-12v-.381c0-70.343 77.44-63.619 77.44-107.408 0-20.016-17.761-40.211-57.44-40.211-29.144 0-44.265 9.649-59.211 28.692-3.908 4.98-11.054 5.995-16.248 2.376l-13.134-9.15c-5.625-3.919-6.86-11.771-2.645-17.177C185.658 133.514 210.842 116 255.67 116c52.32 0 97.44 29.751 97.44 80.211 0 67.414-77.44 63.849-77.44 107.408V304c0 6.627-5.373 12-12 12zM256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8z">
              </path>
            </svg>
            <div class="info-carousel-item-label">
              {{ app.SelectedLang == "en" ? "How to Apply?" : "如何申请" }}
            </div>
          </a>
        </li>
        <li class="info-carousel-item-wrapper">
          <a class="info-carousel-item" routerLink="/info" style="background-color: #ff3a1e">
            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="info-circle"
              class="svg-inline--fa fa-info-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512">
              <path fill="currentColor"
                d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-36 344h12V232h-12c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h48c6.627 0 12 5.373 12 12v140h12c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12h-72c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12zm36-240c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32z">
              </path>
            </svg>
            <div class="info-carousel-item-label">
              {{
              app.SelectedLang == "en" ? "Document Information" : "文件信息"
              }}
            </div>
          </a>
        </li>
        <li class="info-carousel-item-wrapper">
          <a class="info-carousel-item" routerLink="/info" style="background-color: #c8cacb">
            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="clock"
              class="svg-inline--fa fa-clock fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512">
              <path fill="currentColor"
                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm216 248c0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216zm-148.9 88.3l-81.2-59c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h14c6.6 0 12 5.4 12 12v146.3l70.5 51.3c5.4 3.9 6.5 11.4 2.6 16.8l-8.2 11.3c-3.9 5.3-11.4 6.5-16.8 2.6z">
              </path>
            </svg>
            <div class="info-carousel-item-label" translate>
              {{ app.SelectedLang == "en" ? "Processing Time" : "处理时间" }}
            </div>
          </a>
        </li>
      </ul>
    </div>
    <nav class="glide__arrows" data-glide-el="controls">
      <button class="glide__arrow glide__arrow--left" data-glide-dir="<">
        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="angle-left"
          class="svg-inline--fa fa-angle-left fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 192 512">
          <path fill="currentColor"
            d="M4.2 247.5L151 99.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17L69.3 256l118.5 119.7c4.7 4.7 4.7 12.3 0 17L168 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 264.5c-4.7-4.7-4.7-12.3 0-17z">
          </path>
        </svg>
      </button>
      <button class="glide__arrow glide__arrow--right" data-glide-dir=">">
        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="angle-right"
          class="svg-inline--fa fa-angle-right fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 192 512">
          <path fill="currentColor"
            d="M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z">
          </path>
        </svg>
      </button>
    </nav>
  </div>
</section>

<!--
   PAYMENT CHANNELS
   ================================================================================
  -->

<section
  class="section-payment-channel container content-section d-flex flex-column justify-content-center align-items-center mb-lg-0">
  <div class="payment-channel-text-logo"></div>
  <div class="payment-channel-logo-group">
    <img width="100%" src="assets/images/payment-methods.png" alt="" />
  </div>
  <br />
  <br />

  <div class="text-center">
    <table>
      <tr>
        <td colspan="2">
          <h4 class="text-success font-weight-bold">SECURED BY</h4>
        </td>
      </tr>
      <tr>
        <!-- <td>
          <div class="text-center">
            <table width="135" border="0" cellpadding="2" cellspacing="0"
              title="Click to Verify - This site chose GeoTrust SSL for secure e-commerce and confidential communications.">
              <tr>
                <td width="135" align="center" valign="top">
                  <script type="text/javascript"
                    src="https://seal.geotrust.com/getgeotrustsslseal?host_name=laoevisa.gov.la&amp;size=S&amp;lang=en">
                  </script><br />
                  <a href="http://www.geotrust.com/ssl/" target="_blank"
                    style="color:#000000; text-decoration:none; font:bold 7px verdana,sans-serif; letter-spacing:.5px; text-align:center; margin:0px; padding:0px;"></a>
                </td>
              </tr>
            </table>
          </div>
        </td> -->
        <td>
          <table width="135" border="0" cellpadding="2" cellspacing="0"
            title="Click to Verify - This site chose GeoTrust SSL for secure e-commerce and confidential communications.">
            <tr>
              <td width="135" align="center" valign="top">
                <script type="text/javascript"
                  src="https://seal.geotrust.com/getgeotrustsslseal?host_name=https://www.laoevisa.gov.la&amp;size=M&amp;lang=en"></script>
                <br />
                <a href="http://www.geotrust.com/ssl/" target="_blank" style="
                    color: #000000;
                    text-decoration: none;
                    font: bold 7px verdana, sans-serif;
                    letter-spacing: 0.5px;
                    text-align: center;
                    margin: 0px;
                    padding: 0px;
                  "></a>
              </td>
              <td>
                <a href="#"
                  onclick="window.open('https://www.sitelock.com/verify.php?site=laoevisa.gov.la','SiteLock','width=600,height=600,left=160,top=170');"><img
                    class="img-responsive" alt="SiteLock" title="SiteLock"
                    src="//shield.sitelock.com/shield/laoevisa.gov.la" /></a>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </div>
</section>

<ng-template #content let-modal>
  <div>
    <div class="d-flex justify-content-between mt-3">
      <div class="bg-dark"></div>
      <div>
        <h2 class="text-red animated swing">
          <i class="fa fa-warning"></i> &nbsp;
          <span translate>Important Notice!</span>
        </h2>
      </div>
      <div class="mr-3">
        <!--  <button type="button" class="close" id="closeModal" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true" style="font-size: 40px; color: red;">&times;</span>
        </button> -->
      </div>
    </div>

    <!-- <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button> -->
  </div>
  <div class="modal-body">
    <img *ngIf="app.SelectedLang == 'en'" src="assets/images/Pop_Up-Message.png" class="img-fluid" alt="" />
    <img *ngIf="app.SelectedLang == 'cn'" src="assets/images/Pop_Up-Message_CH.png" class="img-fluid" alt="" />
    <!-- <img src="assets/images/Pop_Up-Message_CH.png" class="img-fluid" alt="" /> -->
  </div>
  <div class="d-flex justify-content-between">
    <div class="p-2">
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </div>
    <div class="p-2">
      <a class="font-weight-bold font-size:14" href="/term" translate="termsandcondition" target="_blank">Terms and
        Conditions
      </a>
    </div>
    <div class="p-2">
      <button type="button" class="btn btn-blue" (click)="modal.close('Close click')">
        {{ app.SelectedLang == "cn" ? "同意" : "I Agree" }}
      </button>
    </div>
  </div>
</ng-template>

<app-footer></app-footer>