/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-cropper.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./image-cropper.component";
import * as i3 from "@angular/platform-browser";
var styles_ImageCropperComponent = [i0.styles];
var RenderType_ImageCropperComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImageCropperComponent, data: {} });
export { RenderType_ImageCropperComponent as RenderType_ImageCropperComponent };
export function View_ImageCropperComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { sourceImage: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 24, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["sourceImage", 1]], null, 0, "img", [["class", "source-image"]], [[8, "src", 4], [4, "visibility", null]], [[null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (_co.imageLoadedInView() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 22, "div", [["class", "cropper"]], [[2, "rounded", null], [4, "top", "px"], [4, "left", "px"], [4, "width", "px"], [4, "height", "px"], [4, "margin-left", null], [4, "visibility", null]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "move"]], null, [[null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.startMove($event, "move") !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (_co.startMove($event, "move") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "resize topleft"]], null, [[null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.startMove($event, "resize", "topleft") !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (_co.startMove($event, "resize", "topleft") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "span", [["class", "square"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "resize top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "span", [["class", "square"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "resize topright"]], null, [[null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.startMove($event, "resize", "topright") !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (_co.startMove($event, "resize", "topright") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "span", [["class", "square"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [["class", "resize right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "span", [["class", "square"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [["class", "resize bottomright"]], null, [[null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.startMove($event, "resize", "bottomright") !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (_co.startMove($event, "resize", "bottomright") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "span", [["class", "square"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "span", [["class", "resize bottom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, "span", [["class", "square"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "span", [["class", "resize bottomleft"]], null, [[null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.startMove($event, "resize", "bottomleft") !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (_co.startMove($event, "resize", "bottomleft") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 0, "span", [["class", "square"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "span", [["class", "resize left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 0, "span", [["class", "square"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 0, "span", [["class", "resize-bar top"]], null, [[null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.startMove($event, "resize", "top") !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (_co.startMove($event, "resize", "top") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 0, "span", [["class", "resize-bar right"]], null, [[null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.startMove($event, "resize", "right") !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (_co.startMove($event, "resize", "right") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 0, "span", [["class", "resize-bar bottom"]], null, [[null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.startMove($event, "resize", "bottom") !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (_co.startMove($event, "resize", "bottom") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 0, "span", [["class", "resize-bar left"]], null, [[null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.startMove($event, "resize", "left") !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (_co.startMove($event, "resize", "left") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.safeImgDataUrl; var currVal_1 = (_co.imageVisible ? "visible" : "hidden"); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.roundCropper; var currVal_3 = _co.cropper.y1; var currVal_4 = _co.cropper.x1; var currVal_5 = (_co.cropper.x2 - _co.cropper.x1); var currVal_6 = (_co.cropper.y2 - _co.cropper.y1); var currVal_7 = _co.marginLeft; var currVal_8 = (_co.imageVisible ? "visible" : "hidden"); _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_ImageCropperComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "image-cropper", [], [[4, "text-align", null]], [["window", "resize"], ["document", "mousemove"], ["document", "touchmove"], ["document", "mouseup"], ["document", "touchend"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize() !== false);
        ad = (pd_0 && ad);
    } if (("document:mousemove" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).moveImg($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:touchmove" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).moveImg($event) !== false);
        ad = (pd_2 && ad);
    } if (("document:mouseup" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).moveStop() !== false);
        ad = (pd_3 && ad);
    } if (("document:touchend" === en)) {
        var pd_4 = (i1.ɵnov(_v, 1).moveStop() !== false);
        ad = (pd_4 && ad);
    } return ad; }, View_ImageCropperComponent_0, RenderType_ImageCropperComponent)), i1.ɵdid(1, 573440, null, 0, i2.ImageCropperComponent, [i3.DomSanitizer, i1.ChangeDetectorRef, i1.NgZone], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).alignImage; _ck(_v, 0, 0, currVal_0); }); }
var ImageCropperComponentNgFactory = i1.ɵccf("image-cropper", i2.ImageCropperComponent, View_ImageCropperComponent_Host_0, { imageChangedEvent: "imageChangedEvent", imageBase64: "imageBase64", imageFile: "imageFile", format: "format", outputType: "outputType", maintainAspectRatio: "maintainAspectRatio", aspectRatio: "aspectRatio", resizeToWidth: "resizeToWidth", resizeToHeight: "resizeToHeight", cropperMinWidth: "cropperMinWidth", cropperMinHeight: "cropperMinHeight", roundCropper: "roundCropper", onlyScaleDown: "onlyScaleDown", imageQuality: "imageQuality", autoCrop: "autoCrop", backgroundColor: "backgroundColor", containWithinAspectRatio: "containWithinAspectRatio", cropper: "cropper", alignImage: "alignImage" }, { imageCropped: "imageCropped", startCropImage: "startCropImage", imageLoaded: "imageLoaded", cropperReady: "cropperReady", loadImageFailed: "loadImageFailed" }, []);
export { ImageCropperComponentNgFactory as ImageCropperComponentNgFactory };
