<app-nav></app-nav>
<!-- Unpaid Status -->
<main class="page page-status-enquiry">
    <!-- * -->
    <aside class="header-image-half"></aside>
    <div class="container content-section">
        <h1 class="content-section-title text-left mt-0">
            PAY YOUR FEEs
        </h1>
        <hr>
        <div class="row mt-4">
            <div class="col-lg-5 column">
                <div *ngIf="status == 'Paid'">
                    <div class="d-flex flex-row align-items-center mb-3">
                        <i style="font-size:100px;color: #2ecc71;" class="fa fa-check-circle"></i>
                        <h2 class="ml-4" style="color:#2DCB71;">Payment Successful</h2>
                    </div>

                </div>
                <div class="text-left">
                    <div class="">Description: {{detail}} </div>
                    <div class="">Amount: <span class="font-weight-bold">{{amount}} USD </span> </div>
                    <div *ngIf="status == 'Paid'">
                        <div class="">Paid Date: <span class="font-weight-bold">{{paid_date}} </span></div>
                    </div>
                </div>

                <br>
                <br>

                <div class="row" *ngIf="status == 'Unpaid'">
                    <div class="col">
                        <small>We accept</small><br>
                        <img width="200" src="assets/images/payment-method-main.png" alt="">
                    </div>
                    <div class="col d-flex justify-content-end align-items-center">
                        <button (click)="proceedCheckOut()"
                            class="btn btn-warning d-inline-flex ml-lg-auto mt-3 mt-lg-0">
                            <span class="ml-2">Pay Now</span>
                        </button>
                    </div>
                    <br>
                    <br>
                </div>

                <br>
                <p class="text-secondary" style="font-size: 12px;">If your payment are success, please contact eVisa
                    support center at
                    support@laoevisa.gov.la </p>
            </div>
        </div>

        <footer class="text-muted mt-lg-4">
        </footer>
    </div>
</main>


<div *ngIf="model">
    <form ngNoForm id='payment_form' #bcelForm action='{{model.url}}' method='post'>
        <input type='hidden' name='access_key' [(ngModel)]="model.access_key" />
        <input type='hidden' name='bill_companyname' [(ngModel)]="model.bill_companyname" />
        <input type='hidden' name='amount' [(ngModel)]="model.amount" />
        <input type='hidden' name='bill_to_address_city' [(ngModel)]="model.bill_to_address_city" />
        <input type='hidden' name='bill_to_address_country' [(ngModel)]="model.bill_to_address_country" />
        <input type='hidden' name='bill_to_address_line1' [(ngModel)]="model.bill_to_address_line1" />
        <input type='hidden' name='bill_to_address_line2' [(ngModel)]="model.bill_to_address_line2" />
        <input type='hidden' name='bill_to_address_postal_code' [(ngModel)]="model.bill_to_address_postal_code" />
        <input type='hidden' name='bill_to_address_state' [(ngModel)]="model.bill_to_address_state" />
        <input type='hidden' name='bill_to_email' [(ngModel)]="model.bill_to_email" />
        <input type='hidden' name='bill_to_forename' [(ngModel)]="model.bill_to_forename" />
        <input type='hidden' name='bill_to_phone' [(ngModel)]="model.bill_to_phone" />
        <input type='hidden' name='bill_to_surname' [(ngModel)]="model.bill_to_surname" />
        <input type='hidden' name='currency' [(ngModel)]="model.currency" />
        <input type='hidden' name='device_fingerprint_id' [(ngModel)]="model.device_fingerprint_id" />
        <input type='hidden' name='lang' [(ngModel)]="model.lang" />
        <input type='hidden' name='locale' [(ngModel)]="model.locale" />
        <input type='hidden' name='merchant_defined_data1' [(ngModel)]="model.merchant_defined_data1" />
        <input type='hidden' name='merchant_defined_data2' [(ngModel)]="model.merchant_defined_data2" />
        <input type='hidden' name='merchant_defined_data3' [(ngModel)]="model.merchant_defined_data3" />
        <input type='hidden' name='merchant_defined_data4' [(ngModel)]="model.merchant_defined_data4" />
        <input type='hidden' name='merchant_defined_data5' [(ngModel)]="model.merchant_defined_data5" />
        <input type='hidden' name='merchant_defined_data6' [(ngModel)]="model.merchant_defined_data6" />
        <input type='hidden' name='merchant_defined_data7' [(ngModel)]="model.merchant_defined_data7" />
        <input type='hidden' name='merchant_defined_data8' [(ngModel)]="model.merchant_defined_data8" />
        <input type='hidden' name='merchant_defined_data9' [(ngModel)]="model.merchant_defined_data9" />
        <input type='hidden' name='merchant_defined_data10' [(ngModel)]="model.merchant_defined_data10" />
        <input type='hidden' name='profile_id' [(ngModel)]="model.profile_id" />
        <input type='hidden' name='reference_number' [(ngModel)]="model.reference_number" />
        <input type='hidden' name='signed_date_time' [(ngModel)]="model.signed_date_time" />
        <input type='hidden' name='transaction_type' [(ngModel)]="model.transaction_type" />
        <input type='hidden' name='transaction_uuid' [(ngModel)]="model.transaction_uuid" />
        <input type='hidden' name='signed_field_names' [(ngModel)]="model.signed_field_names" />
        <input type='hidden' name='signature' [(ngModel)]="model.signature" />
    </form>
</div>
<app-footer></app-footer>