<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white">Please Wait...</p>
</ngx-spinner>
<header class="section-check-evisa">
  <section class="check-evisa-slider">
    <div class="glide" id="jsCheckeVisaSlider">
      <div class="glide__track" data-glide-el="track">
        <ul class="glide__slides">
          <!--
          <video class="glide__slide" muted>
            <source src="../../../assets/videos/video1.mp4">
          </video>
          <video class="glide__slide" muted>
            <source src="../../../assets/videos/video2.mp4">
          </video>
          <video class="glide__slide" muted>
            <source src="../../../assets/videos/video3.mp4">
          </video>
          <video class="glide__slide" muted>
            <source src="../../../assets/videos/video4.mp4">
          </video> -->


          <a routerLink="/apply">
            <li *ngIf="app.SelectedLang == 'en'" class="glide__slide"
              style="background-image: url('assets/images/wc-pay-banner.png')"></li>
            <li *ngIf="app.SelectedLang == 'cn'" class="glide__slide"
              style="background-image: url('assets/images/wc-pay-banner-cn.png')"></li>
          </a>
          <a routerLink="/apply">
            <li *ngIf="app.SelectedLang == 'en'" class="glide__slide"
              style="background-image: url('assets/images/web-slider-en.png')"></li>
            <li *ngIf="app.SelectedLang == 'cn'" class="glide__slide"
              style="background-image: url('assets/images/web-slider-cn.png')"></li>
          </a>
          <li class="glide__slide" style="background-image: url('assets/images/Aws-01.jpg')"></li>
          <li class="glide__slide" style="background-image: url('assets/images/Aws-02.jpg')"></li>
          <li class="glide__slide" style="background-image: url('assets/images/Aws-03.jpg')"></li>
        </ul>

        <!-- <ul *ngIf="!production" class="glide__slides">
          <a routerLink="/apply">
            <li *ngIf="app.SelectedLang == 'en'" class="glide__slide"
              [style.background-image]="'url('+ image_static_url +'web-slider-en.png)'"></li>
            <li *ngIf="app.SelectedLang == 'cn'" class="glide__slide"
              [style.background-image]="'url('+ image_static_url +'web-slider-cn.png)'"></li>
          </a>
          <a routerLink="/apply">
            <li class="glide__slide" style="background-image: url('assets/images/Aws-04.jpg')"></li>
          </a>
          <a routerLink="/apply">
            <li class="glide__slide"
              [style.background-image]="'url('+ image_static_url +'wc-pay-banner.png)'"></li>
            <li *ngIf="app.SelectedLang == 'cn'" class="glide__slide"
              [style.background-image]="'url('+ image_static_url +'wc-pay-banner-cn.png)'"></li>
          </a>
          <li class="glide__slide" style="background-image: url('assets/images/Aws-02.jpg')"></li>
        </ul> -->
      </div>
      <div class="glide__bullets" data-glide-el="controls[nav]">
        <button class="glide__bullet" data-glide-dir="=0"></button>
        <button class="glide__bullet" data-glide-dir="=1"></button>
        <button class="glide__bullet" data-glide-dir="=2"></button>
        <button class="glide__bullet" data-glide-dir="=3"></button>
        <button class="glide__bullet" data-glide-dir="=4"></button>
      </div>

    </div>
  </section>

  <form class="check-evisa-form" id="jsCheckVisaForm" #checkForm="ngForm" id="checkForm"
    (ngSubmit)="checkForm.form.valid && onSubmit(checkForm)" novalidate>
    <div *ngIf="maintenance" class="maintenance">
      <app-countdown [date_maintenance]="date_maintenance"></app-countdown>
    </div>

    <div *ngIf="!maintenance">
      <div class="text-center mt-5">
        <a routerLink="/apply" class="btn pa-6 btn-red animated bounceInDown" translate>Apply now</a>
      </div>

      <hr *ngIf="app.SelectedLang == 'en'" class="hr-text mt-5" data-content="OR" />
      <hr *ngIf="app.SelectedLang == 'cn'" class="hr-text mt-5" data-content="或" />

      <label translate>Check your eVisa status</label>
      <div class="row">
        <div class="col-lg-12">
          <input id="application_id" maxlength="15" required [(ngModel)]="model_verify.application_id"
            #application="ngModel" pattern="[a-zA-Z0-9]*" class="form-control form-control-lg" [ngClass]="{
              'is-invalid': checkForm.submitted && application.invalid
            }" name="application_id" [placeholder]="'Enter Application ID' | translate" translate />
          <div class="invalid-feedback">
            <div class="text-warning" *ngIf="application.errors?.required">
              Application ID is required
            </div>
            <div class="text-warning" *ngIf="application.errors?.pattern">
              Application ID invalid
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <input id="passport_number" maxlength="50" required [(ngModel)]="model_verify.passport_number"
            #passport="ngModel" class="form-control form-control-lg" [ngClass]="{
              'is-invalid': checkForm.submitted && passport.invalid
            }" pattern="[a-zA-Z0-9]*" name="passport_number" [placeholder]="'Enter Passport Number' | translate" />
          <div class="invalid-feedback">
            <div class="text-warning" *ngIf="checkForm.submitted && passport.errors?.required">
              Passport number is required
            </div>
            <div class="text-warning" *ngIf="checkForm.submitted && passport.errors?.pattern">
              Passport number invalid
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-1">
        <div class="col-lg-12">
          <div class="border img-rounded p-2 captcha bg-white">
            <img *ngIf="captchaImage" [src]="captchaImage" alt="" />
            &nbsp;
            <i class="fa fa-refresh" (click)="reloadCaptcha()" style="cursor: pointer; color: #1a77ba"></i>
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-lg-12">
          <input autocomplete="off" (keyup)="message = ''" type="text" pattern="[0-9]+" maxlength="10" id="verify_code"
            required [ngClass]="{
              'is-invalid': checkForm.submitted && verify_codes.invalid
            }" [(ngModel)]="model_verify.verify_code" #verify_codes="ngModel"
            [placeholder]="'Enter security code' | translate" name="verify_code" class="form-control form-control-lg"
            id="verify_code" />
          <div *ngIf="
              (checkForm.submitted && verify_codes.invalid) || this.isNotSuccess
            " class="invalid-feedback">
            <div class="text-warning" *ngIf="checkForm.submitted && verify_codes.invalid">
              Security code is required
            </div>
          </div>
          <div style="font-size: 80%" class="text-warning" *ngIf="verify_codes.valid && this.isNotSuccess">
            {{ this.message }}
          </div>
        </div>
      </div>
      <!-- TODO: ADD CAPTCHA -->

      <div class="text-right mt-1">
        <button type="submit" class="btn btn-yellow" translate>Check</button>
      </div>
    </div>
  </form>
</header>

<!-- Pending -->
<ng-template #contentPending let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-5 text-center column">
        <strong>YOUR VISA STATUS</strong>
        <div class="modal-check-visa-status-approval pending">PENDING</div>
        <p class="text-dark">
          Your application is processing, the processing time is no more than 03
          working days.
        </p>
      </div>
      <div class="col-lg-7 column">
        <div class="row">
          <div class="col-7 label">Application ID:</div>
          <div class="col-5">{{ model_verify.application_id }}</div>
        </div>
        <div class="row mt-1">
          <div class="col-7 label">Passport No:</div>
          <div class="col-5">{{ model_verify.passport_number }}</div>
        </div>
        <div class="row mt-1">
          <div class="col-7 label">Case creation date:</div>
          <div class="col-5">{{ created_date }}</div>
        </div>
        <div class="row mt-1">
          <div class="col-7 label">Status updated date:</div>
          <div class="col-5">{{ updated_date }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer"></div>
</ng-template>

<!-- Approved -->
<ng-template #contentApproved let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-5 text-center column">
        <strong>YOUR VISA STATUS</strong>
        <div class="modal-check-visa-status-approval approved">APPROVED</div>
        <p class="text-dark">
          Please go to your mailbox to download the Approval Letter.
          <br />
          OR
        </p>
        <div class="column text-center">
          <button [disabled]="clicked" (click)="downloadPdf(); clicked = true" class="btn btn-success">
            <i class="fa fa-arrow-circle-down" aria-hidden="true"></i>Download
          </button>
        </div>
      </div>
      <div class="col-lg-7 column">
        <div class="row">
          <div class="col-7 label">Application ID:</div>
          <div class="col-5">{{ model_verify.application_id }}</div>
        </div>
        <div class="row mt-1">
          <div class="col-7 label">Passport No:</div>
          <div class="col-5">{{ model_verify.passport_number }}</div>
        </div>
        <div class="row mt-1">
          <div class="col-7 label">Case creation date:</div>
          <div class="col-5">{{ created_date }}</div>
        </div>
        <div class="row mt-1">
          <div class="col-7 label">Status updated date:</div>
          <div class="col-5">{{ updated_date }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer"></div>
</ng-template>

<!-- Rejected -->
<ng-template #contentRejected let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-5 text-center column">
        <strong>YOUR VISA STATUS</strong>
        <div class="modal-check-visa-status-approval pending">PENDING</div>
      </div>
      <div class="col-lg-7 column">
        <div class="row">
          <div class="col-7 label">Application ID:</div>
          <div class="col-5">{{ model_verify.application_id }}</div>
        </div>
        <div class="row mt-1">
          <div class="col-7 label">Passport No:</div>
          <div class="col-5">{{ model_verify.passport_number }}</div>
        </div>
        <div class="row mt-1">
          <div class="col-7 label">Case creation date:</div>
          <div class="col-5">{{ created_date }}</div>
        </div>
        <div class="row mt-1">
          <div class="col-7 label">Status updated date:</div>
          <div class="col-5">{{ updated_date }}</div>
        </div>
      </div>
    </div>
  </div>
  <footer class="text-muted mt-lg-4 pl-5 pr-5 pb-5">
    <div class="font-weight-light">
      <li class="text-secondary" style="font-size: 14px" *ngFor="let reason of reasons">
        {{ reason.reason_rejected }}
      </li>
      <br />
      <li class="text-secondary" style="font-size: 14px; list-style: none">
        <b>Additional Comment:</b> {{ comment }}
      </li>
    </div>
    <hr />

    <button *ngIf="
        rejected &&
        (action_status == 'NOT_PASS' || action_status == 'Rejected') &&
        can_edit == true
      " class="btn btn-warning btn-sm pull-left" (click)="editApplication()">
      <i class="fa fa-edit"></i>
      Edit
    </button>
  </footer>
</ng-template>

<!-- Banned -->
<ng-template #contentBanned let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-5 text-center column">
        <strong>YOUR VISA STATUS</strong>
        <div class="modal-check-visa-status-approval rejected">REJECTED</div>
      </div>
      <div class="col-lg-7 column">
        <div class="row">
          <div class="col-7 label">Application ID:</div>
          <div class="col-5">{{ model_verify.application_id }}</div>
        </div>
        <div class="row mt-1">
          <div class="col-7 label">Passport No:</div>
          <div class="col-5">{{ model_verify.passport_number }}</div>
        </div>
        <div class="row mt-1">
          <div class="col-7 label">Case creation date:</div>
          <div class="col-5">{{ created_date }}</div>
        </div>
        <div class="row mt-1">
          <div class="col-7 label">Status updated date:</div>
          <div class="col-5">{{ updated_date }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="pl-5 font-weight-light">
      <!-- <li class="text-secondary" *ngFor="let reason of reasons" style="font-size: 14px">
        {{ reason.reason_rejected }}
      </li>
      <br />
      <li class="text-secondary" style="font-size: 14px; list-style: none">
        <b>Additional Comment:</b> {{ comment }}
      </li> -->
    </div>
  </div>
</ng-template>

<!-- Unpaid -->
<ng-template #contentUnpaid let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row mt-5">
      <div class="col-lg-5 text-center column">
        <div class="text-center">
          <strong>YOUR VISA STATUS</strong>
          <div class="modal-check-visa-status-approval pending">UNPAID</div>
        </div>
      </div>
      <div class="col-lg-6 offset-lg-1 column">
        <div class="row">
          <div class="col-7 label">Application ID:</div>
          <div class="col-5">{{ model_verify.application_id }}</div>
        </div>
        <div class="row mt-1">
          <div class="col-7 label">Passport No:</div>
          <div class="col-5">{{ model_verify.passport_number }}</div>
        </div>
        <div class="row mt-1">
          <div class="col-7 label">Case creation date:</div>
          <div class="col-5">{{ created_date }}</div>
        </div>
        <div class="row mt-1">
          <div class="col-7 label">Status updated date:</div>
          <div class="col-5">{{ updated_date }}</div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end mt-4">
      <div>
        <app-paynow [invoiceId]="invoice_id" payFrom="check_status"></app-paynow>
      </div>
    </div>
  </div>
  <div class="modal-footer"></div>
</ng-template>

<!-- Notfound -->
<ng-template #contentNotfound let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-5 text-center column">
        <strong>YOUR VISA STATUS</strong>
        <div class="modal-check-visa-status-approval rejected">NOT FOUND</div>
      </div>
      <div class="col-lg-7 column">
        <div class="text-center text-dark">
          <p>
            Sorry, Your application id or passport number is not found, please
            check and try again.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer"></div>
</ng-template>
<!-- Notfound -->
<ng-template #contentCancelled let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-5 text-center column">
        <strong>YOUR VISA STATUS</strong>
        <div class="modal-check-visa-status-approval rejected">CANCELLED</div>
      </div>
      <div class="col-lg-7 column">
        <div class="text-center text-dark font-weight-light">
          <p>
            Sorry, Your application id is cancelled. <br />
            because the invoice is unpaid for more than 7 days.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer"></div>
</ng-template>