/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nav-partner.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "./nav-partner.component";
var styles_NavPartnerComponent = [i0.styles];
var RenderType_NavPartnerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavPartnerComponent, data: {} });
export { RenderType_NavPartnerComponent as RenderType_NavPartnerComponent };
export function View_NavPartnerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 19, "nav", [["class", "navbar navbar-expand-lg navbar-light bg-white rounded"]], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i2.ɵm, [], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["class", "navbar-brand mr-5"], ["href", "/partner-dashboard"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", ""], ["class", "img-fluid"], ["src", "assets/images/eVisa.png"], ["width", "50"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["aria-controls", "navbarSupportedContent"], ["aria-expanded", "false"], ["aria-label", "Toggle navigation"], ["class", "navbar-toggler"], ["data-target", "#navbarSupportedContent"], ["data-toggle", "collapse"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [["class", "navbar-toggler-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 13, "div", [["class", "collapse navbar-collapse"], ["id", "navbarSupportedContent"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 6, "ul", [["class", "navbar-nav mr-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 5, "li", [["class", "nav-item active"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "a", [["class", "nav-link"], ["routerLink", "/partner-dashboard"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Dashboard "])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["(current)"])), (_l()(), i1.ɵeld(15, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 4, "ul", [["class", "navbar-nav mr-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 3, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 2, "button", [["class", "nav-link btn btn-outline-light"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logOut() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 0, "i", [["class", "fa fa-sign-out"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Sign out"]))], function (_ck, _v) { var currVal_2 = "/partner-dashboard"; _ck(_v, 11, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 11).target; var currVal_1 = i1.ɵnov(_v, 11).href; _ck(_v, 10, 0, currVal_0, currVal_1); }); }
export function View_NavPartnerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-nav-partner", [], null, null, null, View_NavPartnerComponent_0, RenderType_NavPartnerComponent)), i1.ɵdid(1, 114688, null, 0, i5.NavPartnerComponent, [i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavPartnerComponentNgFactory = i1.ɵccf("app-nav-partner", i5.NavPartnerComponent, View_NavPartnerComponent_Host_0, {}, {}, []);
export { NavPartnerComponentNgFactory as NavPartnerComponentNgFactory };
