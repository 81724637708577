<app-nav></app-nav>
<main class="page page-information">
  <!-- <aside class="header-image-half"></aside> -->
  <br>
  <br>
  <br>
  <br>
  <br>
  <div class="text-center">
    <img width="350" class="img-fluid" src="/assets/images/eVisa_Official_Logo_RGB_FA_01.png" alt="">
  </div>
  <div class="container content-section">

    <h1 class="font-weight-bold text-left mt-0" translate="about">About Lao eVisa
    </h1>

    <div class="row">
      <div class="col-md-12" [innerHTML]="model">
      </div>
      <div class="col-md-12">
        <img src="/assets/images/openeVisa.jpeg" alt="evisa" class="img-fluid">
      </div>
    </div>
  </div>

</main>
<app-footer></app-footer>