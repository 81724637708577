import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-partner-dashboard',
  templateUrl: './partner-dashboard.component.html',
  styleUrls: ['./partner-dashboard.component.css']
})
export class PartnerDashboardComponent implements OnInit {

  countAccess;
  countInApplication;
  countInPaid;
  partner_name;
  type;
  data;
  options;
  constructor(private rest: RestService) { }

  ngOnInit() {
    this.getAllAccess();
  }
  getAllAccess() {
    const auth = JSON.parse(localStorage.getItem('partnerAuthen'));
    if (auth) {
      const partner_id = auth.partner_id;
      this.partner_name = auth.partner_name;
      this.rest.getAllData(partner_id).subscribe(result => {
        this.countAccess = result.all_access;
        this.countInApplication = result.count_in_application;
        this.countInPaid = result.count_in_paid;

        this.type = 'bar';
        this.data = {
          labels: [],
          datasets: [
            {
              label: 'VISIT',
              backgroundColor: ['#FF7360'],
              data: [this.countAccess]
            }, {
              label: 'FILL FORM',
              backgroundColor: ['#05A6F0'],
              data: [this.countInApplication]
            }, {
              label: 'PAID',
              backgroundColor: ['#80BC0A'],
              data: [this.countInPaid]
            }
          ]
        };
        this.options = {
          responsive: true,
          maintainAspectRatio: false,
          showDatasetLabels : true
        };


      });
    }
  }


}
