<app-nav></app-nav>
<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="ball-pulse"
  [fullScreen]="true"
>
  <p style="color: white">Please Wait...</p>
</ngx-spinner>
<main class="page">
  <div class="container content-section">
    <h1
      class="content-section-title text-center text-uppercase mb-3"
      style="margin-top: 140px"
    >
      Application Enquiry
    </h1>
    <form
      *ngIf="!messageBlock"
      (ngSubmit)="applicationEnquiry(enquiryForm)"
      [formGroup]="enquiryForm"
    >
      <div class="row">
        <div class="col-lg-6 offset-lg-3">
          <input
            id="email"
            class="form-control form-control-lg"
            [ngClass]="{
              'is-invalid': submitted && nqryForm.email.invalid
            }"
            name="email"
            placeholder="Email"
            formControlName="email"
            type="email"
          />
          <div
            *ngIf="submitted && nqryForm.email.errors"
            class="invalid-feedback"
          >
            <div *ngIf="nqryForm.email.errors.required">Email is required</div>
            <div *ngIf="nqryForm.email.errors.pattern">Email invalid</div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 offset-lg-3">
          <input
            id="passport_number"
            class="form-control form-control-lg"
            [ngClass]="{
              'is-invalid': submitted && nqryForm.passport_number.invalid
            }"
            name="passport_number"
            placeholder="Enter Passport Number"
            formControlName="passport_number"
            maxlength="15"
            type="text"
          />
          <div
            *ngIf="submitted && nqryForm.passport_number.errors"
            class="invalid-feedback"
          >
            <div *ngIf="nqryForm.passport_number.errors.required">
              Passport number is required
            </div>
            <div *ngIf="nqryForm.passport_number.errors.pattern">
              Passport number invalid
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 offset-lg-3">
          <div class="border img-rounded p-2 captcha">
            <img *ngIf="captchaImg" [src]="captchaImg" alt="" />
            &nbsp;
            <i
              class="fa fa-refresh"
              (click)="getImgCaptcha()"
              style="cursor: pointer; color: #1a77ba"
            ></i>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 offset-lg-3">
          <input
            id="captcha_code"
            class="form-control form-control-lg"
            [ngClass]="{
              'is-invalid': submitted && nqryForm.captcha_code.invalid
            }"
            name="captcha_code"
            placeholder="Please type the characters above"
            formControlName="captcha_code"
            type="text"
            minlength="6"
            maxlength="6"
            autocomplete="off"
          />
          <div
            *ngIf="submitted && nqryForm.captcha_code.errors"
            class="invalid-feedback"
          >
            <div *ngIf="nqryForm.captcha_code.errors.required">
              Security code is required
            </div>
            <div *ngIf="nqryForm.captcha_code.errors.pattern">
              Security code is invalid
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 offset-lg-3 text-center mt-2">
          <button type="submit" class="btn btn-primary">
            REQUEST&nbsp;<i class="fa fa-arrow-right"></i>
          </button>
        </div>
      </div>
    </form>
    <div *ngIf="messageBlock" class="card m-5">
      <div class="card-body text-center">
        <p>{{ responseMsg }}</p>
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <a
              href="https://mail.google.com/mail/u/0/#inbox"
              target="_blank"
              rel="noreferrer"
              class="btn btn-gmail"
              >Open Gmail</a
            >
          </div>
          <div class="col-lg-3 col-md-6">
            <a
              href="https://outlook.live.com/mail/0/"
              target="_blank"
              rel="noreferrer"
              class="btn btn-outlook"
              >Open Outlook</a
            >
          </div>
          <div class="col-lg-3 col-md-6">
            <a
              [href]="yahooUrl"
              target="_blank"
              rel="noreferrer"
              class="btn btn-yahoo"
              >Open Yahoo!</a
            >
          </div>
          <div class="col-lg-3 col-md-6">
            <a
              href="https://mail.qq.com/"
              target="_blank"
              rel="noreferrer"
              class="btn btn-qq"
              >Open QQ</a
            >
          </div>
        </div>
        <hr class="hr-text" data-content="OR" />
        <a
          [href]="eMailLink"
          target="_blank"
          rel="noreferrer"
          class="btn btn-success"
          >Check Mail Box</a
        >
      </div>
    </div>
    <div *ngIf="messageBlock" class="row">
      <div class="col-lg-6 offset-lg-3 text-center mt-1">
        <button type="button" class="btn btn-primary" (click)="backToForm()">
          <i class="fa fa-arrow-left"></i>&nbsp;BACK
        </button>
      </div>
    </div>
  </div>
</main>
<app-footer></app-footer>
