<app-nav></app-nav>
<main class="page page-information">
  <!-- <aside class="header-image-half"></aside> -->
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <div class="container content-section">
    <h1 class="font-weight-bold text-left mt-0" translate="evisa_fee">
      eVisa fee calculator
    </h1>
    <br />
    <br />
    <div class="row">
      <div class="col-md-12 bg-white">
        <form>
          <div class="form-row">
            <div class="col-7">
              <span class="text-secondary" translate
                >Nationality as in passport</span
              >
              <select
                name=""
                id=""
                class="form-control form-control-lg"
                (change)="onCountryChange($event.target.value, $event)"
              >
                <option value="" translate>
                  -- Please select your nationality --
                </option>
                <option
                  [value]="item.country_id"
                  *ngFor="let item of mDataArrayCountry"
                >
                  {{ item.country_name }}
                </option>
              </select>
            </div>
            <div class="col-1 d-flex justify-content-center">
              <div class="">
                <span class="text-secondary">&nbsp;</span>
                <h1 style="color: #a5ce00">=</h1>
              </div>
            </div>
            <div class="col">
              <span class="text-secondary" translate>Per applicant</span>
              <input
                type="text"
                [value]="total"
                class="form-control form-control-lg font-weight-bold bg-white"
                readonly
              />
            </div>
          </div>
          <div class="form-row p-1">
            <div class="alert alert-secondary" role="alert" *ngIf="is_except">
              <h1 class="text-red mt-2 font-weight-bold text-center">Notice</h1>
              <br />
              <!-- <p class="text-secondary"><span class="font-weight-bold text-blue"
                  style="font-size: 20px;">{{citizens}}</span> citizens do not require a Visa to visit the Lao PDR, you
                can stay in the Lao PDR for <span class="text-blue font-weight-bold">{{days}} days</span> without a
                visa. Then, you are required to exit the country and may return at your convenience.</p>
              <br>
              <p class="text-secondary">However, by obtaining an eVisa, you can stay in the Lao PDR up to 30 days and
                your stay permit can be extended at the Immigration office without having to leave the Lao PDR.</p> -->

              <p *ngIf="app.SelectedLang == 'en'" class="text-secondary">
                Citizens of
                <span
                  class="font-weight-bold text-blue"
                  style="font-size: 20px"
                  >{{ citizens }}</span
                >
                are allowed to enter the Lao PDR without visa requirement for a
                maximum stay of
                <span class="text-blue font-weight-bold">{{ days }} days</span>
                per visit. <br />
              </p>
              <p *ngIf="app.SelectedLang == 'en'" class="text-secondary">
                Meanwhile, by obtaining an eVisa, visitors may stay in the Lao
                PDR up to 30 days and the stay permit can be extended at the
                Immigration office in the Lao PDR before the expiry date.
              </p>
              <p *ngIf="app.SelectedLang == 'cn'" class="text-secondary">
                <span
                  class="font-weight-bold text-blue"
                  style="font-size: 20px"
                  >{{ citizens }}</span
                >
                国家居民不需要申请赴老挝的签证，无须您可以免签证在老挝停留
                <span class="text-blue font-weight-bold">{{ days }} 天。</span
                >30 天 后您必须离开老挝，并在您需要时再来老挝
              </p>
              <br />
              <p *ngIf="app.SelectedLang == 'cn'" class="text-secondary">
                但是，您如果申请了电子签证，可以在老挝停留30天，您无须离境就能向移民局申请延长在老挝的停留时间。
              </p>
            </div>
          </div>
          <div class="form-row mt-4">
            <span class="text-danger">*</span> &nbsp;
            <span class="text-secondary" style="font-size: 14px" translate
              >Excluding Bank Charge Fee</span
            >
          </div>
          <div class="form-row">
            <a
              routerLink="/apply"
              class="btn btn-lg btn-blue apply-step-btn-apply text-uppercase mt-5"
              translate
              >Apply now</a
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</main>
<app-footer></app-footer>
