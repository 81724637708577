import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
export class ServiceSEOService {
    constructor(meta, title) {
        this.meta = meta;
        this.title = title;
    }
    addMetaTags() {
        // if (environment.defaultLang == "cn") {
        //   this.title.setTitle(
        //     "老挝电子签证 - 老挝官方电子签证 - 在线获取老挝电子签证"
        //   )
        //   this.meta.addTags([
        //     {
        //       name: "description",
        //       content:
        //         "Lao eVisa or Lao Visa Online is an Electronic Travel Authorization to enter Laos for tourism. Get your Laos Visa Now!",
        //     },
        //     { name: "viewport", content: "width=device-width, initial-scale=1" },
        //     { name: "robots", content: "INDEX, FOLLOW" },
        //     {
        //       name: "author",
        //       content: "Consular Department - Ministry of Foreign Affairs LAO PDR",
        //     },
        //     {
        //       name: "keywords",
        //       content:
        //         "老挝签证, 在线签证, 老挝在线签证, 老挝电子签证, 老挝官方电子签证, 电子签证, 老挝电子签证官网, 老挝签证中心官网, 老挝电子签证, 老挝官网签证",
        //     },
        //     { name: "date", content: "2019-07-09", scheme: "YYYY-MM-DD" },
        //     { httpEquiv: "Content-Type", content: "text/html" },
        //     { httpEquiv: "Content-Language", content: "en" },
        //     {
        //       property: "og:title",
        //       content: "Lao Visa Online | Lao eVisa Authorization Application",
        //     },
        //     { property: "og:type", content: "Service" },
        //     { charset: "UTF-8" },
        //   ])
        // } else {
        this.title.setTitle("Lao Official Online Visa - Get your lao eVisa online - Lao eVisa");
        this.meta.addTags([
            {
                name: "description",
                content: "Lao eVisa or Lao Visa Online is an Electronic Travel Authorization to enter Laos for tourism. Get your Laos Visa Now!",
            },
            // Google
            {
                itemprop: "name",
                content: "Lao Official Online Visa - Get your lao eVisa online - Lao eVisa",
            },
            {
                itemprop: "description",
                content: "Lao eVisa or Lao Visa Online is an Electronic Travel Authorization to enter Laos for tourism. Get your Laos Visa Now!",
            },
            {
                itemprop: "image",
                content: `${window.location.origin}/assets/images/eVisa_Official_Logo_RGB_FA_01.png`,
            },
            // Open Graph
            { property: "og:type", content: "website" },
            {
                property: "og:url",
                content: window.location.origin,
            },
            {
                property: "og:title",
                content: "Lao Visa Online | Lao eVisa Authorization Application",
            },
            {
                property: "og:description",
                content: "Lao eVisa or Lao Visa Online is an Electronic Travel Authorization to enter Laos for tourism. Get your Laos Visa Now!",
            },
            {
                property: "og:image",
                content: `${window.location.origin}/assets/images/eVisa_Official_Logo_RGB_FA_01.png`,
            },
            {
                property: "og:site_name",
                content: "Lao eVisa",
            },
            {
                property: "og:locale",
                content: navigator.language,
            },
            {
                property: "og:image:type",
                content: "image/png",
            },
            {
                property: "og:image:width",
                content: "600",
            },
            {
                property: "og:image:height",
                content: "600",
            },
            // Twitter
            {
                name: "twitter:card",
                content: "summary_large_image",
            },
            // {
            //   property: "twitter:url",
            //   content: window.location.origin,
            // },
            {
                property: "twitter:site",
                content: window.location.origin,
            },
            {
                name: "twitter:title",
                content: "Lao Visa Online | Lao eVisa Authorization Application",
            },
            {
                name: "twitter:description",
                content: "Lao eVisa or Lao Visa Online is an Electronic Travel Authorization to enter Laos for tourism. Get your Laos Visa Now!",
            },
            // {
            //   name: "twitter:image",
            //   content: `${window.location.origin}/assets/images/eVisa_Official_Logo_RGB_FA_01.png`,
            // },
            {
                name: "twitter:image:src",
                content: `${window.location.origin}/assets/images/eVisa_Official_Logo_RGB_FA_01.png`,
            },
            { name: "viewport", content: "width=device-width, initial-scale=1" },
            { name: "robots", content: "INDEX, FOLLOW" },
            {
                name: "author",
                content: "Consular Department - Ministry of Foreign Affairs LAO PDR",
            },
            {
                name: "keywords",
                content: "lao government e visa, official lao evisa,lao evisa, visa laos,laos e visa, e visa laos, evisa laos, e-visa laos, lao online visa, lao official online visa, lao visa, visa lao",
            },
            { name: "date", content: "2019-07-09", scheme: "YYYY-MM-DD" },
            { httpEquiv: "Content-Type", content: "text/html" },
            { httpEquiv: "Content-Language", content: "en" },
            { charset: "UTF-8" },
        ]);
        // }
    }
    addMetaTagsAbout() {
        // if (environment.defaultLang == "cn") {
        //   this.title.setTitle(
        //     "老挝电子签证 - 老挝官方电子签证 - 在线获取老挝电子签证"
        //   )
        //   this.meta.updateTag({
        //     name: "description",
        //     content:
        //       "Lao eVisa or Lao Visa Online is an Electronic Travel Authorization to enter Laos for tourism. Get your Laos Visa Now!",
        //   })
        //   this.meta.updateTag({
        //     name: "viewport",
        //     content: "width=device-width, initial-scale=1",
        //   })
        //   this.meta.updateTag({ name: "robots", content: "INDEX, FOLLOW" })
        //   this.meta.updateTag({
        //     name: "author",
        //     content: "Consular Department - Ministry of Foreign Affairs LAO PDR",
        //   })
        //   this.meta.updateTag({
        //     name: "keywords",
        //     content:
        //       "老挝签证, 在线签证, 老挝在线签证, 老挝电子签证, 老挝官方电子签证, 电子签证, 老挝电子签证官网, 老挝签证中心官网, 老挝电子签证, 老挝官网签证",
        //   })
        //   this.meta.updateTag({
        //     name: "date",
        //     content: "2019-07-09",
        //     scheme: "YYYY-MM-DD",
        //   })
        //   this.meta.updateTag({ httpEquiv: "Content-Type", content: "text/html" })
        //   this.meta.updateTag({ httpEquiv: "Content-Language", content: "en" })
        //   this.meta.updateTag({
        //     property: "og:title",
        //     content: "Lao Visa Online | Lao eVisa Authorization Application",
        //   })
        //   this.meta.updateTag({ property: "og:type", content: "Service" })
        //   this.meta.updateTag({ charset: "UTF-8" })
        // } else {
        this.title.setTitle("Lao Official Online Visa - Get your lao eVisa online - Lao eVisa");
        this.meta.updateTag({
            name: "description",
            content: "Lao eVisa or Lao Visa Online is an Electronic Travel Authorization to enter Laos for tourism. Get your Laos Visa Now!",
        });
        this.meta.updateTag({
            name: "viewport",
            content: "width=device-width, initial-scale=1",
        });
        this.meta.updateTag({ name: "robots", content: "INDEX, FOLLOW" });
        this.meta.updateTag({
            name: "author",
            content: "Consular Department - Ministry of Foreign Affairs LAO PDR",
        });
        this.meta.updateTag({
            name: "keywords",
            content: "lao government e visa, official lao evisa,lao evisa, visa laos, evisa laos, e visa laos, evisa laos, e-visa laos, lao online visa, lao official online visa",
        });
        this.meta.updateTag({
            name: "date",
            content: "2019-07-09",
            scheme: "YYYY-MM-DD",
        });
        this.meta.updateTag({ httpEquiv: "Content-Type", content: "text/html" });
        this.meta.updateTag({ httpEquiv: "Content-Language", content: "en" });
        this.meta.updateTag({
            property: "og:title",
            content: "Lao Visa Online | Lao eVisa Authorization Application",
        });
        this.meta.updateTag({ property: "og:type", content: "Service" });
        this.meta.updateTag({ charset: "UTF-8" });
    }
    // }
    addMetaTagsFee() {
        // if (environment.defaultLang == "cn") {
        //   this.title.setTitle(
        //     "老挝电子签证 - 老挝官方电子签证 - 在线获取老挝电子签证"
        //   )
        //   this.meta.updateTag({
        //     name: "description",
        //     content:
        //       "Lao eVisa or Lao Visa Online is an Electronic Travel Authorization to enter Laos for tourism. Get your Laos Visa Now!",
        //   })
        //   this.meta.updateTag({
        //     name: "viewport",
        //     content: "width=device-width, initial-scale=1",
        //   })
        //   this.meta.updateTag({ name: "robots", content: "INDEX, FOLLOW" })
        //   this.meta.updateTag({
        //     name: "author",
        //     content: "Consular Department - Ministry of Foreign Affairs LAO PDR",
        //   })
        //   this.meta.updateTag({
        //     name: "keywords",
        //     content: "老挝签证費, 老撾电子签证費用, 老撾电子签证的價格",
        //   })
        //   this.meta.updateTag({
        //     name: "date",
        //     content: "2019-07-09",
        //     scheme: "YYYY-MM-DD",
        //   })
        //   this.meta.updateTag({ httpEquiv: "Content-Type", content: "text/html" })
        //   this.meta.updateTag({ httpEquiv: "Content-Language", content: "en" })
        //   this.meta.updateTag({
        //     property: "og:title",
        //     content: "Lao Visa Online | Lao eVisa Authorization Application",
        //   })
        //   this.meta.updateTag({ property: "og:type", content: "Service" })
        //   this.meta.updateTag({ charset: "UTF-8" })
        // } else {
        this.title.setTitle("Lao Official Online Visa - Get your lao eVisa online - Lao eVisa");
        this.meta.updateTag({
            name: "description",
            content: "Lao eVisa or Lao Visa Online is an Electronic Travel Authorization to enter Laos for tourism. Get your Laos Visa Now!",
        });
        this.meta.updateTag({
            name: "viewport",
            content: "width=device-width, initial-scale=1",
        });
        this.meta.updateTag({ name: "robots", content: "INDEX, FOLLOW" });
        this.meta.updateTag({
            name: "author",
            content: "Consular Department - Ministry of Foreign Affairs LAO PDR",
        });
        this.meta.updateTag({
            name: "keywords",
            content: "lao visa fee,lao visa cost,lao e visa fee,lao e visa fee,laos visa cost,laos visa fees,laos e visa price",
        });
        this.meta.updateTag({
            name: "date",
            content: "2019-07-09",
            scheme: "YYYY-MM-DD",
        });
        this.meta.updateTag({ httpEquiv: "Content-Type", content: "text/html" });
        this.meta.updateTag({ httpEquiv: "Content-Language", content: "en" });
        this.meta.updateTag({
            property: "og:title",
            content: "Lao Visa Online | Lao eVisa Authorization Application",
        });
        this.meta.updateTag({ property: "og:type", content: "Service" });
        this.meta.updateTag({ charset: "UTF-8" });
    }
}
ServiceSEOService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ServiceSEOService_Factory() { return new ServiceSEOService(i0.ɵɵinject(i1.Meta), i0.ɵɵinject(i1.Title)); }, token: ServiceSEOService, providedIn: "root" });
