import * as tslib_1 from "tslib";
import * as i0 from "@angular/core";
import * as i1 from "./rest.service";
import * as i2 from "@angular/router";
export class CheckStatusApplyGuard {
    constructor(rest, router) {
        this.rest = rest;
        this.router = router;
        this.status = true;
    }
    canActivate(next, state) {
        this.rest.getIsCloseApply().subscribe((rs) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield localStorage.setItem('status', rs.status_can_apply);
        }));
        return this.status;
    }
}
CheckStatusApplyGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CheckStatusApplyGuard_Factory() { return new CheckStatusApplyGuard(i0.ɵɵinject(i1.RestService), i0.ɵɵinject(i2.Router)); }, token: CheckStatusApplyGuard, providedIn: "root" });
