<div class="popup-container">
  <div class="bg-color">
    <div class="header col-lg-12 col-md-12 text-center"><b>

        <div class="left-logo">
          <img src="../../../assets/images/navbar-logo2.png" style="width: 100%;" alt="">
        </div>

        <div class="warning pt-2 pb-2">
          <h1 class="font">WARNING!</h1>
        </div>

        <div class="side-logo">
          <img src="../../../assets/images/side-logo-1.png" style="width: 100%;" alt="">
        </div>


      </b></div>

    <div class="col-lg-12 col-md-12 col-sm-12 text-center mb-4 mt-4 text-danger">
      <div class="h3">
        <h3 class="font">Duplicate Record Detected</h3>
      </div>
    </div>

    <div class="box-content">
      <div class="text-center font-weight-bold rounded-2 mb-2">
        <h5 class="description">The system has detected duplicated passport details in multiple applications as the
          following details:</h5>
      </div>

      <div class="duplicate-items justify-content-center row ml-4 mr-4 " *ngIf="duplicateData">
        <div class="duplicate-item-card col-lg-12 col-md-12 col-sm-12 row justify-content-between mt-3 mb-1 pt-1"
          *ngFor="let item of duplicateData">
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="border-text text-center mt-4 mr-2 ml-2">
              <h5 class="text-success">{{item.status}}</h5>
              <h6>Application</h6>
            </div>
          </div>

          <div class="col-lg-4 col-md-12 col-sm-12 mt-3">
            <h5 class="details-header">Passport Details:</h5>
            <li class="list-text">Passport No: {{item.passport_number}}</li>
            <li class="list-text">Date of Issue: {{item.issue_date | date:'yyyy-MMM-dd'}}</li>
            <li class="list-text">Date of Exipry: {{item.expired_date | date:'yyyy-MMM-dd'}}</li>
          </div>
          <div class="col-lg-5 col-md-12 col-sm-12 mt-3">
            <h5 class="details-header">Application Details:</h5>
            <li class="list-text">Created Date: {{item.created_date | date:'yyyy-MMM-dd'}}</li>
            <li class="list-text">Application ID: {{item.application_id}}</li>
            <li class="list-text">Payment Status: {{item.status}}</li>
            <li class="list-text">Date of Entry: {{item.intended_date | date:'yyyy-MMM-dd'}}</li>
          </div>
        </div>
      </div>

      <div class=" row d-flex mt-5 justify-content-between">
        <div class="col-lg-5 col-md-5 col-sm-5 col-5 mx-2 justify-content-center row">
          <button class="btn btn-continue" id="closebutton" mat-dialog-close (click)="onContinue()">
            <h4 class="text-btn">Continue</h4>
          </button>
        </div>
        <div class="col-lg-5 col-md-5 col-sm-5 col-5 mx-2 justify-content-center row">
          <button class="btn btn-cancel" mat-dialog-close>
            <h4 class="text-btn">Cancel</h4>
          </button>
        </div>
      </div>

      <div class="col-lg-12 row d-flex mt-3 mx-1">
        <div class="col-lg-6 col-md-12 col-sm-12 text-center">
          <h5 class="list-text">If you wish to proceed with multiple applications.</h5>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 text-center">
          <h5 class="list-text">If you wish to discard the current application.</h5>
        </div>
      </div>

      <div class="text-center mt-3">
        <h5 class="description">Please be reminded that eVisa fee is non-refundable</h5>
      </div>

    </div>
    <div class="space"></div>
  </div>

</div>