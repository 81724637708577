import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ApplicationComponent } from "../application/application.component";

@Component({
  selector: "app-check-arrival",
  templateUrl: "./check-arrival.component.html",
  styleUrls: ["./check-arrival.component.css"],
})
export class CheckArrivalComponent implements OnInit {
  checkArrivalDate: any;
  constructor(
    public dialogRef: MatDialogRef<ApplicationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.checkArrivalDate = data;
  }

  ngOnInit() {}

  onClose() {
    this.dialogRef.close("close");
  }
}
