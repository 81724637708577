/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./countdown.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./countdown.component";
var styles_CountdownComponent = [i0.styles];
var RenderType_CountdownComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CountdownComponent, data: {} });
export { RenderType_CountdownComponent as RenderType_CountdownComponent };
export function View_CountdownComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "maintenance"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["style", "text-align: center;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Website is under Maintenance"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h4", [["style", "text-align: center; color: #fcbf06;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Will be back "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h4", [["class", "countdown"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["alt", "Maintenance"], ["src", "/assets/images/maintenance.png"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.date; _ck(_v, 7, 0, currVal_0); }); }
export function View_CountdownComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-countdown", [], null, null, null, View_CountdownComponent_0, RenderType_CountdownComponent)), i1.ɵdid(1, 114688, null, 0, i2.CountdownComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CountdownComponentNgFactory = i1.ɵccf("app-countdown", i2.CountdownComponent, View_CountdownComponent_Host_0, { date_maintenance: "date_maintenance" }, {}, []);
export { CountdownComponentNgFactory as CountdownComponentNgFactory };
