import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NgbDatepickerConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { saveAs } from "file-saver";
import { NgxSpinnerService } from "ngx-spinner";
import { AppComponent } from "src/app/app.component";
import { Person } from "src/app/models/formData";
import { RestService } from "src/app/services/rest.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  production = environment.production
  maintenance: boolean;
  date_maintenance: string;
  captchaImage: any;
  isImageLoading: boolean;
  application_id;
  passport_number;
  notfound = false;
  processing = false;
  rejected = false;
  approved = false;
  banned = false;
  can_edit = false;
  model_verify: any = {};
  message: string;
  isNotSuccess: boolean;
  have_result: boolean;
  isSuccess: boolean;
  reasons;
  created_date;
  updated_date;
  @ViewChild("contentPending", { static: true }) private contentPending;
  @ViewChild("contentApproved", { static: true }) private contentApproved;
  @ViewChild("contentRejected", { static: true }) private contentRejected;
  @ViewChild("contentBanned", { static: true }) private contentBanned;
  @ViewChild("contentUnpaid", { static: true }) private contentUnpaid;
  @ViewChild("contentNotfound", { static: true }) private contentNotfound;
  @ViewChild("contentCancelled", { static: true }) private contentCancelled;
  @Input() formData: FormData;
  @ViewChild("bcelForm", { static: false }) testFormEl;
  mPaymentMethod: any[];

  node_static_url = environment.node_static_url;
  image_static_url = environment.article_image_static_url;
  person: Person;
  mDataArrayPerson: any[];
  invoice_id = 0;
  total = 0;
  countTotal = 0;
  isEmptyCart = false;
  timeLeft = 0;
  interval;
  model;
  unpaid: boolean;
  cancelled: boolean;
  action_status: any;
  clicked = false;
  comment;
  my_uuid = "";
  temp_verify_code = 0;

  constructor(
    config: NgbDatepickerConfig,
    private router: Router,
    public app: AppComponent,
    private rest: RestService,
    private modalService: NgbModal,
    public spinner: NgxSpinnerService
  ) {
    config.minDate = { year: 1900, month: 1, day: 1 };
  }

  ngOnInit() {
    this.getImageFromService();
    this.getMaintenanceService();
  }

  getMaintenanceService() {
    // this.rest.getMaintenance().subscribe(
    //   (data) => {
    //     this.maintenance = data.maintenance;
    //     this.date_maintenance = data.date_maintenance;
    //   },
    //   (error) => {
    //     console.log(error);
    //   }
    // );
  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        this.captchaImage = reader.result;
      },
      false
    );
    if (image) {
      reader.readAsDataURL(image);
    }
  }
  getImageFromService() {
    this.isImageLoading = true;
    this.rest.getCaptchaImage().subscribe(
      (data: any) => {
        this.createImageFromBlob(data);
        this.isImageLoading = false;
      },
      (error) => {
        this.isImageLoading = false;
        console.log(error);
      }
    );
  }
  reloadCaptcha() {
    this.getImageFromService();
  }

  onSubmit(form: any) {
    this.processing = false;
    this.notfound = false;
    this.rejected = false;
    this.banned = false;
    this.clicked = false;
    if (form.valid) {
      this.rest
        .verifyCaptchaCheckStatus(this.model_verify)
        .subscribe((result) => {
          if (result.responseStatus === false) {
            this.isNotSuccess = true;
            this.message = "Security code is wrong!";
            this.getImageFromService();
          } else {
            this.getImageFromService();
            localStorage.setItem(
              environment.localAuthenInfo,
              JSON.stringify(result)
            );
            this.rest.checkStatus(this.model_verify).subscribe((rs) => {
              this.have_result = true;
              this.isSuccess = true;
              if (rs.status === "notfound") {
                this.notfound = true;
                this.openLg(this.contentNotfound);
                localStorage.removeItem(environment.localAuthenInfo);
              } else if (rs.status === "processing") {
                this.processing = true;
                this.created_date = rs.created_date;
                if (rs.updated_date) {
                  this.updated_date = rs.updated_date;
                }
                this.openLg(this.contentPending);
                localStorage.removeItem(environment.localAuthenInfo);
              } else if (rs.status === "rejected") {
                this.rejected = true;
                this.action_status = rs.action_status;
                this.reasons = rs.reason_rejected;
                this.created_date = rs.created_date;
                if (rs.updated_date) {
                  this.updated_date = rs.updated_date;
                }
                this.comment = rs.comment;
                this.my_uuid = rs.id;
                if (rs.can_edit == "yes") {
                  this.can_edit = true;
                } else {
                  this.can_edit = false;
                }

                if (rs.action_status === "Active") {
                  this.openLg(this.contentPending);
                } else {
                  this.openLg(this.contentRejected);
                }
              } else if (rs.status === "approved") {
                this.approved = true;
                this.created_date = rs.created_date;
                if (rs.updated_date) {
                  this.updated_date = rs.updated_date;
                }
                this.my_uuid = rs.id;
                this.openLg(this.contentApproved);
              } else if (rs.status === "banned") {
                this.banned = true;
                this.reasons = rs.reason_rejected;
                this.created_date = rs.created_date;
                if (rs.updated_date) {
                  this.updated_date = rs.updated_date;
                }
                this.comment = rs.comment;
                this.openLg(this.contentBanned);
                localStorage.removeItem(environment.localAuthenInfo);
              } else if (rs.status === "unpaid") {
                this.unpaid = true;
                this.invoice_id = rs.invoice_id;
                this.created_date = rs.created_date;
                if (rs.updated_date) {
                  this.updated_date = rs.updated_date;
                }
                this.my_uuid = rs.id;
                // this.getPaymentMethods();
                this.openLg(this.contentUnpaid);
              } else if (rs.status === "cancelled") {
                this.cancelled = true;
                this.invoice_id = rs.invoice_id;
                this.created_date = rs.created_date;
                this.openLg(this.contentCancelled);
                localStorage.removeItem(environment.localAuthenInfo);
              }
              this.temp_verify_code = this.model_verify.verify_code;
              this.model_verify.verify_code = "";
              // localStorage.removeItem(environment.localAuthenInfo);
            });
          }
        });
    }
  }
  editApplication() {
    this.modalService.dismissAll();
    this.router.navigate(["/edit-application/" + this.my_uuid]);
  }
  openLg(content) {
    this.modalService.open(content, { size: "lg", centered: true });
  }

  // DownLoad PDF
  downloadPdf() {
    const app_id = this.model_verify.application_id;
    const passport_number = this.model_verify.passport_number;
    const verify_code = this.model_verify.verify_code;
    const data_download = {
      application_id: app_id,
      passport_number: passport_number,
      verify_code: verify_code,
      id: this.my_uuid,
    };
    this.spinner.show();
    this.rest.downloadPDF(data_download).subscribe(async (result) => {
      const filename = app_id;
      saveAs(result, this.my_uuid + ".pdf");
      this.spinner.hide();
    });
  }
}
