import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  Inject,
} from "@angular/core";
import * as $ from "jquery";
import Glide from "@glidejs/glide";
import { environment } from "src/environments/environment";
import offside from "offside-js";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LOCAL_STORAGE, WINDOW } from "@ng-toolkit/universal";
import { CookiesService } from "src/app/services/cookies.service";
import { Router, ActivatedRoute } from "@angular/router";
import { RestService } from "src/app/services/rest.service";
import { ServiceSEOService } from "src/app/services/service-seo.service";
import { AppComponent } from "src/app/app.component";
@Component({
  selector: "app-index",
  templateUrl: "./index.component.html",
  styleUrls: ["./index.component.css"],
})
export class IndexComponent implements OnInit, AfterViewInit {
  @ViewChild("content", { static: false }) private content;
  webDefaultLang: string = environment.defaultLang;
  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(LOCAL_STORAGE) private localStorage: any,
    private seo: ServiceSEOService,
    private modalService: NgbModal,
    private rest: RestService,
    private route: ActivatedRoute,
    private router: Router,
    public app: AppComponent,
    private cookie: CookiesService
  ) {
    this.seo.addMetaTags();
  }

  ngOnInit() {
    $(document).ready(() => {
      new Glide("#jsCheckeVisaSlider", {
        type: "carousel",
        autoplay: 4000,
        gap: 0,
      }).mount();
      new Glide("#jsInformationSlider", {
        type: "carousel",
        perView: 4,
        gap: 1,
        breakpoints: {
          800: {
            perView: 1,
          },
        },
      }).mount();

      // offside('.navbar-menu-mobile-wrapper', {
      //   buttonsSelector: '.navbar-menu-toggle',
      //   slidingSide: 'right'
      // });
    });

    // offside('.navbar-menu-mobile-wrapper', {
    //   buttonsSelector: '.navbar-menu-toggle',
    //   slidingSide: 'right'
    // });

    this.route.params.subscribe((result) => {
      const partner_id = result["partner_id"] || 0;
      if (partner_id) {
        if (!this.cookie.getCookie("P_ID")) {
          this.rest.save_partner_access(partner_id).subscribe((rs) => {
            if (rs.status === "success") {
              this.cookie.setCookie("P_ID", partner_id, 1);
              window.location.href = "/index";
            } else {
              window.location.href = "/index";
            }
          });
        } else {
          window.location.href = "/index";
        }
      }
    });
  }
  openLg(content) {
    this.modalService
      .open(content, {
        size: "lg",
        centered: true,
        windowClass: "app-session-modal-window",
        backdropClass: "app-session-modal-backdrop",
      })
      .result.then(
        (result) => {
          // this.localStorage.setItem('viewAnnounce', 'yes');
          // console.log('result: ' + result);
        },
        (reason) => {
          // this.localStorage.setItem('viewAnnounce', 'yes');
          // console.log(reason);
        }
      );
  }
  ngAfterViewInit(): void {
    // if (this.localStorage.getItem('viewAnnounce')) {

    // } else {
    //   setTimeout(() => {
    //     this.openLg(this.content);
    //   });
    // }
    setTimeout(() => {
      this.openLg(this.content);
    });
  }
  onActivate(event) {
    // this.window.scroll(0, 0);
    // or document.body.scrollTop = 0;
    // or document.querySelector('body').scrollTo(0,0)
  }
}
