<app-nav></app-nav>
<main class="page page-apply-cart">
  <div class="container content-section mt-lg-5">
    <form action="#" class="apply-form">
      <div class="row mt-5">
        <div class="col">
          <fieldset>
            <legend class="section-header-legend bg-blue-primary text-white" translate>
              CART
            </legend>
            <div class="form-control-wrapper">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <th class="text-center" translate>NO.</th>
                    <th style="width: 100px" class="text-center" translate>
                      PHOTO
                    </th>
                    <th style="width: 120px" class="text-center" translate>
                      PASSPORT
                    </th>
                    <th *ngIf="proofileCol" style="width: 120px" class="text-center" translate>
                      <span>PROOF&nbsp;OF&nbsp;LAOTIAN</span>
                    </th>
                    <th translate>DETAILS</th>
                    <th style="width: 90px" class="text-center" translate>
                      FEE
                    </th>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of mDataArrayPerson; let i = index">
                      <td class="text-center">{{ i + 1 }}</td>
                      <td class="text-center">
                        <div>
                          <img (click)="openPhoto(i)" width="100px" [src]="item.photo_name" alt="..."
                            class="img-fluid img-thumbnail photo mouse_pointer" />
                        </div>
                      </td>

                      <td class="text-center">
                        <div>
                          <img (click)="openPassport(i)" width="100px" [src]="item.passport_name" alt="..."
                            class="img-fluid img-thumbnail passport mouse_pointer" />
                        </div>
                      </td>
                      <td class="text-center" *ngIf="proofileCol">
                        <div>
                          <img (click)="openPassport(i)" width="100px" [src]="item.passport_name" alt="..."
                            class="img-fluid img-thumbnail passport mouse_pointer" />
                        </div>
                      </td>
                      <td>
                        <div style="width: 350px">
                          <div class="text-primary font-weight-bold h5">
                            {{ item.last_name }} {{ item.first_name }}
                          </div>
                          <ul class="pl-0">
                            <li class="row pl-0">
                              <div class="col colon-after-label">
                                APPLICATION ID
                              </div>
                              <div class="col">
                                {{ item.application_id }}
                              </div>
                            </li>
                            <li class="row pl-0">
                              <div class="col colon-after-label" translate>
                                {{
                                app.SelectedLang == "en"
                                ? "DATE OF BIRTH"
                                : "出生日期"
                                }}
                              </div>
                              <div class="col">{{ item.dob }}</div>
                            </li>
                            <li class="row pl-0">
                              <div class="col colon-after-label" translate>
                                {{
                                app.SelectedLang == "en"
                                ? "TELEPHONE"
                                : "电话号码"
                                }}
                              </div>
                              <div class="col">{{ item.telephone }}</div>
                            </li>
                            <li class="row pl-0">
                              <div class="col colon-after-label" translate>
                                {{
                                app.SelectedLang == "en"
                                ? "PASSPORT NO."
                                : "护照号码"
                                }}
                              </div>
                              <div class="col">{{ item.passport_number }}</div>
                            </li>
                            <li class="row pl-0">
                              <div class="col colon-after-label" translate>
                                {{
                                app.SelectedLang == "en"
                                ? "DATE OF ISSUE"
                                : "签发日期"
                                }}
                              </div>
                              <div class="col">{{ item.issue_date }}</div>
                            </li>
                            <li class="row pl-0">
                              <div class="col colon-after-label" translate>
                                {{
                                app.SelectedLang == "en"
                                ? "EXPIRY DATE"
                                : "到期日"
                                }}
                              </div>
                              <div class="col">{{ item.expired_date }}</div>
                            </li>
                            <!-- <li class="row pl-0">
                              <div class="col colon-after-label" translate>
                                {{
                                  app.SelectedLang == "en"
                                    ? "VISA TYPE"
                                    : "签证类型"
                                }}
                              </div>
                              <div class="col">{{ item.visa_type_name }}</div>
                            </li> -->
                            <li *ngIf="item.race == 304" class="row pl-0">
                              <div class="col colon-after-label" translate>
                                FAMILY SIDE
                              </div>
                              <div class="col">
                                {{ item.family_side | uppercase }}
                              </div>
                            </li>
                            <li *ngIf="item.race == 304" class="row pl-0">
                              <div class="col colon-after-label" translate>
                                MEMBER
                              </div>
                              <div class="col">
                                {{ item.family_member_names }}
                              </div>
                            </li>
                            <li *ngIf="item.race == 304" class="row pl-0">
                              <div class="col colon-after-label" translate>
                                RELATIONSHIP
                              </div>
                              <div class="col">
                                {{ item.family_relation }}
                              </div>
                            </li>
                            <li class="row pl-0 mt-2">
                              <div class="col">
                                <button (click)="
                                    deletePerson(
                                      i,
                                      item.application_id,
                                      item.first_name
                                    )
                                  " type="button" class="btn btn-danger" style="
                                    padding: 5px 10px;
                                    font-size: 14px;
                                    line-height: 0;
                                    border: 0;
                                  ">
                                  <i class="fa fa-trash"></i>&nbsp;
                                  <span translate>Remove</span>
                                </button>
                              </div>
                              <div class="col"></div>
                            </li>
                          </ul>
                        </div>
                      </td>
                      <td class="text-center">
                        <div class="text-primary h5">${{ item.price }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td [colSpan]="proofileCol ? 5 : 4" class="text-right text-secondary">
                        <h3 translate>Total</h3>
                      </td>
                      <td>
                        <h3 class="font-weight-bold text-secondary">
                          ${{ total }}
                        </h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <footer>
                <div class="row">
                  <div class="col">
                    <div class="d-flex flex-column flex-lg-row justify-content-between">
                      <button class="btn btn-primary btn-icon d-inline-flex" id="addMorePeople"
                        (click)="addMorePerson()" type="button">
                        <i class="fa fa-plus" id="addMorePeople"></i>
                        <span class="ml-2 text-uppercase" translate id="addMorePeople">Add more people</span>
                      </button>
                    </div>
                  </div>
                  <div class="col-md-auto"></div>
                  <div *ngIf="isEmptyCart == false && mDataArrayPerson" class="col col-lg-4 mt-top">
                    <button class="btn btn-warning btn-block text-uppercase" id="continue-pay-review"
                      [disabled]="isEmptyCart == true" (click)="proceedContinue($event)">
                      <span translate id="continue-pay-review">Continue</span>
                      &nbsp;
                      <i class="fa fa-arrow-right" id="continue-pay-review"></i>
                    </button>
                  </div>
                </div>
              </footer>
            </div>
          </fieldset>
        </div>
      </div>

      <footer class="row text-center mt-5"></footer>
    </form>
  </div>
</main>
<app-footer></app-footer>
