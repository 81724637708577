import "hammerjs";
import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { environment } from "./environments/environment";
import { AppBrowserModule } from "./app/app.browser.module";
import * as Sentry from "@sentry/angular";
import * as Tracing from "@sentry/tracing";

if (environment.browserTrack) {
  Sentry.init({
    dsn: environment.sentry_dsn,
    integrations: [
      new Tracing.BrowserTracing({
        tracingOrigins: ["*"],
        routingInstrumentation: Sentry.instrumentAngularRouting,
      }),
    ],
    tracesSampleRate: 1.0,
    environment: environment.production ? "production" : "development",
    beforeSend: (event, hint) => {
      console.info(event, hint);
      return event;
    },
  });
}

if (environment.production) {
  enableProdMode();
}

document.addEventListener("DOMContentLoaded", () => {
  platformBrowserDynamic()
    .bootstrapModule(AppBrowserModule)
    .catch((err) => console.error(err));
});
