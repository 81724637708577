<app-nav></app-nav>
<main class="page page-information">
  <aside class="header-image-half"></aside>

  <div class="container content-section">
    <div class="row">
      <div class="col-md-12 text-center">
        <i class="fa fa-warning text-warning" style="font-size: 70px"></i>
        <br />
        <br />
        <h1 class="content-section-title mt-0">
          <b>404</b> &nbsp;
          <span class="font-weight-normal">PAGE NOT FOUND</span>
        </h1>
        <br />
        <a routerLink="/index" class="btn btn-primary">
          <i class="fa fa-home"></i>
          HOME</a
        >
      </div>
    </div>
  </div>
</main>
<app-footer></app-footer>
