import * as tslib_1 from "tslib";
import { isPlatformBrowser } from "@angular/common";
import { OnInit, } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment";
export class PaymentReviewComponent {
    constructor(platformId, app, router, rest, formDataService, countIncart, _lightbox, modalService, localStorage) {
        this.platformId = platformId;
        this.app = app;
        this.router = router;
        this.rest = rest;
        this.formDataService = formDataService;
        this.countIncart = countIncart;
        this._lightbox = _lightbox;
        this.modalService = modalService;
        this.localStorage = localStorage;
        this.node_static_url = environment.node_static_url;
        this.invoice_id = 0;
        this.total = 0;
        this.countTotal = 0;
        this.isEmptyCart = false;
        this.timeLeft = 0;
        this.model = {};
        this.isCheckOut = false;
        this._albumsPhoto = [];
        this._albumsPassport = [];
        this._albumsCover = [];
        this._albumProoFile = [];
        this.proofileCol = false;
        this.data = {
            session_id: "",
            input_id: "",
            input_value: "",
        };
    }
    ngOnInit() {
        this.person = this.formDataService.getFormData();
        if (localStorage.getItem(environment.localAuthenInfo) != null) {
            const authInfo = JSON.parse(localStorage.getItem(environment.localAuthenInfo));
            if (authInfo) {
                this.person.token = authInfo.token;
                this.getData();
            }
        }
        else {
            this.router.navigate(["/apply"]);
        }
    }
    getData() {
        this.person.currentActiveForm = "3";
        this.getPersonInfo();
        this.countIncart.funcCountCart();
    }
    addMorePerson() {
        this.formData = this.formDataService.resetFormData();
        this.router.navigate(["/application"]);
        this.data.session_id = this.localStorage.getItem("session_id");
        this.data.input_id = "addMorePeople";
        this.data.input_value = "add_more_people";
        // this.rest.updateUserSession(this.data).subscribe((res) => {
        //   console.log("Result Update Session when focus input:", res);
        // });
    }
    calculateTotal() {
        this.countTotal = this.mDataArrayPerson.length;
    }
    backToCart(event) {
        this.data.session_id = this.localStorage.getItem("session_id");
        this.data.input_id = event.target.id;
        this.data.input_value = "back_to_cart";
        // this.rest.updateUserSession(this.data).subscribe((res) => {
        //   console.log("Result Update Session when focus input:", res);
        // });
        this.router.navigate(["/cart"]);
    }
    open(content) {
        this.startTimer();
        this.modalService.open(content, { centered: true });
    }
    openPhoto(index) {
        if (isPlatformBrowser(this.platformId)) {
            this._lightbox.open(this._albumsPhoto, index, { centerVertically: true });
        }
    }
    openPassport(index) {
        if (isPlatformBrowser(this.platformId)) {
            this._lightbox.open(this._albumsPassport, index, {
                centerVertically: true,
            });
        }
    }
    openCover(index) {
        if (isPlatformBrowser(this.platformId)) {
            this._lightbox.open(this._albumsCover, index, { centerVertically: true });
        }
    }
    closeImgPreview() {
        this._lightbox.close();
    }
    startTimer() {
        this.interval = setInterval(() => {
            this.timeLeft += 20;
            if (this.timeLeft === 100) {
                clearInterval(this.interval);
                this.modalService.dismissAll();
                this.countIncart.funcCountCart();
                this.getPersonInfo();
            }
        }, 1000);
    }
    getPersonInfo() {
        this.rest.getPersonForPayment().subscribe((result) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (result.length > 0) {
                this.mDataArrayPerson = result;
                this.calculateTotal();
                this.invoice_id = result[0].invoice_id;
                let m_total = 0;
                for (let i = 0; i < result.length; i++) {
                    m_total += result[i].price;
                }
                this.total = Math.round(m_total * 100) / 100 || 0;
                for (let i = 0; i < result.length; i++) {
                    const srcPhoto = result[i].version == "2"
                        ? yield this.getBase64Image(result[i].photo_name, "photo")
                        : result[i].photo_name;
                    const srcPassport = result[i].version == "2"
                        ? yield this.getBase64Image(result[i].passport_name, "passport")
                        : result[i].passport_name;
                    const srcCover = result[i].passport_cover_name;
                    this.mDataArrayPerson[i].photo_name = srcPhoto;
                    this.mDataArrayPerson[i].passport_name = srcPassport;
                    const captionPhoto = "Photo ";
                    const captionPassport = "Passport ";
                    const captionCover = "Cover ";
                    const albumPhoto = {
                        src: srcPhoto,
                        caption: captionPhoto,
                    };
                    const albumPassport = {
                        src: srcPassport,
                        caption: captionPassport,
                    };
                    const albumCover = {
                        src: srcCover,
                        caption: captionCover,
                    };
                    this._albumsPhoto.push(albumPhoto);
                    this._albumsPassport.push(albumPassport);
                    this._albumsCover.push(albumCover);
                }
                this.total = m_total;
            }
            else {
                this.mDataArrayPerson = result;
                this.isEmptyCart = true;
                this.total = 0;
            }
        }));
    }
    onGetPersonInfo(mDataArrayPerson) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.mDataArrayPerson = mDataArrayPerson;
            if (mDataArrayPerson.length > 0) {
                this.invoice_id = mDataArrayPerson[0].invoice_id;
                let m_total = 0;
                for (let i = 0; i < mDataArrayPerson.length; i++) {
                    m_total += mDataArrayPerson[i].price;
                }
                this.total = Math.round(m_total * 100) / 100 || 0;
                for (let i = 0; i < mDataArrayPerson.length; i++) {
                    const srcPhoto = mDataArrayPerson[i].version == "2"
                        ? yield this.getBase64Image(mDataArrayPerson[i].photo_name, "photo")
                        : mDataArrayPerson[i].photo_name;
                    const srcPassport = mDataArrayPerson[i].version == "2"
                        ? yield this.getBase64Image(mDataArrayPerson[i].passport_name, "passport")
                        : mDataArrayPerson[i].passport_name;
                    const srcCover = mDataArrayPerson[i].passport_cover_name;
                    this.mDataArrayPerson[i].photo_name = srcPhoto;
                    this.mDataArrayPerson[i].passport_name = srcPassport;
                    const captionPhoto = "Photo ";
                    const captionPassport = "Passport ";
                    const captionCover = "Cover ";
                    this._albumsPhoto.push({
                        src: srcPhoto,
                        caption: captionPhoto,
                    });
                    this._albumsPassport.push({
                        src: srcPassport,
                        caption: captionPassport,
                    });
                    this._albumsCover.push({
                        src: srcCover,
                        caption: captionCover,
                    });
                }
                this.total = m_total;
            }
            else {
                this.mDataArrayPerson = [];
                this.isEmptyCart = true;
                this.total = 0;
            }
        });
    }
    getBase64Image(imageName, image) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this.rest
                .getBase64ImageService(imageName, image)
                .toPromise();
            return result[`${image}_name`];
        });
    }
}
