<app-nav></app-nav>
<br>
<main class="page page-security-verification">
  <div class="container content-section">
    <div class="container content-section">
      <h1 class="content-section-title text-center text-uppercase" style="margin-top: 140px;">Welcome
      </h1>
      <form #loginForm="ngForm" id="loginForm" (ngSubmit)="loginForm.form.valid && submitLogin(loginForm)" novalidate>
        <div class="row">
          <div class="col-lg-6 offset-lg-3">
            <input id="client_email" type="email" #client_email class="form-control form-control-lg"
              [(ngModel)]="model.client_email" #client_emails="ngModel"
              [ngClass]="{'is-invalid':loginForm.submitted && client_emails.invalid}" name="client_email" required
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$" [placeholder]="'Email' | translate">
            <div class="invalid-feedback">
              <div *ngIf="client_emails.errors?.pattern">Email is invalid</div>
              <div *ngIf="client_emails.invalid && !client_emails.errors?.pattern">Email is required</div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 offset-lg-3">
            <input id="password" type="password" #password class="form-control form-control-lg"
              [(ngModel)]="model.password" #passwords="ngModel"
              [ngClass]="{'is-invalid':loginForm.submitted && passwords.invalid}" name="password" required
              [placeholder]="'Password' | translate">
            <div class="invalid-feedback">
              <div *ngIf="passwords.invalid && passwords.errors?.required">Password is required</div>
            </div>
          </div>
        </div>

        <!-- TODO: ADD CAPTCHA -->
        <div class="row">
          <div class="col-lg-6 offset-lg-3 text-center mt-1">
            <button type="submit" class="btn btn-blue">Login</button>
          </div>
        </div>
      </form>
    </div>

  </div>
</main>
