import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-partner',
  templateUrl: './nav-partner.component.html',
  styleUrls: ['./nav-partner.component.css']
})
export class NavPartnerComponent implements OnInit {

  constructor(private router: Router ) { }

  ngOnInit() {
  }
  logOut() {
    localStorage.removeItem('partnerAuthen');
    this.router.navigate(['/partner-login']);
  }

}
