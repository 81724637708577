<app-nav-partner></app-nav-partner>

<div class="container mt-5">
    <h1 class="text-secondary">{{partner_name}}</h1>
  <div class="row mt-3">
  

    <div class="col-sm-4 mt-2">
      <div class="card">
        <div class="card-body shadow rounded">
          <h3 class="card-title text-primary2 font-weight-bold">Visit</h3>
          <h3 class="card-text border-top"> <span class="badge badge-warning2 mt-3">
              {{countAccess}}
            </span></h3>
            <a routerLink="/partner-visit" class="pull-right mt-3 text-secondary" style="font-size: 14px;">
                <span class="badge badge-secondary">Detail <i class="fa fa-arrow-circle-o-right"></i></span>
              </a>
        </div>
      </div>
    </div>
    <div class="col-sm-4 mt-2">
      <div class="card">
        <div class="card-body shadow rounded">
          <h3 class="card-title text-primary2 font-weight-bold">Fill form</h3>
          <h3 class="card-text border-top">
            <span class="badge badge-primary mt-3">
              {{countInApplication}}
            </span>
          </h3>
          <a class="pull-right mt-3 text-secondary" style="font-size: 14px;">
              <span class="badge badge-secondary">Coming soon</span>
            </a>
        </div>
      </div>
    </div>
    <div class="col-sm-4 mt-2">
      <div class="card">
        <div class="card-body shadow rounded">
          <h3 class="card-title text-primary2 font-weight-bold">Paid</h3>
          <h3 class="card-text border-top"> <span class="badge badge-success mt-3">
              {{countInPaid}}
            </span></h3>
          <a class="pull-right mt-3 text-secondary" style="font-size: 14px;">
            <span class="badge badge-secondary">Coming soon</span>
          </a>
        </div>
      </div>
    </div>
  </div>
<div class="mt-5">
  
</div>
  <chart class="shadow" [type]="type" [data]="data" [options]="options"></chart>
</div>
