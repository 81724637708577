export class FormData {
  application_id = "";
  imgPhoto = "";
  imgPhotoName = "";
  imgPass = "";
  imgPassName = "";
  imgCover = "";
  imgCoverName = "";
  hotel_doc = "";
  flight_doc = "";
  third_visa_doc = "";
  guarantor_doc = "";
  firstName = "";
  lastName = "";
  gender = "";
  DOB = { year: 0, month: 0, day: 0 };
  countryBirth = "";
  tel = "";
  email = "";
  occupation = "";
  resAddress = "";
  province = "";
  countryCity = "";
  purposeOfTrip = "";
  addressDuringVisit = "";
  passportNumber = "";
  issueDate = { year: 0, month: 0, day: 0 };
  expiredDate = { year: 0, month: 0, day: 0 };
  countryOfPassport = "";
  visaType = "";
  portOfEntry = "";
  intendedDate = { year: 0, month: 0, day: 0 };
  vehicle = "";
  next_destination = "";
  currentActiveForm = "";
  selectedGender = "";
  selectedCountryBirth = "";
  selectedCountryCity = "";
  selectedCountryOfPassport = "";
  selectedvisaType = "";
  selectedportOfEntry = "";
  selectedPurpose = "";
  token = "";
  is_request_doc = false;
  partner_id = "";
  bank_statement_doc = "";
  race: number | string = "0";
  family_side?: string = "";
  family_member_names?: string = "";
  family_relation?: string = "";
  proof_of_laotian?: string = "";
  proof_file_preview?: string = "";
  first_action_date?: string | null = null;
  clear() {
    this.application_id = "";
    this.imgPhoto = "";
    this.imgPhotoName = "";
    this.imgPass = "";
    this.imgPassName = "";
    this.imgCover = "";
    this.imgCoverName = "";
    this.hotel_doc = "";
    this.flight_doc = "";
    this.third_visa_doc = "";
    this.guarantor_doc = "";
    this.firstName = "";
    this.lastName = "";
    this.gender = "";
    this.DOB = { year: 0, month: 0, day: 0 };
    this.countryBirth = "";
    this.tel = "";
    this.email = "";
    this.occupation = "";
    this.resAddress = "";
    this.province = "";
    this.countryCity = "";
    this.purposeOfTrip = "";
    this.addressDuringVisit = "";
    this.passportNumber = "";
    this.issueDate = { year: 0, month: 0, day: 0 };
    this.expiredDate = { year: 0, month: 0, day: 0 };
    this.countryOfPassport = "";
    this.visaType = "";
    this.portOfEntry = "";
    this.intendedDate = { year: 0, month: 0, day: 0 };
    this.vehicle = "";
    this.next_destination = "";
    this.currentActiveForm = "";
    this.selectedGender = "";
    this.selectedCountryBirth = "";
    this.selectedCountryCity = "";
    this.selectedCountryOfPassport = "";
    this.selectedvisaType = "";
    this.selectedportOfEntry = "";
    this.selectedPurpose = "";
    this.is_request_doc = false;
    this.partner_id = "";
    this.bank_statement_doc = "";
    this.race = "0";
    this.family_side = "";
    this.family_member_names = "";
    this.family_relation = "";
    this.proof_of_laotian = "";
    this.proof_file_preview = "";
    this.first_action_date = "";
  }
}
export class Person {
  application_id = "";
  imgPhoto = "";
  imgPhotoName = "";
  imgPass = "";
  imgPassName = "";
  imgCover = "";
  imgCoverName = "";
  hotel_doc = "";
  flight_doc = "";
  third_visa_doc = "";
  guarantor_doc = "";
  firstName = "";
  lastName = "";
  gender = "";
  DOB = { year: 0, month: 0, day: 0 };
  countryBirth = "";
  tel = "";
  email = "";
  occupation = "";
  resAddress = "";
  province = "";
  countryCity = "";
  purposeOfTrip = "";
  addressDuringVisit = "";
  passportNumber = "";
  issueDate = { year: 0, month: 0, day: 0 };
  expiredDate = { year: 0, month: 0, day: 0 };
  countryOfPassport = "";
  visaType = "";
  portOfEntry = "";
  intendedDate = { year: 0, month: 0, day: 0 };
  vehicle = "";
  next_destination = "";
  currentActiveForm = "";
  selectedGender = "";
  selectedCountryBirth = "";
  selectedCountryCity = "";
  selectedCountryOfPassport = "";
  selectedvisaType = "";
  selectedportOfEntry = "";
  selectedPurpose = "";
  token = "";
  is_request_doc = false;
  partner_id = "";
  bank_statement_doc = "";
  race: number | string = "0";
  family_side?: string = "";
  family_member_names?: string = "";
  family_relation?: string = "";
  proof_of_laotian?: string = "";
  proof_file_preview?: string = "";
  first_action_date?: string | null = null;
}

export class CustomerImage {
  imgPhoto = "";
  imgPass = "";
  imgCover = "";
}
