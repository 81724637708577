import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RestService } from 'src/app/services/rest.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  model: any = {};
  constructor(private rest: RestService, private router: Router,) { }

  ngOnInit() {
  }

  submitLogin(form: any) {
    this.rest.login(this.model).subscribe(result => {
      if (result.responseStatus == true) {
        localStorage.setItem(environment.agentAuthenInfo, JSON.stringify(result));
        localStorage.setItem(environment.localAuthenInfo, JSON.stringify(result));
        localStorage.setItem('confirm', 'yes')
        this.router.navigate(['/application']);
      } else {

      }
    });
  }

}
