<app-nav></app-nav>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white">Recognizing Passport Please Wait...</p>
</ngx-spinner>
<!-- Modal -->
<main class="page page-apply-form">
  <div class="container content-section mt-lg-5">
    <div class="row mt-2" *ngIf="app.SelectedLang == 'cn'">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <div class="alert alert-warning alert-dismissible fade show" role="alert">
          <p class="text-danger">
            www.laoevisa.gov.la 网站上有英文和中文两种文本，以便更好地理解 evisa
            的申请流程。但是，每位申请者提交的所有资料须为英文，包括但不限于申请人的个人资料、查询信息等等。如果未能提供英文的申请材料，将会延误申请，或是被拒签。
          </p>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>

    <!-- <form (ngSubmit)="onSubmit(personForm)" method="POST" enctype="multipart/form-data" [formGroup]="personForm" id="personForm"> -->
    <form [formGroup]="personForm" id="personForm">
      <!-- Modal -->
      <div class="modal fade bd-example-modal-lg" id="exampleModal" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header" style="padding-bottom: 5px">
              <h5 class="mr-5" style="margin-left: 20px" translate>Photo</h5>
              <span class="text-danger"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                <span translate="size_warning">Maximum file size is limited to 10MB only</span></span>
              <div class="row">
                <div class="col-md-12"></div>
              </div>
              <button type="button" class="close mr-1" style="color: red" data-dismiss="modal" aria-label="Close">
                <i class="fa fa-times-circle"></i>
              </button>
            </div>
            <div class="modal-body">
              <input type="file" accept="image/x-png,image/jpeg" #fileInputPhoto id="inputPhotoFile" autocomplete="off"
                name="inputPhotoFile" style="display: none" (change)="fileChangeEvent($event)" />
              <div class="row">
                <div class="col-md-3 text-left">
                  <ul style="margin-left: -17px">
                    <li style="list-style-type: none">
                      <h3 translate>Instructions</h3>
                    </li>
                  </ul>
                  <ul class="mb-30 text-secondary" style="font-size: 14px">
                    <li *ngIf="app.SelectedLang == 'en'">
                      Please refer the sample photo by
                      <a href="/assets/images/photo_spec.jpg" target="_blank"><b>CLICK HERE</b></a>
                    </li>
                    <li *ngIf="app.SelectedLang == 'cn'">
                      请点击
                      <a href="/assets/images/photo_spec.jpg" target="_blank"><b>photo sample</b></a>
                      按键预览照片
                    </li>
                    <li *ngIf="app.SelectedLang == 'fr'">
                      Please refer the sample photo by
                      <a href="/assets/images/photo_spec.jpg" target="_blank"><b>CLICK HERE</b></a>
                    </li>
                    <li translate="photo_stru2">Click ‘Browse File’ button</li>
                    <li translate="photo_stru3">
                      Choose a file with extension of JPG,PNG
                    </li>
                    <li translate="photo_stru4">
                      Please use the crop window (dotted line window) to select
                      the area in the photo
                    </li>
                    <li translate="photo_stru5">
                      Click ‘Yes, upload’ button to upload the image
                    </li>
                    <li translate="photo_stru6">
                      This image will be uploaded on the Lao eVisa application
                    </li>
                  </ul>
                </div>
                <div class="col-md-4">
                  <div class="d-flex justify-content-between">
                    <a id="btnChoosePhoto" (click)="fileInputPhoto.click(); isPhotoBigSize = false"
                      class="btn btn-primary btn-sm text-white">
                      <i class="fa fa-search"></i>
                      <span translate>Browse File</span></a>
                    <button id="clickUploadPhoto" [disabled]="photo_count < 1" (click)="onUploadPhotoImage()"
                      type="button" class="btn btn-success2 btn-sm pull-right" data-dismiss="modal">
                      <i class="fa fa-check"></i>
                      <span translate>Yes, upload</span>
                    </button>
                  </div>
                  <div *ngIf="!cropperReady">
                    <!-- <img alt="photo-guideline" src="assets/images/passport_guide.png" /> -->
                    <img src="assets/images/photo_spec.jpg" alt="photo-guideline"
                      style="max-height: 70vh; max-width: 60vw" />
                  </div>
                  <br /><br />
                  <div class="personeBorder">
                    <image-cropper #imagePhoto class="imgCropPhoto" [imageChangedEvent]="imageChangedEvent"
                      [maintainAspectRatio]="false" [aspectRatio]="4 / 6" [resizeToWidth]="1000" [roundCropper]="false"
                      format="png" outputType="base64" (imageCropped)="imageCropped($event)" [imageQuality]="70"
                      (imageLoaded)="imageLoaded()" (loadImageFailed)="loadImageFailed()"
                      [style.display]="cropperReady ? null : 'none'">
                    </image-cropper>
                    <!--style="max-height: 33vh"-->
                  </div>
                  <div id="file_error" class="custom-invalid-feedback"></div>
                  <div *ngIf="this.isPhotoBigSize" class="custom-invalid-feedback">
                    File size is greater than 10MB
                  </div>
                  <div *ngIf="this.isPhotoSmallSize" class="custom-invalid-feedback">
                    File size very small we accept 300x300 pixel or up
                  </div>
                  <p *ngIf="photo_count == 1" class="text-red">
                    You can adjust the size of your photo
                  </p>
                </div>
                <div class="col-md-5">
                  <br /><br />
                  <img id="croppedImage" [src]="croppedImage" class="mt-5" height="227" />

                  <div *ngIf="cropperReady" class="btn-toolbar my-2" role="toolbar"
                    aria-label="Toolbar with button groups">
                    <div class="btn-group mr-2" role="group" aria-label="First group">
                      <button type="button" class="btn btn-primary" (click)="rotateLeft('photo')">
                        <i class="fa fa-undo" aria-hidden="true"></i>
                      </button>
                      <button type="button" class="btn btn-primary" (click)="rotateRight('photo')">
                        <i class="fa fa-repeat" aria-hidden="true"></i>
                      </button>
                      <button type="button" class="btn btn-primary" (click)="flipHorizontal('photo')">
                        <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                          <g id="SVGRepo_iconCarrier">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M2.14935 19.5257C2.33156 19.8205 2.65342 20 3 20H10C10.5523 20 11 19.5523 11 19V4.99998C11 4.5362 10.6811 4.13328 10.2298 4.02673C9.77838 3.92017 9.31298 4.13795 9.10557 4.55276L2.10557 18.5528C1.95058 18.8628 1.96714 19.2309 2.14935 19.5257ZM4.61804 18L9 9.23604V18H4.61804ZM13 19C13 19.5523 13.4477 20 14 20H21C21.3466 20 21.6684 19.8205 21.8507 19.5257C22.0329 19.2309 22.0494 18.8628 21.8944 18.5528L14.8944 4.55276C14.687 4.13795 14.2216 3.92017 13.7702 4.02673C13.3189 4.13328 13 4.5362 13 4.99998V19Z"
                              fill="#ffffff"></path>
                          </g>
                        </svg>
                      </button>
                      <button type="button" class="btn btn-primary" (click)="flipVertical('photo')">
                        <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none"
                          xmlns="http://www.w3.org/2000/svg" transform="rotate(270)">
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                          <g id="SVGRepo_iconCarrier">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M2.14935 19.5257C2.33156 19.8205 2.65342 20 3 20H10C10.5523 20 11 19.5523 11 19V4.99998C11 4.5362 10.6811 4.13328 10.2298 4.02673C9.77838 3.92017 9.31298 4.13795 9.10557 4.55276L2.10557 18.5528C1.95058 18.8628 1.96714 19.2309 2.14935 19.5257ZM4.61804 18L9 9.23604V18H4.61804ZM13 19C13 19.5523 13.4477 20 14 20H21C21.3466 20 21.6684 19.8205 21.8507 19.5257C22.0329 19.2309 22.0494 18.8628 21.8944 18.5528L14.8944 4.55276C14.687 4.13795 14.2216 3.92017 13.7702 4.02673C13.3189 4.13328 13 4.5362 13 4.99998V19Z"
                              fill="#ffffff"></path>
                          </g>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal -->
      <div class="modal fade bd-passport-modal-lg" id="passportModal" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header" style="padding-bottom: 5px">
              <h5 class="mr-5" style="margin-left: 20px" translate>Passport</h5>
              <span class="text-danger"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i><span
                  translate="size_warning">Maximum file size is limited to 10MB only</span></span>
              <div class="row">
                <div class="col-md-12"></div>
              </div>
              <button type="button" class="close mr-1" style="color: red" data-dismiss="modal" aria-label="Close">
                <i class="fa fa-times-circle"></i>
              </button>
            </div>
            <div class="modal-body">
              <input type="file" #fileInputPassport accept="image/x-png,image/jpeg" id="inputPassportFile"
                autocomplete="off" name="inputPassportFile" style="display: none"
                (change)="fileChangeEventPass($event)" />
              <div class="row">
                <div class="col-md-3 text-left">
                  <ul style="margin-left: -17px">
                    <li style="list-style-type: none">
                      <h3 translate>Instructions</h3>
                    </li>
                  </ul>
                  <ul class="mb-30 text-secondary" style="font-size: 14px">
                    <li translate="photo_stru2">Click ‘Browse File’ button</li>
                    <li translate="photo_stru3">
                      Choose a file with extension of JPG,PNG
                    </li>
                    <li translate="photo_stru4">
                      Please use the crop window (dotted line window) to select
                      the area in the photo
                    </li>
                    <li translate="photo_stru5">
                      Click ‘Yes, upload’ button to Upload the image
                    </li>
                    <li translate="photo_stru6">
                      This image will be uploaded on the Lao eVisa application
                    </li>
                  </ul>
                </div>
                <div class="col-md-4">
                  <div class="d-flex justify-content-between">
                    <a id="btnChoosePassport" (click)="
                        fileInputPassport.click(); isPassportBigSize = false
                      " class="btn btn-primary btn-sm text-white">
                      <i class="fa fa-search"></i>
                      <span translate>Browse File</span></a>

                    <button id="clickUploadPassport" [disabled]="passport_count < 1" (click)="onUploadPassportImage()"
                      type="button" class="btn btn-success2 btn-sm pull-right" data-dismiss="modal">
                      <i class="fa fa-check"></i>
                      <span translate>Yes, upload</span>
                    </button>
                  </div>

                  <br /><br />
                  <div class="personeBorder">
                    <!-- <image-cropper [imageChangedEvent]="imageChangedEventPass" [maintainAspectRatio]="false"
                      [aspectRatio]="8/5" [resizeToWidth]="1000" [roundCropper]="false" format="png" outputType="base64"
                      (imageCropped)="imageCroppedPass($event)" (imageLoaded)="imageLoadedPass()" autoCrop="true"
                      (loadImageFailed)="loadImageFailedPass()" [style.display]="cropperReadyPass ? null : 'none'">
                    </image-cropper> -->
                    <!--style="max-height: 33vh"-->

                    <image-cropper #imagePassport [imageChangedEvent]="imageChangedEventPass"
                      [maintainAspectRatio]="false" [containWithinAspectRatio]="true" [resizeToWidth]="1000"
                      [cropperMinWidth]="700" [roundCropper]="false" format="png" outputType="base64"
                      (imageCropped)="imageCroppedPass($event)" (imageLoaded)="imageLoadedPass()"
                      (cropperReady)="cropperReadyLog()" (loadImageFailed)="loadImageFailedPass()"
                      [style.display]="cropperReadyPass ? null : 'none'" [alignImage]="'center'"></image-cropper>
                  </div>
                  <div id="file_error" class="custom-invalid-feedback"></div>
                  <div *ngIf="this.isPassportBigSize" class="custom-invalid-feedback">
                    File size is greater than 10MB
                  </div>
                  <div *ngIf="this.isPassportSmallSize" class="custom-invalid-feedback">
                    File size very small we accept 300x300 pixel or up
                  </div>
                  <p *ngIf="passport_count == 1" class="text-red">
                    - You can adjust the size of your passport
                  </p>
                  <p *ngIf="passport_count == 1" class="text-red" translate="mrz"></p>
                </div>
                <div class="col-md-5">
                  <br /><br />
                  <img id="croppedImagePass" [src]="croppedImagePass" class="mt-5" width="80%" />
                  <div *ngIf="cropperReadyPass" class="btn-toolbar my-2" role="toolbar"
                    aria-label="Toolbar with button groups">
                    <div class="btn-group mr-2" role="group" aria-label="First group">
                      <button type="button" class="btn btn-primary" (click)="rotateLeft('passport')">
                        <i class="fa fa-undo" aria-hidden="true"></i>
                      </button>
                      <button type="button" class="btn btn-primary" (click)="rotateRight('passport')">
                        <i class="fa fa-repeat" aria-hidden="true"></i>
                      </button>
                      <button type="button" class="btn btn-primary" (click)="flipHorizontal('passport')">
                        <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                          <g id="SVGRepo_iconCarrier">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M2.14935 19.5257C2.33156 19.8205 2.65342 20 3 20H10C10.5523 20 11 19.5523 11 19V4.99998C11 4.5362 10.6811 4.13328 10.2298 4.02673C9.77838 3.92017 9.31298 4.13795 9.10557 4.55276L2.10557 18.5528C1.95058 18.8628 1.96714 19.2309 2.14935 19.5257ZM4.61804 18L9 9.23604V18H4.61804ZM13 19C13 19.5523 13.4477 20 14 20H21C21.3466 20 21.6684 19.8205 21.8507 19.5257C22.0329 19.2309 22.0494 18.8628 21.8944 18.5528L14.8944 4.55276C14.687 4.13795 14.2216 3.92017 13.7702 4.02673C13.3189 4.13328 13 4.5362 13 4.99998V19Z"
                              fill="#ffffff"></path>
                          </g>
                        </svg>
                      </button>
                      <button type="button" class="btn btn-primary" (click)="flipVertical('passport')">
                        <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none"
                          xmlns="http://www.w3.org/2000/svg" transform="rotate(270)">
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                          <g id="SVGRepo_iconCarrier">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M2.14935 19.5257C2.33156 19.8205 2.65342 20 3 20H10C10.5523 20 11 19.5523 11 19V4.99998C11 4.5362 10.6811 4.13328 10.2298 4.02673C9.77838 3.92017 9.31298 4.13795 9.10557 4.55276L2.10557 18.5528C1.95058 18.8628 1.96714 19.2309 2.14935 19.5257ZM4.61804 18L9 9.23604V18H4.61804ZM13 19C13 19.5523 13.4477 20 14 20H21C21.3466 20 21.6684 19.8205 21.8507 19.5257C22.0329 19.2309 22.0494 18.8628 21.8944 18.5528L14.8944 4.55276C14.687 4.13795 14.2216 3.92017 13.7702 4.02673C13.3189 4.13328 13 4.5362 13 4.99998V19Z"
                              fill="#ffffff"></path>
                          </g>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer"></div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col">
          <!-- <button class="btn-primary" (click)="clickPopUp()">CLick ME</button> -->
          <fieldset>
            <legend class="section-header-legend bg-blue-primary text-white text-uppercase" translate>
              Personal Information
            </legend>
            <div class="form-control-wrapper">
              <div class="row mb-5" *ngIf="isOrdinaryPassport">
                <div class="col-lg-6 d-flex flex-column align-items-center justify-content-center">
                  <div class="text-center mb-3">
                    <strong><span translate>Photo</span> &nbsp;
                      <i class="fa fa-question-circle tooltip-wide" [ngbPopover]="popContentPhoto"
                        triggers="mouseenter:mouseleave" placement="bottom" container="body"></i>
                    </strong>
                    <br /><span translate>(JPG, JPEG, PNG, max size 10MB)</span>
                  </div>

                  <ng-template #popContentPhoto>
                    <table>
                      <tr>
                        <td class="text-left">Photo Guide</td>
                      </tr>
                      <tr>
                        <img src="assets/images/Profile_eVisa@4x.2.png" width="400" />
                      </tr>
                    </table>
                  </ng-template>
                  <!-- <div class="image-placeholder photo mb-4"></div> -->
                  <img id="photo" width="151" [src]="
                      custImage.imgPhoto ||
                      './assets/images/placeholder-photo.jpg'
                    " class="mb-4 photo" alt="" />

                  <a class="btn btn-primary btn-icon text-white" data-toggle="modal" id="btnshowModalPhoto"
                    data-target=".bd-example-modal-lg">
                    <svg aria-hidden="true" class="svg-inline--fa fa-upload fa-w-16" data-icon="upload"
                      data-prefix="fal" focusable="false" role="img" viewBox="0 0 512 512" id="btnshowModalPhoto"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M452 432c0 11-9 20-20 20s-20-9-20-20 9-20 20-20 20 9 20 20zm-84-20c-11 0-20 9-20 20s9 20 20 20 20-9 20-20-9-20-20-20zm144-48v104c0 24.3-19.7 44-44 44H44c-24.3 0-44-19.7-44-44V364c0-24.3 19.7-44 44-44h124v-99.3h-52.7c-35.6 0-53.4-43.1-28.3-68.3L227.7 11.7c15.6-15.6 40.9-15.6 56.6 0L425 152.4c25.2 25.2 7.3 68.3-28.3 68.3H344V320h124c24.3 0 44 19.7 44 44zM200 188.7V376c0 4.4 3.6 8 8 8h96c4.4 0 8-3.6 8-8V188.7h84.7c7.1 0 10.7-8.6 5.7-13.7L261.7 34.3c-3.1-3.1-8.2-3.1-11.3 0L109.7 175c-5 5-1.5 13.7 5.7 13.7H200zM480 364c0-6.6-5.4-12-12-12H344v24c0 22.1-17.9 40-40 40h-96c-22.1 0-40-17.9-40-40v-24H44c-6.6 0-12 5.4-12 12v104c0 6.6 5.4 12 12 12h424c6.6 0 12-5.4 12-12V364z"
                        fill="currentColor"></path>
                    </svg>
                    <span class="ml-2 text-uppercase" id="btnshowModalPhoto" translate>Upload</span>
                  </a>
                  <div class="mt-2">
                    <div id="warningUploadPhoto" *ngIf="submitted && !custImage.imgPhoto"
                      class="custom-invalid-feedback">
                      Please upload photo
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 d-flex flex-column align-items-center justify-content-center">
                  <div class="text-center mt-5 mt-lg-0 mb-3">
                    <strong><span translate>Passport</span> &nbsp;
                      <i class="fa fa-question-circle tooltip-wide" triggers="mouseenter:mouseleave" placement="bottom"
                        [ngbPopover]="popContentPassport"></i></strong>
                    <br /><span translate>(JPG, JPEG, PNG, max size 10MB)</span>
                    <ng-template #popContentPassport>
                      <table>
                        <tr>
                          <td class="text-left">Passport Guide</td>
                        </tr>
                        <tr>
                          <img src="assets/images/passport_guide.png" height="200" />
                        </tr>
                      </table>
                    </ng-template>
                  </div>
                  <!-- <div class="image-placeholder passport mb-4"></div> -->
                  <img id="passport" width="300" [src]="
                      custImage.imgPass ||
                      './assets/images/placeholder-passport.jpg'
                    " alt="your image" class="img-fluid passport mb-4" alt="" />
                  <div class="text-secondary mb-2 text-left w-100" translate="mrz"></div>
                  <div class="text-secondary mb-2 text-left w-100" translate="mrz2"></div>
                  <br />
                  <a class="btn btn-primary btn-icon text-white" data-toggle="modal" id="btnshowModalPassport"
                    data-target=".bd-passport-modal-lg">
                    <svg aria-hidden="true" class="svg-inline--fa fa-upload fa-w-16" data-icon="upload"
                      data-prefix="fal" focusable="false" role="img" viewBox="0 0 512 512" id="btnshowModalPassport"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M452 432c0 11-9 20-20 20s-20-9-20-20 9-20 20-20 20 9 20 20zm-84-20c-11 0-20 9-20 20s9 20 20 20 20-9 20-20-9-20-20-20zm144-48v104c0 24.3-19.7 44-44 44H44c-24.3 0-44-19.7-44-44V364c0-24.3 19.7-44 44-44h124v-99.3h-52.7c-35.6 0-53.4-43.1-28.3-68.3L227.7 11.7c15.6-15.6 40.9-15.6 56.6 0L425 152.4c25.2 25.2 7.3 68.3-28.3 68.3H344V320h124c24.3 0 44 19.7 44 44zM200 188.7V376c0 4.4 3.6 8 8 8h96c4.4 0 8-3.6 8-8V188.7h84.7c7.1 0 10.7-8.6 5.7-13.7L261.7 34.3c-3.1-3.1-8.2-3.1-11.3 0L109.7 175c-5 5-1.5 13.7 5.7 13.7H200zM480 364c0-6.6-5.4-12-12-12H344v24c0 22.1-17.9 40-40 40h-96c-22.1 0-40-17.9-40-40v-24H44c-6.6 0-12 5.4-12 12v104c0 6.6 5.4 12 12 12h424c6.6 0 12-5.4 12-12V364z"
                        fill="currentColor"></path>
                    </svg>
                    <span class="ml-2 text-uppercase" id="btnshowModalPassport" translate>Upload</span>
                  </a>
                  <div class="mt-2">
                    <div id="warningUploadPassport" *ngIf="submitted && !custImage.imgPass"
                      class="custom-invalid-feedback">
                      Please upload passport
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="isOrdinaryPassport">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="label" translate="sn">SURNAME<span class="text-secondary" style="font-size: 10px"
                        translate>(Please type your surname as in your passport)</span>
                      <span class="text-danger">*</span></label>
                    <input placement="bottom" ngbTooltip="Please type your surname as in your passport"
                      autocomplete="off" (focus)="onFocus($event)" (blur)="onBlur($event)" formControlName="lastName"
                      class="form-control" [(ngModel)]="person.lastName" name="lastName" id="lastName" maxlength="100"
                      required type="text" [placeholder]="'Surname' | translate" [autofocus]="true" [ngClass]="{
                        'is-invalid': submitted && f.lastName.invalid
                      }" />
                    <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                      <div *ngIf="f.lastName.errors.required">
                        Surname is required
                      </div>
                      <div *ngIf="f.lastName.errors.pattern">
                        Surname is invalid
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="label" translate="given_name">GIVEN NAMES
                      <span class="text-secondary" style="font-size: 10px" translate>(Please type your given names as in
                        your
                        passport)</span><span class="text-danger">*</span></label>
                    <input placement="bottom" ngbTooltip="Please type your given names as in your passport"
                      autocomplete="off" (focus)="onFocus($event)" (blur)="onBlur($event)" formControlName="firstName"
                      class="form-control" [(ngModel)]="person.firstName" required id="firstName" maxlength="100"
                      name="firstName" type="text" [placeholder]="'Given Names' | translate" [ngClass]="{
                        'is-invalid': submitted && f.firstName.errors
                      }" />

                    <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                      <div *ngIf="f.firstName.errors.required">
                        Given Name is required
                      </div>
                      <div *ngIf="f.firstName.errors.pattern">
                        Given Name is invalid
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="label" translate="gender">GENDER <span class="text-danger">*</span></label>
                    <select formControlName="gender" (change)="onGenderChange($event)" [(ngModel)]="person.gender"
                      (focus)="onFocus($event)" (blur)="onBlur($event)" required
                      [ngClass]="{ 'is-invalid': submitted && f.gender.errors }" name="gender" id="gender"
                      class="form-control">
                      <option value="" translate>-- Please Select --</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                    <div *ngIf="submitted && f.gender.errors" class="invalid-feedback">
                      <div *ngIf="f.gender.errors.required">
                        Gender is required
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="label" translate="dob">DATE OF BIRTH <span class="text-danger">*</span></label>
                    <div class="input-group">
                      <input formControlName="DOB" (ngModelChange)="DateBirthOnChange($event)" readonly
                        autocomplete="off" id="dateOfBirth" (focus)="onFocus($event)" (blur)="onBlur($event)"
                        [(ngModel)]="person.DOB" name="DOB" class="form-control bg-white" [maxDate]="today"
                        placeholder="DD-MM-YYYY" appMaskDate maxlength="40" ngbDatepicker #d="ngbDatepicker"
                        [ngClass]="{ 'is-invalid': submitted && f.DOB.errors }" />
                      <div class="input-group-append pb-2 pt-2">
                        <span class="input-group-text pl-3 pr-3 rounded-right" (click)="d.toggle()">
                          <i class="fa fa-calendar"></i>
                        </span>
                      </div>
                      <div *ngIf="submitted && f.DOB.errors" class="invalid-feedback">
                        <div *ngIf="f.DOB.errors.required">
                          Date of birth is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="label" translate="cob">COUNTRY OF BIRTH
                      <span class="text-danger">*</span></label>
                    <select formControlName="countryBirth" (change)="onCountryBirthChange($event.target.value)" required
                      (focus)="onFocus($event)" (blur)="onBlur($event)" [(ngModel)]="this.person.countryBirth"
                      name="countryBirth" id="countryBirth" class="form-control" [ngClass]="{
                        'is-invalid': submitted && f.countryBirth.errors
                      }">
                      <option value="" translate>-- Please Select --</option>
                      <option *ngFor="let item of mDataArrayCountryBirth" [value]="item.country_id">
                        {{ item.country_name }}
                      </option>
                    </select>
                    <div *ngIf="submitted && f.countryBirth.errors" class="invalid-feedback">
                      <div *ngIf="f.countryBirth.errors.required">
                        Country birth is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="label" translate="tel">TELEPHONE <span class="text-danger">*</span></label>
                    <input formControlName="tel" type="text" class="form-control" required id="tel" name="tel"
                      autocomplete="off" (focus)="onFocus($event)" (blur)="onBlur($event)"
                      [ngClass]="{ 'is-invalid': submitted && f.tel.errors }" maxlength="40" [(ngModel)]="person.tel"
                      [placeholder]="'Telephone /Mobile number' | translate" />
                    <div *ngIf="submitted && f.tel.errors" class="invalid-feedback">
                      <div *ngIf="f.tel.errors.required">
                        Telephone is required
                      </div>
                      <div *ngIf="f.tel.errors.pattern">
                        Telephone is accept number only
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="label" translate="occ">OCCUPATION <span class="text-danger">*</span></label>
                    <input formControlName="occupation" type="text" class="form-control" id="occupation"
                      autocomplete="off" (focus)="onFocus($event)" (blur)="onBlur($event)" name="occupation"
                      [(ngModel)]="person.occupation" maxlength="100" required [placeholder]="'Occupation' | translate"
                      [ngClass]="{
                        'is-invalid': submitted && f.occupation.errors
                      }" />
                    <div *ngIf="submitted && f.occupation.errors" class="invalid-feedback">
                      <div *ngIf="f.occupation.errors.pattern">
                        Occupation is invalid
                      </div>
                      <div *ngIf="f.occupation.errors.required">
                        Occupation is required
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="label" translate="res">RESIDENTIAL ADDRESS
                      <span class="text-danger">*</span></label>
                    <input formControlName="resAddress" type="text" class="form-control" id="resAddress"
                      autocomplete="off" (focus)="onFocus($event)" (blur)="onBlur($event)" name="resAddress"
                      [(ngModel)]="person.resAddress" maxlength="200" required
                      [placeholder]="'Residential Address' | translate" [ngClass]="{
                        'is-invalid': submitted && f.resAddress.errors
                      }" />
                    <div *ngIf="submitted && f.resAddress.errors" class="invalid-feedback">
                      <div *ngIf="f.resAddress.errors.pattern">
                        Residential Address is invalid
                      </div>
                      <div *ngIf="f.resAddress.errors.required">
                        Residential Address is required
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="label" translate="stp">STATE / PROVINCE
                      <span class="text-danger">*</span></label>
                    <input formControlName="province" type="text" class="form-control" id="province" name="province"
                      autocomplete="off" (focus)="onFocus($event)" (blur)="onBlur($event)" [(ngModel)]="person.province"
                      maxlength="200" required [placeholder]="'State / Province' | translate" [ngClass]="{
                        'is-invalid': submitted && f.province.errors
                      }" />
                    <div *ngIf="submitted && f.province.errors" class="invalid-feedback">
                      <div *ngIf="f.province.errors.pattern">
                        Province is invalid
                      </div>
                      <div *ngIf="f.province.errors.required">
                        State / Province is required
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="label" translate="coc">COUNTRY OF CITIZENSHIP
                      <span class="text-danger">*</span></label>
                    <select formControlName="countryCity" (change)="
                        onCountryOfCityChange($event.target.value, $event)
                      " required [(ngModel)]="this.person.countryCity" name="countryCity" id="countryCity"
                      (focus)="onFocus($event)" (blur)="onBlur($event)" class="form-control" [ngClass]="{
                        'is-invalid': submitted && f.countryCity.errors
                      }">
                      <option value="" translate>-- Please Select --</option>
                      <option *ngFor="let item of mDataArrayCountry" [value]="item.country_id">
                        {{ item.country_name }}
                      </option>
                    </select>
                    <div *ngIf="submitted && f.countryCity.errors" class="invalid-feedback">
                      <div *ngIf="f.countryCity.errors.required">
                        Country Of Citizenship is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>

      <div class="row mt-5" *ngIf="person.is_request_doc && isOrdinaryPassport">
        <div class="col">
          <fieldset>
            <legend class="section-header-legend bg-blue-primary text-white">
              REQUEST INFORMATION
            </legend>
            <div class="form-control-wrapper">
              <div class="row">
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-12">
                      <label class="label text-uppercase" translate>Hotel reservation document
                        <span class="text-danger">*</span></label>
                      <p style="font-size: 12px" class="text-danger">
                        (JPG, JPEG, PNG, max size 10MB)
                      </p>
                      <div class="mt-1">
                        <div class="input-group mb-3">
                          <input readonly formControlName="myfileHotel" #myfileHotel type="text" autocomplete="off"
                            class="form-control form-control-sm bg-white" name="myfileHotel" id="myfileHotel"
                            [(ngModel)]="person.hotel_doc" [ngClass]="{
                              'is-invalid': submitted && f.myfileHotel.errors
                            }" placeholder="Upload Hotel reservation document" />
                          <div class="input-group-append pt-2 pb-2" (click)="hotelInputUpload.click()">
                            <span class="input-group-text pl-4 pr-4 text-white" style="
                                background-color: #1b76bb;
                                font-size: 20px;
                                cursor: pointer;
                              " id="basic-addon2">
                              <i class="fa fa-paperclip"></i>
                            </span>
                          </div>
                        </div>
                        <input accept="image/x-png,image/jpeg" style="display: none" #hotelInputUpload
                          autocomplete="off" (change)="onFileHotelChanged($event)" type="file" />
                        <div *ngIf="submitted && f.myfileHotel.errors" class="invalid-feedback">
                          <div *ngIf="f.myfileHotel.errors.required">
                            File is request
                          </div>
                        </div>

                        <div *ngIf="hotel_doc_big_size" class="text-danger">
                          <small>File is big size then 10 MB</small>
                        </div>
                        <div *ngIf="
                            submitted &&
                            person.is_request_doc == true &&
                            person.hotel_doc == ''
                          " class="text-danger">
                          <small>Please upload hotel reservation document</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <label class="label text-uppercase" translate>Flight reservation document
                        <span class="text-danger">*</span></label>
                      <p style="font-size: 12px" class="text-danger">
                        (JPG, JPEG, PNG, max size 10MB)
                      </p>
                      <div class="mt-1">
                        <div class="input-group mb-3">
                          <input readonly formControlName="myfileFlight" #myfileFlight type="text" autocomplete="off"
                            class="form-control bg-white" name="myfileFlight" id="myfileFlight"
                            [(ngModel)]="person.flight_doc" [ngClass]="{
                              'is-invalid': submitted && f.myfileFlight.errors
                            }" placeholder="Upload Flight reservation document" />
                          <div class="input-group-append pt-2 pb-2" (click)="flightInputUpload.click()">
                            <span class="input-group-text pl-4 pr-4 text-white" style="
                                background-color: #1b76bb;
                                font-size: 20px;
                                cursor: pointer;
                              " id="basic-addon2">
                              <i class="fa fa-paperclip"></i>
                            </span>
                          </div>
                        </div>

                        <input accept="image/x-png,image/jpeg" style="display: none" #flightInputUpload
                          autocomplete="off" (change)="onFileFlightChanged($event)" type="file" />
                        <div *ngIf="submitted && f.myfileFlight.errors" class="invalid-feedback">
                          <div *ngIf="f.myfileFlight.errors.required">
                            File is request
                          </div>
                        </div>

                        <div *ngIf="flight_doc_big_size" class="text-danger">
                          <small>File is big size then 10 MB</small>
                        </div>
                        <div *ngIf="
                            submitted &&
                            person.is_request_doc == true &&
                            person.flight_doc == ''
                          " class="text-danger">
                          <small>Please upload flight reservation document</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-12">
                      <label class="label text-uppercase" translate>Third country visa document
                        <span class="text-danger">*</span></label>
                      <p style="font-size: 12px" class="text-danger">
                        (JPG, JPEG, PNG, max size 10MB)
                      </p>
                      <div class="mt-1">
                        <div class="input-group mb-3">
                          <input readonly formControlName="ThirdCountryVisa" #ThirdCountryVisa type="text"
                            autocomplete="off" class="form-control bg-white" name="ThirdCountryVisa"
                            id="ThirdCountryVisa" [(ngModel)]="person.third_visa_doc" [ngClass]="{
                              'is-invalid':
                                submitted && f.ThirdCountryVisa.errors
                            }" placeholder="Upload Third country visa" />
                          <div class="input-group-append pt-2 pb-2" (click)="thirdVisaInputUpload.click()">
                            <span class="input-group-text pl-4 pr-4 text-white" style="
                                background-color: #1b76bb;
                                font-size: 20px;
                                cursor: pointer;
                              " id="basic-addon2">
                              <i class="fa fa-paperclip"></i>
                            </span>
                          </div>
                        </div>

                        <input accept="image/x-png,image/jpeg" style="display: none" #thirdVisaInputUpload
                          autocomplete="off" (change)="onFileThirdVisaChanged($event)" type="file" />
                        <div *ngIf="submitted && f.ThirdCountryVisa.errors" class="invalid-feedback">
                          <div *ngIf="f.ThirdCountryVisa.errors.required">
                            File is request
                          </div>
                        </div>

                        <div *ngIf="third_visa_doc_big_size" class="text-danger">
                          <small>File is big size then 10 MB</small>
                        </div>
                        <div *ngIf="
                            submitted &&
                            person.is_request_doc == true &&
                            person.third_visa_doc == ''
                          " class="text-danger">
                          <small>Please upload third country visa document</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <label class="label text-uppercase" translate>Guarantor document
                        <span class="text-danger">*</span></label>
                      <p style="font-size: 12px" class="text-danger">
                        (JPG, JPEG, PNG, max size 10MB)
                      </p>
                      <div class="mt-1">
                        <div class="input-group mb-3">
                          <input readonly formControlName="guarantor" #ThirdCountryVisa type="text" autocomplete="off"
                            class="form-control bg-white" name="guarantor" id="guarantor"
                            [(ngModel)]="person.guarantor_doc" [ngClass]="{
                              'is-invalid': submitted && f.guarantor.errors
                            }" placeholder="Upload Guarantor document" />
                          <div class="input-group-append pt-2 pb-2" (click)="guarantorInputUpload.click()">
                            <span class="input-group-text pl-4 pr-4 text-white" style="
                                background-color: #1b76bb;
                                font-size: 20px;
                                cursor: pointer;
                              " id="basic-addon2">
                              <i class="fa fa-paperclip"></i>
                            </span>
                          </div>
                        </div>

                        <input accept="image/x-png,image/jpeg" style="display: none" #guarantorInputUpload
                          autocomplete="off" (change)="onFileGuarantorInputUploadChanged($event)" type="file" />
                        <div *ngIf="submitted && f.guarantor.errors" class="invalid-feedback">
                          <div *ngIf="f.guarantor.errors.required">
                            File is request
                          </div>
                        </div>

                        <div *ngIf="guarantor_visa_doc_big_size" class="text-danger">
                          <small>File is big size then 10 MB</small>
                        </div>
                        <div *ngIf="
                            submitted &&
                            person.is_request_doc == true &&
                            person.guarantor_doc == ''
                          " class="text-danger">
                          <small>Please upload hotel reservation document</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-12">
                      <label class="label text-uppercase" translate>Bank Statement
                        <span class="text-danger">*</span></label>
                      <p style="font-size: 12px" class="text-danger">
                        (JPG, JPEG, PNG, max size 10MB)
                      </p>
                      <div class="mt-1">
                        <div class="input-group mb-3">
                          <input readonly formControlName="bank_statement" #bank_statement type="text"
                            autocomplete="off" class="form-control bg-white" name="bank_statement" id="BankStatement"
                            placeholder="Upload Bank Statement" [(ngModel)]="person.bank_statement_doc" [ngClass]="{
                              'is-invalid': submitted && f.bank_statement.errors
                            }" />
                          <div class="input-group-append pt-2 pb-2" (click)="bankStatementInputUpload.click()">
                            <span class="input-group-text pl-4 pr-4 text-white" style="
                                background-color: #1b76bb;
                                font-size: 20px;
                                cursor: pointer;
                              " id="basic-addon2">
                              <i class="fa fa-paperclip"></i>
                            </span>
                          </div>
                        </div>

                        <input accept="image/x-png,image/jpeg" style="display: none" #bankStatementInputUpload
                          autocomplete="off" (change)="onFileBankStatementChanged($event)" type="file" />
                        <div *ngIf="submitted && f.bank_statement.errors" class="invalid-feedback">
                          <div *ngIf="f.bank_statement.errors.required">
                            File is request
                          </div>
                        </div>

                        <div *ngIf="bank_statement_doc_big_size" class="text-danger">
                          <small>File is big size then 10 MB</small>
                        </div>
                        <div *ngIf="
                            submitted &&
                            person.is_request_doc == true &&
                            person.bank_statement_doc == ''
                          " class="text-danger">
                          <small>Please upload bank statement</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>

      <div class="row" *ngIf="isOrdinaryPassport">
        <div class="col-md-6 mt-5">
          <fieldset>
            <legend class="section-header-legend bg-blue-primary text-white" translate>
              PASSPORT DETAILS
            </legend>
            <div class="form-control-wrapper">
              <div class="form-group">
                <label class="label" translate="ppn">PASSPORT NO. <span class="text-danger">*</span></label>
                <input formControlName="passportNumber" type="text" class="form-control" id="passportNumber"
                  autocomplete="off" (focus)="onFocus($event)" (blur)="onBlur($event)" name="passportNumber"
                  [(ngModel)]="person.passportNumber" maxlength="15" required
                  [placeholder]="'Passport Number' | translate" [ngClass]="{
                    'is-invalid': submitted && f.passportNumber.errors
                  }" />
                <div *ngIf="submitted && f.passportNumber.errors" class="invalid-feedback">
                  <div *ngIf="f.passportNumber.errors.required">
                    Passport Number is required
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="label" translate="doi">DATE OF ISSUE <span class="text-danger">*</span></label>
                <div class="input-group">
                  <input autocomplete="off" (ngModelChange)="IssueOnChange($event)" formControlName="issueDate" readonly
                    autocomplete="off" (focus)="onFocus($event)" (blur)="onBlur($event)" type="text"
                    [(ngModel)]="person.issueDate" id="issueDate" required name="issueDate" appMaskDate maxlength="40"
                    [maxDate]="today" class="form-control bg-white" placeholder="DD-MM-YYYY" ngbDatepicker
                    #s="ngbDatepicker" [ngClass]="{
                      'is-invalid': submitted && f.issueDate.errors
                    }" />

                  <div class="input-group-append pb-2 pt-2">
                    <span class="input-group-text pl-3 pr-3 rounded-right" (click)="s.toggle()">
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                  <div *ngIf="submitted && f.issueDate.errors" class="invalid-feedback">
                    <div *ngIf="f.issueDate.errors">
                      Passport Date of Issue is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="label" translate="epd">EXPIRY DATE <span class="text-danger">*</span></label>
                <div class="input-group">
                  <input autocomplete="off" formControlName="expiredDate" type="text" (focus)="onFocus($event)"
                    autocomplete="off" (blur)="onBlur($event)" id="expiredDate" readonly
                    (ngModelChange)="ExpiredOnChange($event)" [(ngModel)]="person.expiredDate" maxlength="40"
                    appMaskDate required name="expiredDate" class="form-control bg-white" placeholder="DD-MM-YYYY"
                    ngbDatepicker #e="ngbDatepicker" [ngClass]="{
                      'is-invalid':
                        (submitted && f.expiredDate.errors) ||
                        (submitted && expiredDate.day > 0 && isExpiredDate)
                    }" />
                  <div class="input-group-append pb-2 pt-2">
                    <span class="input-group-text pl-3 pr-3 rounded-right" (click)="e.toggle()">
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                  <div *ngIf="submitted && f.expiredDate.errors" class="invalid-feedback">
                    <div *ngIf="f.expiredDate.errors">
                      Passport Expiry Date is required
                    </div>
                  </div>
                  <div *ngIf="isExpiredDate" class="custom-invalid-feedback">
                    Passport is expired
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="label" translate="cop">COUNTRY OF PASSPORT <span class="text-danger">*</span></label>
                <select formControlName="countryOfPassport" (focus)="onFocus($event)" (blur)="onBlur($event)"
                  (change)="onCountryOfCityChange($event.target.value, $event)" required
                  [(ngModel)]="person.countryOfPassport" name="countryOfPassport" id="countryOfPassport"
                  class="form-control" [ngClass]="{
                    'is-invalid': submitted && f.countryOfPassport.errors
                  }">
                  <option value="" translate>-- Please Select --</option>
                  <option *ngFor="let item of mDataArrayCountry" [selected]="selectedCountry == item.country_id"
                    [value]="item.country_id">
                    {{ item.country_name }}
                  </option>
                </select>
                <div *ngIf="submitted && f.countryOfPassport.errors" class="invalid-feedback">
                  <div *ngIf="f.countryOfPassport.errors.required">
                    Country of Passport is required
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>

        <div class="col-md-6 mt-5">
          <fieldset>
            <legend class="section-header-legend bg-blue-primary text-white" translate>
              TRAVEL DETAILS
            </legend>
            <div class="form-control-wrapper">
              <!-- <div class="form-group">
                <label class="label" translate="vst">VISA TYPE <span class="text-danger">*</span></label>
                <select formControlName="visaType" (change)="onVisaTypeChange($event.target.value)" name="visaType"
                  (focus)="onFocus($event)" (blur)="onBlur($event)" id="visaType" class="form-control" required
                  [(ngModel)]="person.visaType" [ngClass]="{ 'is-invalid': submitted && f.visaType.errors }">
                  <option value="" translate>-- Please Select --</option>
                  <option *ngFor="let item of mDataArrayVisaType" [value]="item.visa_type_id" translate>
                    {{ item.visa_type_name }}
                  </option>
                </select>
                <div *ngIf="submitted && f.visaType.errors" class="invalid-feedback">
                  <div *ngIf="f.visaType.errors.required">
                    Visa Type is required
                  </div>
                </div>
              </div> -->
              <div class="form-group">
                <!-- <label class="label" translate="poe">PORT OF ENTRY <span class="text-danger">*</span></label> -->
                <!-- <br /> -->
                <label class="text-primary2">
                  <span translate="port_allow">You can only enter through these following designated
                    ports.</span>
                </label>
                <!-- <select formControlName=" portOfEntry" (change)="onPortOfEntryChange($event.target.value)"
                    (focus)="onFocus($event)" (blur)="onBlur($event)" name="portOfEntry" id="portOfEntry"
                    class="form-control" required [(ngModel)]="person.portOfEntry" [ngClass]="{
                    'is-invalid': submitted && f.portOfEntry.errors
                  }">
                    <option value="" translate>-- Please Select --</option>
                    <option *ngFor="let item of filteredPortOfEntry" value="{{ item.port_of_entry_id }}">
                      {{ item.port_of_entry_name }}
                    </option>
                    </select> -->
                <!-- <div *ngIf="submitted && f.portOfEntry.errors" class="invalid-feedback">
                  <div *ngIf="f.portOfEntry.errors.required">
                    Port of entry is required
                  </div>
                </div> -->
                <ul class="port-list">
                  <li *ngFor="let item of filteredPortOfEntry; let idx = index">
                    <img
                      [src]="item.port_of_entry_name.includes('Airport') ? '/assets/images/plane-icon.png' : item.port_of_entry_name.includes('Bridge') ? '/assets/images/bridge-icon.png' : item.port_of_entry_name.includes('Checkpoint') ? '/assets/images/road-icon.png' : '/assets/images/train-icon.png'"
                      style="width: 35px; height: 35px; padding: 5px;">
                    {{ idx + 1 }}. &nbsp; {{ item.port_of_entry_name }}
                  </li>
                </ul>
              </div>
              <div class="form-group">
                <label class="label" translate="ide">INTENDED DATE OF ENTRY
                  <span class="text-danger">*</span></label>
                <div class="input-group">
                  <input (ngModelChange)="IntendedOnChange($event)" autocomplete="off" formControlName="intendedDate"
                    autocomplete="off" (focus)="onFocus($event)" (blur)="onBlur($event)" id="intendedDate" type="text"
                    required [(ngModel)]="person.intendedDate" name="intendedDate" class="form-control bg-white"
                    placeholder="DD-MM-YYYY" appMaskDate maxlength="40" ngbDatepicker #i="ngbDatepicker"
                    [maxDate]="next_2month" [minDate]="minDate" readonly [ngClass]="{
                      'is-invalid':
                        (submitted && f.intendedDate.errors) ||
                        (submitted && isIntendedError)
                    }" />
                  <div class="input-group-append pb-2 pt-2">
                    <span id="selectIntendedDatebtn" class="input-group-text pl-3 pr-3 rounded-right"
                      (click)="i.toggle()">
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                  <div *ngIf="submitted && f.intendedDate.errors" class="invalid-feedback">
                    <div *ngIf="f.intendedDate.errors.required">
                      Intended Date of Entry is required
                    </div>
                  </div>
                  <div class="custom-invalid-feedback" *ngIf="isIntendedError && app.SelectedLang == 'en'">
                    {{ intentedMessage }}
                  </div>
                  <div class="custom-invalid-feedback" *ngIf="isIntendedError && app.SelectedLang == 'cn'">
                    {{ intentedMessage_cn }}
                  </div>
                  <div class="custom-invalid-feedback" *ngIf="isIntendedError && app.SelectedLang == 'fr'">
                    {{ intentedMessage_fr }}
                  </div>
                </div>
              </div>
              <div>

              </div>
              <div class="form-group">
                <label class="label" translate="vh">VEHICLE<span class="text-danger">*</span></label>
                <input formControlName="vehicle" type="text" class="form-control" id="vehicle" name="vehicle"
                  autocomplete="off" (focus)="onFocus($event)" (blur)="onBlur($event)" [(ngModel)]="person.vehicle"
                  maxlength="40" required [placeholder]="'vehicle_hint' | translate"
                  [ngClass]="{ 'is-invalid': submitted && f.vehicle.errors }" />
                <div *ngIf="submitted && f.vehicle.errors" class="invalid-feedback">
                  <div *ngIf="f.vehicle.errors.pattern">Vehicle is invalid</div>
                  <div *ngIf="f.vehicle.errors.required">
                    Vehicle is required
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>

      <div class="row mt-5" *ngIf="isOrdinaryPassport">
        <div class="col">
          <fieldset>
            <legend class="section-header-legend bg-blue-primary text-white" translate>
              TRAVEL DETAILS
            </legend>
            <div class="form-control-wrapper">
              <div class="form-group">
                <label class="label" translate="pot">PURPOSE OF TRIP <span class="text-danger">*</span></label>
                <select formControlName="purposeOfTrip" (change)="onPurposeChange($event.target.value)"
                  (focus)="onFocus($event)" (blur)="onBlur($event)" name="purposeOfTrip" id="purposeOfTrip"
                  class="form-control" required [(ngModel)]="person.purposeOfTrip" [ngClass]="{
                    'is-invalid': submitted && f.purposeOfTrip.errors
                  }">
                  <option value="" translate>-- Please Select --</option>
                  <option *ngFor="let item of mPurpose" [value]="item.purpose_id" translate>
                    {{ item.purpose_name }}
                  </option>
                </select>
                <div *ngIf="submitted && f.purposeOfTrip.errors" class="invalid-feedback">
                  <div *ngIf="f.purposeOfTrip.errors.required">
                    Purpose of trip is required
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="label" translate="adv">ADDRESS DURING VISIT
                  <span class="text-danger">*</span></label>
                <input formControlName="addressDuringVisit" type="text" class="form-control" id="addressDuringVisit"
                  autocomplete="off" (focus)="onFocus($event)" (blur)="onBlur($event)" name="addressDuringVisit"
                  maxlength="240" [(ngModel)]="person.addressDuringVisit" required
                  [placeholder]="'address_during_visit_hint' | translate" [ngClass]="{
                    'is-invalid': submitted && f.addressDuringVisit.errors
                  }" />
                <div *ngIf="submitted && f.addressDuringVisit.errors" class="invalid-feedback">
                  <div *ngIf="f.addressDuringVisit.errors.pattern">
                    Address during visit is invalid
                  </div>
                  <div *ngIf="f.addressDuringVisit.errors.required">
                    Address during visit is required
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="label" translate="nd">NEXT DESTINATION (OPTIONAL)</label>
                <input formControlName="next_destination" type="text" pattern="[a-zA-Z0-9 ,']*" class="form-control"
                  autocomplete="off" (focus)="onFocus($event)" (blur)="onBlur($event)" id="next_destination"
                  name="next_destination" maxlength="240" [ngClass]="{
                    'is-invalid': submitted && f.next_destination.errors
                  }" [(ngModel)]="person.next_destination" [placeholder]="'Next destination' | translate" />
                <div *ngIf="submitted && f.next_destination.errors" class="invalid-feedback">
                  <div *ngIf="f.next_destination.errors.pattern">
                    Next destination is invalid
                  </div>
                  <div *ngIf="f.next_destination.errors.required">
                    Next destination is required
                  </div>
                </div>
              </div>
              <div class="form-group form-check text-center">
                <input formControlName="acceptTerm" required (change)="checkAccept()" type="checkbox"
                  (focus)="onFocus($event)" (blur)="onBlur($event)" class="form-check-input" name="acceptTerm"
                  id="acceptTerm" [ngClass]="{ 'is-invalid': submitted && f.acceptTerm.errors }" />
                <label class="font-weight-light" class="form-check-label text-secondary" for="acceptTerm"
                  translate="iagree">I agree to the
                  <a href="https://laoevisa.gov.la/term" target="_blank" style="color: #1a77ba"
                    translate="termsandcondition">Terms and Conditions</a>
                </label>
                <div *ngIf="submitted && f.acceptTerm.errors" class="invalid-feedback">
                  <div *ngIf="f.acceptTerm.errors.required">
                    Please accept term and conditions
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
      <div class="row mt-5" *ngIf="NIB3">
        <div class="col">
          <fieldset>
            <legend class="section-header-legend bg-blue-primary text-white" translate>
              RACE
            </legend>
            <div class="form-control-wrapper">
              <div class="row">
                <div class="col">
                  <!-- <label class="label" translate>Race<span class="text-danger">*</span></label>
                  <br> -->
                  <div class="my-2">
                    <mat-radio-group aria-label="Race *" [(ngModel)]="person.race"
                      [ngModelOptions]="{standalone: true}">
                      <mat-radio-button id="race-other" (change)="onRaceChange($event)" value="0" color="warn">
                        Others
                      </mat-radio-button>
                      &nbsp;&nbsp;&nbsp;
                      <mat-radio-button id="race-lao" (change)="onRaceChange($event)" value="304"
                        color="warn">Lao</mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="person.race == 304">
                <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                  <label for="family_side" class="label" translate>
                    Which side of your family comes from th lao PDR?<span class="text-danger">*</span>
                  </label>
                  <select [ngClass]="{'is-invalid': family_side_touch && !person.family_side}"
                    [(ngModel)]="person.family_side" [ngModelOptions]="{standalone: true}" (blur)="onBlur($event)"
                    (focus)="onFocus($event)" id="family_side" class="form-control">
                    <option value="" translate>-- Please Select --</option>
                    <option value="paternal">Paternal</option>
                    <option value="maternal">Maternal</option>
                    <option value="both">Both Sides</option>
                  </select>
                  <div *ngIf="!person.family_side" class="text-danger" style="font-size: .875rem;">
                    Side of your family comes from th lao PDR is required
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                  <label class="label" for="family_member_names" translate>
                    Please provide the names of your family members that from come the lao PDR<span
                      class="text-danger">*</span>
                  </label>
                  <input maxLength="150"
                    [ngClass]="{'is-invalid': family_member_names_touch && !person.family_member_names}"
                    id="family_member_names" [(ngModel)]="person.family_member_names"
                    [ngModelOptions]="{standalone: true}" (blur)="onBlur($event)" (focus)="onFocus($event)" type="text"
                    placeholder="The name(s) of your family memebers that come from the lao PDR" class="form-control"
                    required />
                  <div *ngIf="!person.family_member_names" class="text-danger" style="font-size: .875rem;">
                    The name(s) of your family memebers is required
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                  <label class="label" for="family_relation" translate>
                    Relationship to you<span class="text-danger">*</span>
                  </label>
                  <input id="family_relation"
                    [ngClass]="{'is-invalid': family_relation_touch && !person.family_relation}"
                    [(ngModel)]="person.family_relation" [ngModelOptions]="{standalone: true}" (blur)="onBlur($event)"
                    (focus)="onFocus($event)" class="form-control" type="text" placeholder="Family relationship"
                    required />
                  <div *ngIf="!person.family_relation" class="text-danger" style="font-size: .875rem;">
                    Family relationship is required
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                  <div>
                    <label class="label" translate>Proof of laotian<span class="text-danger">*</span></label>
                  </div>
                  <button type="button" class="btn btn-primary text-white" data-toggle="modal" id="btnshowModalPhoto"
                    data-target=".bd-proof-modal-lg">
                    <i class="fa fa-upload"></i>
                    &nbsp;
                    <span class="text-uppercase" translate>Upload</span>
                  </button>
                  <div *ngIf="proofFileCount !== 0 && person.proof_of_laotian;else notUpload" class="text-success"
                    style="font-size: 0.875rem;">
                    <i class="fa" [ngClass]="{'fa-check-circle-o': person.proof_of_laotian}"></i>&nbsp;
                    Uploaded successfully
                  </div>
                  <ng-template #notUpload>
                    <div class="text-danger" style="font-size: 0.875rem;">
                      Proof of laotian is required
                    </div>
                  </ng-template>
                </div>
              </div>
              <div class="form-group form-check text-center">
                <input formControlName="acceptTerm" required (change)="checkAccept()" type="checkbox"
                  (focus)="onFocus($event)" (blur)="onBlur($event)" class="form-check-input" name="acceptTerm"
                  id="acceptTerm" [ngClass]="{ 'is-invalid': submitted && f.acceptTerm.errors }" />
                <label class="font-weight-light" class="form-check-label text-secondary" for="acceptTerm"
                  translate="iagree">I agree to the
                  <a href="https://laoevisa.gov.la/term" target="_blank" style="color: #1a77ba"
                    translate="termsandcondition">Terms and Conditions</a>
                </label>
                <div *ngIf="submitted && f.acceptTerm.errors" class="invalid-feedback">
                  <div *ngIf="f.acceptTerm.errors.required">
                    Please accept term and conditions
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>

      <div class="row text-center mt-5">
        <div class="col">
          <button class="btn btn-primary btn-icon d-inline-flex" [disabled]="
              isExpiredDate ||
              isIntendedError ||
              (person.race == 304 &&
                (
                  !person.family_side ||
                  !person.family_member_names ||
                  !person.family_relation ||
                  !person.proof_of_laotian
                )
              )
            " id="btnContinue" translate="continue" (click)="checkDuplicate()">
            Continue &nbsp;
            <svg aria-hidden="true" class="svg-inline--fa fa-arrow-alt-right fa-w-14" data-icon="arrow-alt-right"
              data-prefix="fal" focusable="false" role="img" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M32 212.57A20.57 20.57 0 0 1 52.57 192H224V75a8 8 0 0 1 13.66-5.66L410 241.44a20.56 20.56 0 0 1 0 29.11L237.66 442.63A8 8 0 0 1 224 437V320H52.57A20.57 20.57 0 0 1 32 299.42v-86.85m-32 0v86.85A52.63 52.63 0 0 0 52.57 352H192v85c0 35.51 43 53.5 68.27 28.3l172.3-172.08a52.55 52.55 0 0 0 0-74.4L260.27 46.71C235.15 21.62 192 39.36 192 75v85H52.57A52.63 52.63 0 0 0 0 212.57z"
                fill="currentColor"></path>
            </svg>
          </button>
        </div>
      </div>
    </form>
  </div>
</main>
<div class="modal fade bd-proof-modal-lg" id="exampleModal" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header" style="padding-bottom: 5px">
        <button type="button" class="close pull-right" style="color: #dc3545" data-dismiss="modal" aria-label="Close"
          (click)="removeProofFile()">
          <i class="fa fa-times-circle"></i>
        </button>
      </div>
      <div class="modal-body">
        <input type="file" #proofFile accept="image/x-png,image/jpeg" id="proofFile" name="proofFile"
          style="display: none" (change)="proofFileChangeEvent($event)" />
        <div style="
            background-color: #f9f9f9;
            padding: 5px;
            border-radius: 50px;
            border: 0.2px solid #ced4da;
          " class="mx-5 d-flex justify-content-between align-items-center">
          <button *ngIf="proofFileCount == 0" type="button" (click)="proofFile.click()"
            class="btn btn-primary btn-sm text-white text-uppercase">
            <i class="fa fa-search"></i>
            &nbsp;Browse File
          </button>
          <button *ngIf="proofFileCount !== 0" type="button" class="btn btn-sm btn-danger pull-right text-uppercase"
            style="color: #fff" [disabled]="proofFileCount == 0" (click)="removeProofFile()">
            <i class="fa fa-trash"></i>&nbsp;remove
          </button>
          <span class="text-truncate">{{ proofFileName }}</span>
          <button [disabled]="proofFileCount == 0" (click)="onUploadProofFile()" type="button"
            class="btn btn-success btn-sm pull-right text-uppercase" data-dismiss="modal">
            <i class="fa fa-check"></i>
            &nbsp;Yes, upload
          </button>
        </div>

        <div *ngIf="proofFileCount !== 0" style="
            height: 400px;
            overflow-x: auto;
            background-color: #f9f9f9;
            border-radius: 5px;
          " class="row m-5">
          <div class="mx-auto col-lg-6 col-md-8 col-sm-12">
            <img [src]="proofFilePreview" alt="prooffile" width="100%" height="auto" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #content let-modal>
  <div class="modal-header">
    <h1 class="modal-title text-red text-center w-100" translate="notice">
      Notice
    </h1>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <p *ngIf="app.SelectedLang == 'en'" class="text-secondary">
      Citizens of
      <span class="font-weight-bold text-blue" style="font-size: 20px">{{
        citizens
        }}</span>
      are allowed to enter the Lao PDR without visa requirement for a maximum
      stay of
      <span class="text-blue font-weight-bold">{{ days }} days</span> per visit.
      <br />
    </p>
    <p *ngIf="app.SelectedLang == 'en'" class="text-secondary">
      Meanwhile, by obtaining an eVisa, visitors may stay in the Lao PDR up to
      30 days and the stay permit can be extended at the Immigration office in
      the Lao PDR before the expiry date.
    </p>
    <p *ngIf="app.SelectedLang == 'cn'" class="text-secondary">
      <span class="font-weight-bold text-blue" style="font-size: 20px">{{
        citizens
        }}</span>
      国家居民不需要申请赴老挝的签证，无须您可以免签证在老挝停留
      <span class="text-blue font-weight-bold">{{ days }} 天。</span>30 天
      后您必须离开老挝，并在您需要时再来老挝
    </p>
    <br />
    <!-- <p *ngIf="app.SelectedLang == 'en'" class="text-secondary">However, by obtaining an eVisa, you can stay in the Lao
      PDR up to 30 days and your stay permit can be extended at the Immigration office without having to leave the Lao
      PDR.</p> -->
    <p *ngIf="app.SelectedLang == 'cn'" class="text-secondary">
      但是，您如果申请了电子签证，可以在老挝停留30天，您无须离境就能向移民局申请延长在老挝的停留时间。
    </p>
    <p [innerHTML]="except_message"></p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')" translate>
      Close
    </button>
  </div>
</ng-template>
<app-footer></app-footer>