import { Injectable } from "@angular/core";
import { RestService } from "./rest.service";

@Injectable({
  providedIn: "root",
})
export class CountCartService {
  countCart = 0;
  constructor(private rest: RestService) {}

  funcCountCart() {
    this.rest.getCountCart().subscribe(async (result) => {
      if (result != null) {
        this.countCart = await result[0].countCart;
      }
    });
  }
}
