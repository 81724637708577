import { Component, OnInit, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ApplicationComponent } from "../application/application.component";

@Component({
  selector: "app-pop-up",
  templateUrl: "./pop-up.component.html",
  styleUrls: ["./pop-up.component.css"],
})
export class PopUpComponent implements OnInit {
  duplicateData: any;

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<ApplicationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.duplicateData = data;
  }

  ngOnInit() {
    console.log(this.duplicateData);
  }
  onContinue() {
    this.dialogRef.close("continue");
  }
}
