
  <app-nav></app-nav>
  <main class="page page-information">
    <aside class="header-image-half"></aside>
    <div class="container content-section">

      <h3 class="content-section-title text-right mt-0" translate>
        {{model.article_title}}
      </h3>

      <div class="row">
        <div class="col-md-12" [innerHTML]="model.article_detail">

        </div>
      </div>
    </div>

  </main>
  <app-footer></app-footer>