/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./terms-conditions.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../nav/nav.component.ngfactory";
import * as i3 from "../nav/nav.component";
import * as i4 from "../../app.component";
import * as i5 from "../../services/form-data.service";
import * as i6 from "../../services/rest.service";
import * as i7 from "../../services/event-emitter.service";
import * as i8 from "../../services/count-cart.service";
import * as i9 from "@angular/router";
import * as i10 from "@ngx-translate/core";
import * as i11 from "../footer/footer.component.ngfactory";
import * as i12 from "../footer/footer.component";
import * as i13 from "./terms-conditions.component";
import * as i14 from "../../services/service-seo.service";
var styles_TermsConditionsComponent = [i0.styles];
var RenderType_TermsConditionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TermsConditionsComponent, data: {} });
export { RenderType_TermsConditionsComponent as RenderType_TermsConditionsComponent };
export function View_TermsConditionsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-nav", [], null, null, null, i2.View_NavComponent_0, i2.RenderType_NavComponent)), i1.ɵdid(1, 4308992, null, 0, i3.NavComponent, [i4.AppComponent, i5.FormDataService, i6.RestService, i7.EventEmitterService, i8.CountCartService, i9.Router, i9.ActivatedRoute, i1.PLATFORM_ID], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 6, "main", [["class", "page page-information"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "aside", [["class", "header-image-half"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "container content-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h1", [["class", "content-section-title text-right mt-0"], ["translate", "termsandcondition"]], null, null, null, null, null)), i1.ɵdid(6, 8536064, null, 0, i10.TranslateDirective, [i10.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "col-md-12"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-footer", [], null, null, null, i11.View_FooterComponent_0, i11.RenderType_FooterComponent)), i1.ɵdid(10, 114688, null, 0, i12.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); var currVal_0 = "termsandcondition"; _ck(_v, 6, 0, currVal_0); _ck(_v, 10, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.model; _ck(_v, 8, 0, currVal_1); }); }
export function View_TermsConditionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-terms-conditions", [], null, null, null, View_TermsConditionsComponent_0, RenderType_TermsConditionsComponent)), i1.ɵdid(1, 114688, null, 0, i13.TermsConditionsComponent, [i14.ServiceSEOService, i6.RestService, i7.EventEmitterService, i9.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TermsConditionsComponentNgFactory = i1.ɵccf("app-terms-conditions", i13.TermsConditionsComponent, View_TermsConditionsComponent_Host_0, {}, {}, []);
export { TermsConditionsComponentNgFactory as TermsConditionsComponentNgFactory };
