<div>
  <div *ngIf="isMaintainer">
    <h5 style="color: red;">*eVisa Payment Under Maintainance*</h5>
  </div>
  <div *ngIf="!isMaintainer">
    <small class="h5 font-weight-bold">We accept</small><br />
    <mat-radio-group aria-label="Choose payment type">
      <div *ngFor="let item of mPaymentMethod">
        <mat-radio-button [id]="item.payment_method_id" *ngIf="item.active == 'yes'"
          (change)="selectPaymentMethod($event, item.payment_method_name, item.detail)"
          [value]="item.payment_method_name">
          <img height="36px" [src]="item.logo" [alt]="item.detail" />
        </mat-radio-button>
      </div>
    </mat-radio-group>
    <button *ngIf="!loading" class="btn btn-warning btn-block mt-3 text-uppercase" id="paynow"
      [disabled]="!selected_payment_method" (click)="proceedCheckOut()">
      <span translate id="paynow">Proceed to checkout</span>
    </button>
  </div>
  <button *ngIf="loading" class="btn btn-warning btn-block mt-3 text-uppercase" disabled>
    Please wait...
  </button>
</div>
<div *ngIf="model">
  <form ngNoForm id="payment_form" #bcelForm action="{{ model.url }}" method="post">
    <input type="hidden" name="access_key" [(ngModel)]="model.access_key" />
    <input type="hidden" name="bill_companyname" [(ngModel)]="model.bill_companyname" />
    <input type="hidden" name="amount" [(ngModel)]="model.amount" />
    <input type="hidden" name="bill_to_address_city" [(ngModel)]="model.bill_to_address_city" />
    <input type="hidden" name="bill_to_address_country" [(ngModel)]="model.bill_to_address_country" />
    <input type="hidden" name="bill_to_address_line1" [(ngModel)]="model.bill_to_address_line1" />
    <input type="hidden" name="bill_to_address_line2" [(ngModel)]="model.bill_to_address_line2" />
    <input type="hidden" name="bill_to_address_postal_code" [(ngModel)]="model.bill_to_address_postal_code" />
    <input type="hidden" name="bill_to_address_state" [(ngModel)]="model.bill_to_address_state" />
    <input type="hidden" name="bill_to_email" [(ngModel)]="model.bill_to_email" />
    <input type="hidden" name="bill_to_forename" [(ngModel)]="model.bill_to_forename" />
    <input type="hidden" name="bill_to_phone" [(ngModel)]="model.bill_to_phone" />
    <input type="hidden" name="bill_to_surname" [(ngModel)]="model.bill_to_surname" />
    <input type="hidden" name="currency" [(ngModel)]="model.currency" />
    <input type="hidden" name="device_fingerprint_id" [(ngModel)]="model.device_fingerprint_id" />
    <input type="hidden" name="lang" [(ngModel)]="model.lang" />
    <input type="hidden" name="locale" [(ngModel)]="model.locale" />
    <input type="hidden" name="merchant_defined_data1" [(ngModel)]="model.merchant_defined_data1" />
    <input type="hidden" name="merchant_defined_data2" [(ngModel)]="model.merchant_defined_data2" />
    <input type="hidden" name="merchant_defined_data3" [(ngModel)]="model.merchant_defined_data3" />
    <input type="hidden" name="merchant_defined_data4" [(ngModel)]="model.merchant_defined_data4" />
    <input type="hidden" name="merchant_defined_data5" [(ngModel)]="model.merchant_defined_data5" />
    <input type="hidden" name="merchant_defined_data6" [(ngModel)]="model.merchant_defined_data6" />
    <input type="hidden" name="merchant_defined_data7" [(ngModel)]="model.merchant_defined_data7" />
    <input type="hidden" name="merchant_defined_data8" [(ngModel)]="model.merchant_defined_data8" />
    <input type="hidden" name="merchant_defined_data9" [(ngModel)]="model.merchant_defined_data9" />
    <input type="hidden" name="merchant_defined_data10" [(ngModel)]="model.merchant_defined_data10" />
    <input type="hidden" name="profile_id" [(ngModel)]="model.profile_id" />
    <input type="hidden" name="reference_number" [(ngModel)]="model.reference_number" />
    <input type="hidden" name="signed_date_time" [(ngModel)]="model.signed_date_time" />
    <input type="hidden" name="transaction_type" [(ngModel)]="model.transaction_type" />
    <input type="hidden" name="transaction_uuid" [(ngModel)]="model.transaction_uuid" />
    <input type="hidden" name="signed_field_names" [(ngModel)]="model.signed_field_names" />
    <input type="hidden" name="signature" [(ngModel)]="model.signature" />
  </form>
</div>