import { Location, PlatformLocation, isPlatformBrowser } from "@angular/common";
import {
  Component,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { LOCAL_STORAGE } from "@ng-toolkit/universal";
import { TranslateService } from "@ngx-translate/core";
import { Lightbox } from "ngx-lightbox";
import { AppComponent } from "src/app/app.component";
import { FormData, Person } from "src/app/models/formData";
import { CountCartService } from "src/app/services/count-cart.service";
import { FormDataService } from "src/app/services/form-data.service";
import { RestService } from "src/app/services/rest.service";
import { ServiceSEOService } from "src/app/services/service-seo.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-payment-review",
  templateUrl: "./payment-review.component.html",
  styleUrls: ["./payment-review.component.css"],
})
export class PaymentReviewComponent implements OnInit {
  node_static_url = environment.node_static_url;
  person: Person;
  mDataArrayPerson: any[];
  invoice_id = 0;
  total = 0;
  countTotal = 0;
  isEmptyCart = false;
  timeLeft = 0;
  interval: any;
  model: any = {};
  isCheckOut = false;
  @Input() formData: FormData;
  @ViewChild("bcelForm", { static: false }) testFormEl;
  mPaymentMethod: any[];
  selected_payment_method: string;
  selected_payment_active: string;
  selected_payment_method_text: string;
  _albumsPhoto = [];
  _albumsPassport = [];
  _albumsCover = [];
  _albumProoFile = [];
  proofileCol: boolean = false;
  data = {
    session_id: "",
    input_id: "",
    input_value: "",
  };
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private app: AppComponent,
    private router: Router,

    private rest: RestService,
    private formDataService: FormDataService,
    private countIncart: CountCartService,
    private _lightbox: Lightbox,
    private modalService: NgbModal,
    @Inject(LOCAL_STORAGE) private localStorage: any
  ) {}

  ngOnInit() {
    this.person = this.formDataService.getFormData();
    if (localStorage.getItem(environment.localAuthenInfo) != null) {
      const authInfo = JSON.parse(
        localStorage.getItem(environment.localAuthenInfo)
      );
      if (authInfo) {
        this.person.token = authInfo.token;
        this.getData();
      }
    } else {
      this.router.navigate(["/apply"]);
    }
  }

  getData() {
    this.person.currentActiveForm = "3";
    this.getPersonInfo();
    this.countIncart.funcCountCart();
  }
  addMorePerson() {
    this.formData = this.formDataService.resetFormData();
    this.router.navigate(["/application"]);
    this.data.session_id = this.localStorage.getItem("session_id");
    this.data.input_id = "addMorePeople";
    this.data.input_value = "add_more_people";

    // this.rest.updateUserSession(this.data).subscribe((res) => {
    //   console.log("Result Update Session when focus input:", res);
    // });
  }

  calculateTotal() {
    this.countTotal = this.mDataArrayPerson.length;
  }

  backToCart(event: any) {
    this.data.session_id = this.localStorage.getItem("session_id");
    this.data.input_id = event.target.id;
    this.data.input_value = "back_to_cart";
    // this.rest.updateUserSession(this.data).subscribe((res) => {
    //   console.log("Result Update Session when focus input:", res);
    // });
    this.router.navigate(["/cart"]);
  }

  open(content) {
    this.startTimer();
    this.modalService.open(content, { centered: true });
  }
  openPhoto(index: number): void {
    if (isPlatformBrowser(this.platformId)) {
      this._lightbox.open(this._albumsPhoto, index, { centerVertically: true });
    }
  }
  openPassport(index: number): void {
    if (isPlatformBrowser(this.platformId)) {
      this._lightbox.open(this._albumsPassport, index, {
        centerVertically: true,
      });
    }
  }
  openCover(index: number): void {
    if (isPlatformBrowser(this.platformId)) {
      this._lightbox.open(this._albumsCover, index, { centerVertically: true });
    }
  }
  closeImgPreview(): void {
    this._lightbox.close();
  }

  startTimer() {
    this.interval = setInterval(() => {
      this.timeLeft += 20;
      if (this.timeLeft === 100) {
        clearInterval(this.interval);
        this.modalService.dismissAll();
        this.countIncart.funcCountCart();
        this.getPersonInfo();
      }
    }, 1000);
  }

  getPersonInfo() {
    this.rest.getPersonForPayment().subscribe(async (result) => {
      if (result.length > 0) {
        this.mDataArrayPerson = result;
        this.calculateTotal();
        this.invoice_id = result[0].invoice_id;

        let m_total = 0;
        for (let i = 0; i < result.length; i++) {
          m_total += result[i].price;
        }
        this.total = Math.round(m_total * 100) / 100 || 0;
        for (let i = 0; i < result.length; i++) {
          const srcPhoto =
            result[i].version == "2"
              ? await this.getBase64Image(result[i].photo_name, "photo")
              : result[i].photo_name;
          const srcPassport =
            result[i].version == "2"
              ? await this.getBase64Image(result[i].passport_name, "passport")
              : result[i].passport_name;
          const srcCover = result[i].passport_cover_name;
          this.mDataArrayPerson[i].photo_name = srcPhoto;
          this.mDataArrayPerson[i].passport_name = srcPassport;
          const captionPhoto = "Photo ";
          const captionPassport = "Passport ";
          const captionCover = "Cover ";

          const albumPhoto = {
            src: srcPhoto,
            caption: captionPhoto,
          };
          const albumPassport = {
            src: srcPassport,
            caption: captionPassport,
          };
          const albumCover = {
            src: srcCover,
            caption: captionCover,
          };

          this._albumsPhoto.push(albumPhoto);
          this._albumsPassport.push(albumPassport);
          this._albumsCover.push(albumCover);
        }
        this.total = m_total;
      } else {
        this.mDataArrayPerson = result;
        this.isEmptyCart = true;
        this.total = 0;
      }
    });
  }

  async onGetPersonInfo(mDataArrayPerson: Array<any>) {
    this.mDataArrayPerson = mDataArrayPerson;
    if (mDataArrayPerson.length > 0) {
      this.invoice_id = mDataArrayPerson[0].invoice_id;

      let m_total = 0;
      for (let i = 0; i < mDataArrayPerson.length; i++) {
        m_total += mDataArrayPerson[i].price;
      }
      this.total = Math.round(m_total * 100) / 100 || 0;
      for (let i = 0; i < mDataArrayPerson.length; i++) {
        const srcPhoto =
          mDataArrayPerson[i].version == "2"
            ? await this.getBase64Image(mDataArrayPerson[i].photo_name, "photo")
            : mDataArrayPerson[i].photo_name;
        const srcPassport =
          mDataArrayPerson[i].version == "2"
            ? await this.getBase64Image(
                mDataArrayPerson[i].passport_name,
                "passport"
              )
            : mDataArrayPerson[i].passport_name;
        const srcCover = mDataArrayPerson[i].passport_cover_name;
        this.mDataArrayPerson[i].photo_name = srcPhoto;
        this.mDataArrayPerson[i].passport_name = srcPassport;
        const captionPhoto = "Photo ";
        const captionPassport = "Passport ";
        const captionCover = "Cover ";

        this._albumsPhoto.push({
          src: srcPhoto,
          caption: captionPhoto,
        });
        this._albumsPassport.push({
          src: srcPassport,
          caption: captionPassport,
        });
        this._albumsCover.push({
          src: srcCover,
          caption: captionCover,
        });
      }
      this.total = m_total;
    } else {
      this.mDataArrayPerson = [];
      this.isEmptyCart = true;
      this.total = 0;
    }
  }

  async getBase64Image(imageName: string, image: "photo" | "passport") {
    const result = await this.rest
      .getBase64ImageService(imageName, image)
      .toPromise();
    return result[`${image}_name`];
  }
}
