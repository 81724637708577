import { Component, OnInit, Inject } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { RestService } from "src/app/services/rest.service";
import { TranslateService } from "@ngx-translate/core";
import { FormDataService } from "src/app/services/form-data.service";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-verify",
  templateUrl: "./verify.component.html",
  styleUrls: ["./verify.component.css"],
})
export class VerifyComponent implements OnInit {
  constructor(
    private router: Router,
    private rest: RestService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private formDataService: FormDataService
  ) {}

  isWrong = false;
  isTokenExpired = false;
  public errorMsg;

  ngOnInit() {
    this.route.params.subscribe(async (params) => {
      this.verifyToken(params["id"]);
    });
  }

  verifyToken(credential) {
    this.rest.verifyToken(credential).subscribe(
      (result) => {
        if (result.responseStatus === true) {
          localStorage.removeItem(environment.localAuthenInfo);
          localStorage.setItem(
            environment.localAuthenInfo,
            JSON.stringify(result)
          );
          this.router.navigate(["/application"]);
          // this.router.navigate(['/confirm']);
        } else if (
          result.auth === false &&
          result.message === "Failed to authenticate token."
        ) {
          this.isWrong = true;
          this.isTokenExpired = false;
        } else if (result.auth === false && result.message === "TokenExpired") {
          this.isTokenExpired = true;
          this.isWrong = false;
          localStorage.removeItem(environment.localAuthenInfo);
        }
      },
      (error) => {
        this.errorMsg = error;
        this.isWrong = false;
        localStorage.removeItem(environment.localAuthenInfo);
        // console.log(this.errorMsg);
        if (
          this.errorMsg.status === 200 &&
          this.errorMsg.error.message === "Failed to authenticate token."
        ) {
          this.isTokenExpired = true;
        } else {
          // this.router.navigate(['/apply']);
        }
      }
    );
  }
}
