<app-nav></app-nav>
<main class="page page-security-verification">

  <aside class="header-image-half"></aside>
<div class="container p-3 text-dark mt-5">
  <div class="mt-5"></div>
  <div class="card pb-5">
    <div class="card-body">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <div *ngIf="isWrong" class="text-center text-uppercase">
            <br>
            <br>
            <h1 class="text-dark" translate>Something is wrong.</h1>
            <h3 class="text-secondary" translate>Please try again.</h3>
            <br>

          </div>
          <div *ngIf="isTokenExpired" class="text-center">
            <br>
            <br>
            <h1 class="text-dark text-uppercase" translate="link_expired1">The verification link is expired. </h1>
            <br>
            <p class="text-secondary text-uppercase" translate="link_expired2">
              Please click "Apply Now" to get the new verification link for your email.
            </p>
          </div>
          <div *ngIf="isWrong || isTokenExpired" class="text-center">
            <a href="/apply" class="btn btn-primary btn-lg">Apply Now</a>
          </div>

          <div *ngIf="!isWrong && !isTokenExpired" class="progress mt-5">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75"
              aria-valuemin="0" aria-valuemax="100" style="width: 90%"></div>
          </div>
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
  </div>
</div>
</main>
ຶ<br><br>
<app-footer></app-footer>
