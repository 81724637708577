import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { RestService } from "src/app/services/rest.service";
import { ServiceSEOService } from "src/app/services/service-seo.service";
import Swal from "sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-application-enquiry",
  templateUrl: "./application-enquiry.component.html",
  styleUrls: ["./application-enquiry.component.css"],
})
export class ApplicationEnquiryComponent implements OnInit {
  enquiryForm: FormGroup;
  submitted: boolean = false;
  captchaImg: string | ArrayBuffer;
  messageBlock: boolean = false;
  responseMsg: string;
  eMailLink: string;
  yahooUrl: string = "https://mail.yahoo.com/";

  constructor(
    private rest: RestService,
    private seo: ServiceSEOService,
    public spinner: NgxSpinnerService,
    private formBuilder: FormBuilder
  ) {
    this.seo.addMetaTags();
  }

  ngOnInit() {
    this.getImgCaptcha();
    // this.spinner.show();
    this.enquiryForm = this.formBuilder.group({
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,10}$"
          ),
        ],
      ],
      passport_number: [
        "",
        [Validators.required, Validators.pattern("[a-zA-Z0-9]*")],
      ],
      captcha_code: ["", [Validators.required, Validators.pattern("[0-9+-]+")]],
    });
  }

  get nqryForm() {
    return this.enquiryForm.controls;
  }

  applicationEnquiry(form: FormGroup) {
    this.submitted = true;
    if (form.valid) {
      console.log(form.value);
      // TODO: get mail url from backend
      this.eMailLink = form.value.email;

      // TODO: call verify captcha to get token

      // TODO: call application query api with response token
      this.messageBlock = true;
      // this.spinner.show();
      this.responseMsg =
        "Your APPLICATION ID is sent to your email. Pleaser check in your mail box.";

      // TODO: CALL real api to send request

      this.enquiryForm.reset();
      this.enquiryForm.clearValidators();
      this.submitted = false;
    }
  }

  backToForm() {
    this.messageBlock = false;
  }

  getImgCaptcha() {
    this.rest.getCaptchaImage().subscribe(
      (data) => {
        const reader = new FileReader();
        reader.addEventListener(
          "load",
          () => {
            this.captchaImg = reader.result;
          },
          false
        );

        if (data) {
          reader.readAsDataURL(data);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
