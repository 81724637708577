import { Component, OnInit, ViewChild } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-re-pay',
  templateUrl: './re-pay.component.html',
  styleUrls: ['./re-pay.component.css']
})
export class RePayComponent implements OnInit {
  @ViewChild('bcelForm', { static: false }) testFormEl;
  model;
  invoice_id = 0;
  amount: any;
  status: any;
  paid_date: any;
  detail: any;
  constructor(private rest: RestService, private route: ActivatedRoute, private router: Router,) { }

  ngOnInit() {
    this.route.params.subscribe(async params => {
      if ((params['id'])) {
        const inv = params['id'];
        if (inv.length > 10) {
          this.router.navigate(['/404']);
        } else {
          this.checkRePay(params['id']);
        }
      } else {
        this.router.navigate(['/404']);
      }
    });
  }
  checkRePay(invoice_id) {
    const data = { 'payment_code': invoice_id };
    this.rest.checkRePay(data).subscribe(async result => {

      if (result.status === 'success') {
        this.detail = result.repay.status === "Paid" ? `Was paid successful, invoice ${result.repay.invoice_id}` : `eVisa fee from invoice ${result.repay.invoice_id}`
        this.invoice_id = result.repay.invoice_id;
        this.amount = result.repay.amount;
        this.status = result.repay.status;
        this.paid_date = result.repay.paid_date;
      }

    });
  }

  proceedCheckOut() {
    const data = { 'payment_code': this.invoice_id, 'payment_method': 'CBS' };
    this.rest.RePayNow(data).subscribe(async result => {
      this.model = await result;
      setTimeout(_ => this.testFormEl.nativeElement.submit());
      setTimeout(_ => {
      });
    });
  }




}
