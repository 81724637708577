import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { RestService } from "src/app/services/rest.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "app-warning-confirm",
  templateUrl: "./warning-confirm.component.html",
  styleUrls: ["./warning-confirm.component.css"],
})
export class WarningConfirmComponent implements OnInit {
  isCheck = false;
  warning_line1;
  selectedLang;
  constructor(
    private router: Router,
    public app: AppComponent,
    private rest: RestService,
    private modalService: NgbModal,
    private route: ActivatedRoute
  ) {
    this.selectedLang = this.app.SelectedLang;
    localStorage.setItem("confirm", "no");
    this.modalService.dismissAll();

    if (this.app.SelectedLang == "en") {
      this.warning_line1 =
        "Applicant should be informed that all nationalities travelling from China Mainland will not be eligible to apply for Lao eVisa during the outbreak period of Novel coronavirus.";
    } else if (this.app.SelectedLang == "cn") {
      this.warning_line1 =
        "我们在此通知各位来自中国大陆的人员知晓， 在新型冠状病毒爆发期间，你们将无法申请电子签证。如果老挝移民局的警察认为申请人来自中国大陆地区，将禁止该申请者进入老挝。";
    }
  }

  ngOnInit() {
    // this.selectedLang = this.app.SelectedLang;
    // localStorage.setItem('confirm', 'no');
    // this.modalService.dismissAll();
    // if(this.app.SelectedLang == 'en'){
    //   this.warning_line1 = "Applicant should be informed that all nationalities travelling from China Mainland will not be eligible to apply for Lao eVisa during the outbreak period of Novel coronavirus.";
    // } else if(this.app.SelectedLang == 'cn'){
    //   this.warning_line1 = "我们在此通知各位来自中国大陆的人员知晓， 在新型冠状病毒爆发期间，你们将无法申请电子签证。如果老挝移民局的警察认为申请人来自中国大陆地区，将禁止该申请者进入老挝。";
    // }
  }
  onFilterChange(eve: any) {
    this.isCheck = !this.isCheck;
  }
  confirm(conf) {
    localStorage.setItem("confirm", conf);
    if (conf === "yes") {
      this.router.navigate(["/application"]);
    } else {
      this.router.navigate(["/index"]);
    }
  }
}
