import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {
  invokeTermConditionsComponentFunction = new EventEmitter();
  invokeFAQComponentFunction = new EventEmitter();
  invokeInfoComponentFunction = new EventEmitter();
  invokeAboutComponentFunction = new EventEmitter();
  invokeArticleComponentFunction = new EventEmitter();
  subsVar: Subscription;

  constructor() { }

  onTermConditionComponentButtonClick(language: string) {
    this.invokeTermConditionsComponentFunction.emit(language);
  }
  onFAQComponentButtonClick(language: string) {
    this.invokeFAQComponentFunction.emit(language);
  }
  onInfoComponentButtonClick(language: string) {
    this.invokeInfoComponentFunction.emit(language);
  }
  onAboutComponentButtonClick(language: string) {
    this.invokeAboutComponentFunction.emit(language);
  }
  onArticleComponentButtonClick(language: string) {
    this.invokeArticleComponentFunction.emit(language);
  }
}
