<app-nav-partner></app-nav-partner>
<div class="container mt-5">
    <h1 class="text-secondary">VISIT</h1>
    <div class="table-responsive">
        <table class="table table-bordered table-sm">
          <thead>
            <th style="width:50px;">#</th>
            <th>Date</th>
            <th>IP</th>
          </thead>
          <tbody>
           <tr *ngFor="let item of model; let id = index">
              <td>{{id+1}}</td>
              <td>{{ item.access_date | date: 'dd-MM-yyyy H:mm:s'}}</td>
              <td>{{item.ip}}</td>
           </tr>
          </tbody>
        </table>
      </div>
      
</div>