import * as i0 from "@angular/core";
import * as i1 from "./rest.service";
import * as i2 from "@angular/router";
export class AuthGuard {
    constructor(rest, router) {
        this.rest = rest;
        this.router = router;
    }
    canActivate(next, state) {
        if (this.rest.isLoggedIn()) {
            return true;
        }
        else {
            this.router.navigate(['partner-login']);
            // return false;
        }
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.RestService), i0.ɵɵinject(i2.Router)); }, token: AuthGuard, providedIn: "root" });
