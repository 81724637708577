import { Component, OnInit, SecurityContext } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ServiceSEOService } from 'src/app/services/service-seo.service';
import { EventEmitterService } from 'src/app/services/event-emitter.service';
import { async } from '@angular/core/testing';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  model: any;
  title: string;
  constructor(
    private rest: RestService,
    private seo: ServiceSEOService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private eventEmitterService: EventEmitterService,
    private router: Router) {
    this.seo.addMetaTagsAbout();
  }

  ngOnInit() {

    // this.rest.getAbout().subscribe(async rs => {
    //   if (rs.length > 0) {
    //     this.model = await this.sanitizer.bypassSecurityTrustHtml(rs[0].article_detail);
    //     this.title = await rs[0].article_title;
    //   } else {
    //     this.router.navigate(['/404']);
    //   }
    // });


    if (this.eventEmitterService.subsVar === undefined) {
      this.eventEmitterService.subsVar = this.eventEmitterService.
        invokeAboutComponentFunction.subscribe((language: string) => {
          if (this.router.url === '/about') {
            this.getAbout(language);
          }
        });
    }


    const language = localStorage.getItem('selectedLang');
    this.getAbout(language);

  }

  getAbout(language) {
    this.rest.getAbout(language).subscribe(async rs => {
      this.model = await this.sanitizer.bypassSecurityTrustHtml(rs[0].article_detail);
      this.title = await rs[0].article_title;
    });
  }
}
