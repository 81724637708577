import { Component, ViewChild, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { RestService } from "src/app/services/rest.service";
import { NgxSpinnerService } from "ngx-spinner";
import { isNumber } from "util";
import { AppComponent } from "src/app/app.component";
import Swal from "sweetalert2";
import { MatRadioChange } from "@angular/material/radio";

@Component({
  selector: "app-pay-success",
  templateUrl: "./pay-success.component.html",
  styleUrls: ["./pay-success.component.css"],
})
export class PaySuccessComponent implements OnInit {
  invoice_id;
  message;
  status;
  model;
  isEmptyCart = false;
  message_cn: any;
  message_fr: any;
  selected_payment_method;
  selected_payment_active;
  selected_payment_method_text;
  my_uuid = "";
  isCancelled = false;
  @ViewChild("bcelForm", { static: false }) testFormEl;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private rest: RestService,
    private spinner: NgxSpinnerService,
    public app: AppComponent
  ) {}
  ngOnInit() {
    this.route.params.subscribe(async (params) => {
      if (Number(params["id"])) {
        const inv = params["id"];
        if (inv.length > 10) {
          this.router.navigate(["/404"]);
        } else {
          this.checkPayment(params["id"]);
        }
      } else {
        this.router.navigate(["/404"]);
      }
    });

    this.route.queryParams.subscribe((query) => {
      if (query.cancel == true || query.cancel == "true") {
        this.isCancelled = true;
      }
    });
  }

  checkPayment(invoice_id) {
    const data = { invoice_id: invoice_id };
    this.rest.checkPayment(data).subscribe(async (result) => {
      this.my_uuid = await result.id;
      this.status = await result.status;
      this.invoice_id = await result.invoice_id;
      this.message = await result.message;
      this.message_cn = await result.message_cn;
      this.message_fr = await result.message_fr;
    });
  }
}
