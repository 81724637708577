import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-partner-visit',
  templateUrl: './partner-visit.component.html',
  styleUrls: ['./partner-visit.component.css']
})
export class PartnerVisitComponent implements OnInit {

  model;
  constructor(private rest: RestService) { }

  ngOnInit() {
    this.getAllVisit();
  }

  getAllVisit() {
    const auth = JSON.parse(localStorage.getItem('partnerAuthen'));
    if (auth) {
      const partner_id = auth.partner_id;
    this.rest.getAllVisit(partner_id).subscribe(rs => {
      this.model = rs;
    });
  }
  }
}
