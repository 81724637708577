export class Article {
    article_id: number;
    article_title: string;
    article_short_detail: string;
    language_id: number;
    article_detail: string;
    show_index: number;
    created_date: any;
    updated_date: any;
    article_thumbnail: any;
  }
