<div class="container">
  <nav class="navbar navbar-expand-lg navbar-light bg-white rounded">
      <a class="navbar-brand mr-5" href="/partner-dashboard">
        <img src="assets/images/eVisa.png" class="img-fluid" width="50" alt="">
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item active">
            <a class="nav-link" routerLink="/partner-dashboard">Dashboard <span class="sr-only">(current)</span></a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" href="#">Access</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Fill Form</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Paid</a>
          </li> -->
        </ul>
        <div>
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <button class="nav-link btn btn-outline-light" (click)="logOut()">
                <i class="fa fa-sign-out"></i>
                Sign out</button>
            </li>
          </ul>
        </div>
      </div>
    </nav>

</div>
