import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
export class EventEmitterService {
    constructor() {
        this.invokeTermConditionsComponentFunction = new EventEmitter();
        this.invokeFAQComponentFunction = new EventEmitter();
        this.invokeInfoComponentFunction = new EventEmitter();
        this.invokeAboutComponentFunction = new EventEmitter();
        this.invokeArticleComponentFunction = new EventEmitter();
    }
    onTermConditionComponentButtonClick(language) {
        this.invokeTermConditionsComponentFunction.emit(language);
    }
    onFAQComponentButtonClick(language) {
        this.invokeFAQComponentFunction.emit(language);
    }
    onInfoComponentButtonClick(language) {
        this.invokeInfoComponentFunction.emit(language);
    }
    onAboutComponentButtonClick(language) {
        this.invokeAboutComponentFunction.emit(language);
    }
    onArticleComponentButtonClick(language) {
        this.invokeArticleComponentFunction.emit(language);
    }
}
EventEmitterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EventEmitterService_Factory() { return new EventEmitterService(); }, token: EventEmitterService, providedIn: "root" });
