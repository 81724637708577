import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-close-apply',
  templateUrl: './close-apply.component.html',
  styleUrls: ['./close-apply.component.css']
})
export class CloseApplyComponent implements OnInit {

  description = '';
  description_cn = '';
  constructor(    public app: AppComponent,private rest: RestService, private router: Router) { }

  ngOnInit() {
    this.getApplyStatus();
  }

  getApplyStatus(){
    this.rest.getIsCloseApply().subscribe(rs =>{
        this.description = rs.description;
        this.description_cn = rs.description_cn;
       return;
    });
  }
}
