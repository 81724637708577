import { Component, OnInit } from "@angular/core";
import { AppComponent } from "src/app/app.component";
import { RestService } from "src/app/services/rest.service";
import { ServiceSEOService } from "src/app/services/service-seo.service";

@Component({
  selector: "app-calculate-price",
  templateUrl: "./calculate-price.component.html",
  styleUrls: ["./calculate-price.component.css"],
})
export class CalculatePriceComponent implements OnInit {
  model: any;
  title: string;
  mDataArrayCountry: any[];
  total = "";
  citizens: any;
  days: any;
  is_except = false;
  constructor(
    private rest: RestService,
    private seo: ServiceSEOService,
    public app: AppComponent
  ) {
    this.seo.addMetaTagsFee();
  }

  ngOnInit() {
    this.getCountryList();
  }
  getCountryList() {
    this.rest.getCountryPriceCalculator().subscribe((result) => {
      // @ts-ignore
      // this.mDataArrayCountry = result.country
      this.mDataArrayCountry = result;
    });
  }
  onCountryChange(selectedId, event) {
    this.rest.getCalculatePrice(selectedId).subscribe((result) => {
      if (result.status === "success") {
        this.total = result.total;
      } else {
        this.total = "US$ 0";
      }
    });

    if (selectedId !== "") {
      const s: string = this.mDataArrayCountry.find(
        (x: any) => x.country_id == selectedId
      );
      this.citizens = s["citizens"];
      this.days = s["stay_day"];

      const country = this.mDataArrayCountry.find(
        (x) => x.country_id == selectedId
      );
      if (country.is_except == 1) {
        this.is_except = true;
        event.srcElement.blur();
        event.preventDefault();
      } else {
        this.is_except = false;
      }
    }
  }
}
