import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment.prod.cn";
@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent implements OnInit {
  year: number = new Date().getFullYear();
  version: string = environment.version;
  lang: string = environment.defaultLang;
  // lang: string = localStorage.getItem(environment.selectedLang);
  constructor() {}

  ngOnInit() {}
}
