import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { MatRadioChange } from "@angular/material/radio";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LOCAL_STORAGE, WINDOW } from "@ng-toolkit/universal";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { AppComponent } from "src/app/app.component";
import { Person } from "src/app/models/formData";
import { RestService } from "src/app/services/rest.service";
import { ServiceSEOService } from "src/app/services/service-seo.service";
import { environment } from "src/environments/environment";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

@Component({
  selector: "app-new-apply",
  templateUrl: "./new-apply.component.html",
  styleUrls: ["./new-apply.component.css"],
})
export class NewApplyComponent implements OnInit, AfterViewInit {
  @ViewChild("img", { static: false }) img: ElementRef;
  @ViewChild("bgColor", { static: false }) bgColor: ElementRef;
  @ViewChild("secuityHeader", { static: false }) secuityHeader: ElementRef;
  @ViewChild("overLay", { static: false }) overLay: ElementRef;

  @Input("src") imageEn: any = "../../../assets/images/form-preview-en.png";
  @Input("src") imageCn: any = "../../../assets/images/form-preview-cn.png";

  captchaImage: any;
  clientEmail: string = "";
  verifyCode: string = "";
  isImageLoading: boolean;
  isSuccess = false;
  isNotSuccess = false;
  message: string;
  message_pin: string;
  isShow = false;
  isEmpty = false;
  isCodeEmpty = false;
  your_email: string;
  currentToken: any;
  isNotEmail: boolean;
  model_pin: any = {};
  isMail: string;
  isGmail: boolean = false;
  isYahooMail: boolean = false;
  @ViewChild("cop", { static: false }) cop: ElementRef;
  pin_isNotSuccess: boolean;
  maxLength = 1;
  pin1 = "";
  pin2 = "";
  pin3 = "";
  pin4 = "";
  passportType = "";
  isOrdinaryPassport = false;
  person = new Person();
  verifyForm: FormGroup;
  countryOfPassport: string = "";
  citizens = "";
  days = 0;
  VerifyType: number;
  selectedCountry: string;
  @ViewChild("content", { static: true }) private content: any;
  mDataArrayCountry: Array<any> = [];
  isMaintainer: boolean = environment.maintainerWeb;
  selectLanguage: string;
  imageIndex = 0;
  imageViewerConfig = {
    zoomFactor: 0.5,
    containerBackgroundColor: "#000000",
    wheelZoom: true,
  };
  underMaintainance: boolean = false;

  openModal = false;

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(LOCAL_STORAGE) private localStorage: any,
    private app: AppComponent,
    private rest: RestService,
    private router: Router,
    private seo: ServiceSEOService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private renderer: Renderer2,
    public dialog: MatDialog
  ) {
    this.seo.addMetaTags();
  }

  @HostListener("focus", ["$event.target"])
  onFocus(event: any) {
    // this.rest
    //   .updateUserSession({
    //     session_id: this.localStorage.getItem("session_id"),
    //     input_id: event.target.id,
    //     input_value: event.target.value,
    //   })
    //   .subscribe((res) => {
    //     console.log("Result Update Session when focus input:", res);
    //   });
  }

  @HostListener("blur", ["$event.target"])
  onBlur(event: any) {
    // this.rest
    //   .updateUserSession({
    //     session_id: this.localStorage.getItem("session_id"),
    //     input_id: event.target.id,
    //     input_value: event.target.value,
    //   })
    //   .subscribe((res) => {
    //     console.log("Result Update Session when unfocus input:", res);
    //   });
  }

  getCountryList() {
    this.rest.getCountryPriceCalculator().subscribe(async (result) => {
      this.mDataArrayCountry = result;
    });
  }

  ngOnInit() {
    this.rest.getMaintainance().subscribe((res: any) => {
      if (res.status == "success") {
        this.underMaintainance = true;
      }
      console.log(this.underMaintainance);
    });
    this.selectLanguage = this.localStorage.getItem("selectedLang");
    this.getImageFromService();
    this.getCountryList();
    this.verifyForm = this.formBuilder.group({
      client_email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            "[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?"
          ),
        ],
      ],
      verify_code: ["", Validators.required],
      passport_type: ["", Validators.required],
      country_passport: ["", Validators.required],
    });
    console.log(this.selectLanguage);
  }

  ngAfterViewInit(): void {
    this.cop.nativeElement.focus();
  }

  getImageFromService() {
    this.rest.getCaptchaImage().subscribe(
      (image) => {
        const reader = new FileReader();
        reader.addEventListener(
          "load",
          () => {
            this.captchaImage = reader.result;
          },
          false
        );

        if (image) reader.readAsDataURL(image);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onSubmitVerification(form: FormGroup) {
    console.log(form.valid, form.value);
    if (
      this.verifyCode &&
      this.clientEmail &&
      this.passportType == "ordinary" &&
      this.countryOfPassport
    ) {
      const client_email = form.value.client_email || this.clientEmail;
      this.rest
        .verifyCaptcha({
          verify_code: form.value.verify_code || this.verifyCode,
          client_email: `${client_email}`.replace(/(\/|\s)+/g, ""),
          country_of_passport:
            form.value.country_passport || this.countryOfPassport,
        })
        .subscribe((result) => {
          const domain_email = `${client_email}`
            .replace(/(\/|\s)+/g, "")
            .toLowerCase()
            .split("@")[1];
          if (result.responseStatus === false) {
            this.isNotSuccess = true;
            this.message = "Security code is wrong!";
          } else {
            this.model_pin.verify_code = form.value.verify_code;
            this.model_pin.client_email = `${client_email}`.replace(
              /(\/|\s)+/g,
              ""
            );
            this.your_email = `${client_email}`.replace(/(\/|\s)+/g, "");
            this.isMail = domain_email;
            this.isGmail = domain_email.includes("gmail");
            this.isYahooMail =
              domain_email.includes("myyahoo") ||
              domain_email.includes("yahoo") ||
              domain_email.includes("ymail");
            this.localStorage.setItem(
              "email",
              `${client_email}`.replace(/(\/|\s)+/g, "")
            );
            this.isSuccess = true;
            this.VerifyType = 2;
          }
        });
    } else {
      this.isNotSuccess = true;
      return;
    }
  }

  verifyPin(form: NgForm) {
    this.verifyPinNow();
  }
  verifyPinNow() {
    const data = {
      pin1: this.pin1,
      pin2: this.pin2,
      pin3: this.pin3,
      pin4: this.pin4,
      client_email: `${this.model_pin.client_email}`.replace(/(\/|\s)+/g, ""),
      verify_code: this.model_pin.verify_code,
    };
    this.spinner.show();
    this.rest.verifyPin(data).subscribe(async (result) => {
      if (result.responseStatus === false) {
        this.spinner.hide();
        this.pin_isNotSuccess = true;
        this.message_pin = "PIN is wrong!";
      } else {
        this.spinner.hide();
        this.localStorage.removeItem(environment.localAuthenInfo);
        this.localStorage.setItem(
          environment.localAuthenInfo,
          JSON.stringify(result)
        );

        // this.rest
        //   .updateUserSession({
        //     email: this.your_email,
        //     session_id: this.localStorage.getItem("session_id"),
        //   })
        //   .subscribe((res) => {
        //     console.log("Result Update Session when focus input:", res);
        //   });
        this.router.navigate(["/application"]);
      }
    });
  }

  onKeyUp($event, maxLength): void {
    const element = $event.srcElement.nextElementSibling;
    const inputData = $event.srcElement.value.trim();
    if (
      this.pin1 !== "" &&
      this.pin2 !== "" &&
      this.pin3 !== "" &&
      this.pin4 !== ""
    ) {
      this.verifyPinNow();
    }
    if (element == null) {
      // check if its null
      return;
    } else if (
      inputData.length >= maxLength &&
      $event.code !== "Tab" &&
      $event.code !== "ShiftLeft" &&
      $event.code !== "ShiftRight"
    ) {
      this.pin_isNotSuccess = false;
      element.focus();
    }
  }

  onActivate(event) {
    this.window.scroll(0, 0);
  }

  openInbox() {
    const domain_email = this.clientEmail.toLowerCase().split("@")[1];
    let url = "";
    if (domain_email.includes("gmail")) {
      url = "https://mail.google.com/mail/u/0/#inbox";
    } else if (
      domain_email.includes("myyahoo") ||
      domain_email.includes("yahoo") ||
      domain_email.includes("ymail")
    ) {
      url = `https://mail.yahoo.${
        domain_email.split(".").slice(1).join(".") || "com"
      }`;
    } else if (
      domain_email.includes("outlook") ||
      domain_email.includes("hotmail")
    ) {
      url = "https://outlook.live.com/mail/0/";
    } else if (domain_email.split(".")[0].includes("qq")) {
      url = `https://mail.qq.com/`;
    }
    window.open(url, "_blank");
  }

  checkPassportType(event: Event) {
    if (this.passportType == "ordinary") {
      this.isOrdinaryPassport = true;
    } else {
      this.isOrdinaryPassport = false;
    }
    if (!environment.production) {
      console.log("Select passport:", this.passportType);
      console.log(event.target);
    }
  }

  onCountryOfPassportChange(selectedId: any, event: any) {
    if (selectedId !== "") {
      if (this.selectedCountry) {
        this.selectedCountry = selectedId;
      }
      const country = this.mDataArrayCountry.find(
        (x) => x.country_id == selectedId
      );
      this.citizens = country.country_name;
      this.days = country.stay_day;
      if (country.is_except === 1) {
        event.srcElement.blur();
        event.preventDefault();
        this.openLg(this.content);
      }
      if (country.request_attach === 1) {
        this.person.is_request_doc = true;
      } else if (country.request_attach == 0) {
        this.person.is_request_doc = false;
      }
    } else {
      this.person.is_request_doc = false;
    }
  }

  openLg(content) {
    this.modalService.open(content, { size: "lg", centered: true });
  }

  clickOpenHelp() {
    this.selectLanguage = this.localStorage.getItem("selectedLang");
    this.overLay.nativeElement.style.display = "block";
    this.openModal = true;
  }

  closeOpenHelp() {
    this.overLay.nativeElement.style.display = "none";
    this.openModal = false;
  }
}
