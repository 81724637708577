import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { RestService } from './rest.service';
import { async } from '@angular/core/testing';

@Injectable({
  providedIn: 'root'
})
export class CheckStatusApplyGuard implements CanActivate {
  status = true;
  constructor(private rest: RestService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    this.rest.getIsCloseApply().subscribe(async rs => {
      await localStorage.setItem('status', rs.status_can_apply);
    });


    return this.status;
  }
}
