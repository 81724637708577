import { Component, OnInit, SecurityContext } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Article } from 'src/app/models/Article';
import { ServiceSEOService } from 'src/app/services/service-seo.service';
import { EventEmitterService } from 'src/app/services/event-emitter.service';

@Component({
  selector: 'app-article-viewer',
  templateUrl: './article-viewer.component.html',
  styleUrls: ['./article-viewer.component.css']
})
export class ArticleViewerComponent implements OnInit {
  title;
  model = new Article;
  constructor(
    private seo: ServiceSEOService,
    private eventEmitterService: EventEmitterService,
    private activatedRoute:ActivatedRoute,
    private rest: RestService, private route: ActivatedRoute, private router: Router) {
    this.seo.addMetaTags();
  }
  ngOnInit() {
    this.route.params.subscribe(async (result) => {
      const title = result['title'];
      //  this.rest.getArticleDetail(title).subscribe(rs => {
      //   if (rs.length > 0) {
      //     this.model = rs[0];
      //   } else {
      //     this.router.navigate(['/404']);
      //   }
      //  });


      
      if (this.eventEmitterService.subsVar === undefined) {
        this.eventEmitterService.subsVar = this.eventEmitterService.
          invokeArticleComponentFunction.subscribe((language: string) => {
            if (this.activatedRoute.snapshot.url[0].path === 'article') {
              this.rest.getArticleDetail(title, language).subscribe(rs => {
                if (rs.length > 0) {
                  this.model = rs[0];
                } else {
                  this.router.navigate(['/404']);
                }
              });
            }
          });
      }

      const language = localStorage.getItem('selectedLang');
      this.rest.getArticleDetail(title, language).subscribe(rs => {
        if (rs.length > 0) {
          this.model = rs[0];
        } else {
          this.router.navigate(['/404']);
        }
      });

    });


  }
}
