import { CommonModule } from "@angular/common";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
// import * as Sentry from '@sentry/angular';
import { Router } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { MatRadioModule } from "@angular/material/radio";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Route, RouterModule } from "@angular/router";
import { NgbModule, NgbTooltipConfig } from "@ng-bootstrap/ng-bootstrap";
import { NgtUniversalModule } from "@ng-toolkit/universal";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ChartModule } from "angular2-chartjs";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { CookieService } from "ngx-cookie-service";
import { LightboxModule } from "ngx-lightbox";
import { NgxMaskModule } from "ngx-mask";
import { NgxSpinnerModule } from "ngx-spinner";
import { AppComponent } from "./app.component";
import { AboutComponent } from "./components/about/about.component";
import { ApplicationReviewComponent } from "./components/application-review/application-review.component";
import { ApplicationComponent } from "./components/application/application.component";
import { ApplyComponent } from "./components/apply/apply.component";
import { ArticleViewerComponent } from "./components/article-viewer/article-viewer.component";
import { CalculatePriceComponent } from "./components/calculate-price/calculate-price.component";
import { CartComponent } from "./components/cart/cart.component";
import { CheckArrivalComponent } from "./components/check-arrival/check-arrival.component";
import { CheckStatusComponent } from "./components/check-status/check-status.component";
import { CloseApplyComponent } from "./components/close-apply/close-apply.component";
import { ContactComponent } from "./components/contact/contact.component";
import { CountdownComponent } from "./components/countdown/countdown.component";
import { EditApplicationComponent } from "./components/edit-application/edit-application.component";
import { FaqComponent } from "./components/faq/faq.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { ImageCropperModule } from "./components/image-cropper/image-cropper.module";
import { IndexComponent } from "./components/index/index.component";
import { InfoComponent } from "./components/info/info.component";
import { LoginComponent } from "./components/login/login.component";
import { NavPartnerComponent } from "./components/nav-partner/nav-partner.component";
import { NavComponent } from "./components/nav/nav.component";
import { NotfoundComponent } from "./components/notfound/notfound.component";
import { PartnerDashboardComponent } from "./components/partner-dashboard/partner-dashboard.component";
import { PartnerLoginComponent } from "./components/partner-login/partner-login.component";
import { PartnerPaidComponent } from "./components/partner-paid/partner-paid.component";
import { PartnerVisitComponent } from "./components/partner-visit/partner-visit.component";
import { PaySuccessComponent } from "./components/pay-success/pay-success.component";
import { PaymentReviewComponent } from "./components/payment-review/payment-review.component";
import { PopUpComponent } from "./components/pop-up/pop-up.component";
import { RePayComponent } from "./components/re-pay/re-pay.component";
import { TermsConditionsComponent } from "./components/terms-conditions/terms-conditions.component";
import { VerifyComponent } from "./components/verify/verify.component";
import { WarningConfirmComponent } from "./components/warning-confirm/warning-confirm.component";
import { ApplicationEnquiryComponent } from "./components/application-enquiry/application-enquiry.component";
import { AuthGuard } from "./services/auth.guard";
import { AuthInterceptor } from "./services/auth.interceptor";
import { CheckStatusApplyGuard } from "./services/check-status-apply.guard";
import { EventEmitterService } from "./services/event-emitter.service";
import { MaskDateDirective } from "./services/mask-date.directive";
import { OnlyNumberDirective } from "./services/only-number.directive";
import { SafeHtmlPipe } from "./services/safe-html-pipe";
import { NgxImageCompressService } from "ngx-image-compress";
import { NewApplyComponent } from "./components/new-apply/new-apply.component";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { PaynowComponent } from "./components/paynow/paynow.component";
import { RetrieveApplicationComponent } from "./components/retrieve-application/retrieve-application.component";
import { ImageViewerModule } from "ngx-image-viewer";
import { MatIconModule } from "@angular/material/icon";

export function translateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
const appRoutes: Route[] = [
  { path: "", redirectTo: "/index", pathMatch: "full" },
  { path: "index", component: IndexComponent },
  { path: "ref/:partner_id", component: IndexComponent },
  { path: "faq", component: FaqComponent },
  { path: "info", component: InfoComponent },
  { path: "about", component: AboutComponent },
  { path: "contact", component: ContactComponent },
  {
    path: "apply",
    component: NewApplyComponent,
    canActivate: [CheckStatusApplyGuard],
  },
  { path: "verify/ssid/:id", component: VerifyComponent },
  {
    path: "application",
    component: ApplicationComponent,
    canActivate: [CheckStatusApplyGuard],
  },
  { path: "edit-application/:id", component: EditApplicationComponent },
  { path: "paysuccess/:id", component: PaySuccessComponent },
  { path: "checkstatus", component: CheckStatusComponent },
  { path: "retrieveapplication", component: RetrieveApplicationComponent },
  { path: "review", component: ApplicationReviewComponent },
  { path: "continue-review", component: PaymentReviewComponent },
  { path: "application-enquiry", component: ApplicationEnquiryComponent },
  { path: "cart", component: CartComponent },
  { path: "term", component: TermsConditionsComponent },
  { path: "404", component: NotfoundComponent },
  { path: "article/:title", component: ArticleViewerComponent },
  { path: "partner-login", component: PartnerLoginComponent },
  {
    path: "partner-dashboard",
    component: PartnerDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "partner-visit",
    component: PartnerVisitComponent,
    canActivate: [AuthGuard],
  },
  { path: "fee-calculator", component: CalculatePriceComponent },
  { path: "repay/:id", component: RePayComponent },
  { path: "confirm", component: WarningConfirmComponent },
  { path: "notice", component: CloseApplyComponent },
  { path: "login", component: LoginComponent },
  { path: "**", redirectTo: "/404" },
];

@NgModule({
  entryComponents: [PopUpComponent, CheckArrivalComponent],
  declarations: [
    CountdownComponent,
    AppComponent,
    IndexComponent,
    NavComponent,
    FooterComponent,
    HeaderComponent,
    FaqComponent,
    InfoComponent,
    ContactComponent,
    ApplyComponent,
    ApplicationComponent,
    MaskDateDirective,
    OnlyNumberDirective,
    ApplicationReviewComponent,
    CartComponent,
    SafeHtmlPipe,
    VerifyComponent,
    CheckStatusComponent,
    RetrieveApplicationComponent,
    EditApplicationComponent,
    TermsConditionsComponent,
    NotfoundComponent,
    PaySuccessComponent,
    PartnerDashboardComponent,
    PartnerLoginComponent,
    PartnerPaidComponent,
    PartnerVisitComponent,
    NavPartnerComponent,
    AboutComponent,
    ArticleViewerComponent,
    CalculatePriceComponent,
    RePayComponent,
    WarningConfirmComponent,
    CloseApplyComponent,
    LoginComponent,
    PopUpComponent,
    CheckArrivalComponent,
    PaymentReviewComponent,
    ApplicationEnquiryComponent,
    NewApplyComponent,
    PaynowComponent,
  ],
  imports: [
    NgbModule.forRoot(),
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    BrowserAnimationsModule,
    ImageCropperModule,
    HttpClientModule,
    FormsModule,
    ChartModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: "never" }),
    NgxSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    RouterModule.forRoot(appRoutes),
    NgxMaskModule.forRoot(),
    TooltipModule.forRoot(),
    CommonModule,
    MatRadioModule,
    MatDialogModule,
    NgtUniversalModule,
    LightboxModule,
    SlickCarouselModule,
    ImageViewerModule,
    MatIconModule,
  ],
  // tslint:disable-next-line:max-line-length
  providers: [
    EventEmitterService,
    CookieService,
    NgbTooltipConfig,
    NgxImageCompressService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: true,
    //   }),
    // },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => {},
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
