import { Component, OnInit, SecurityContext } from "@angular/core";
import { RestService } from "src/app/services/rest.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { ServiceSEOService } from "src/app/services/service-seo.service";
import { EventEmitterService } from "src/app/services/event-emitter.service";
import { MatDialog } from "@angular/material/dialog";
import { PopUpComponent } from "../pop-up/pop-up.component";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-info",
  templateUrl: "./info.component.html",
  styleUrls: ["./info.component.css"],
})
export class InfoComponent implements OnInit {
  model: any;

  constructor(
    private seo: ServiceSEOService,
    private rest: RestService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private router: Router,
    private eventEmitterService: EventEmitterService,
    public dialog: MatDialog
  ) {
    this.seo.addMetaTags();
  }

  ngOnInit() {
    if (this.eventEmitterService.subsVar === undefined) {
      this.eventEmitterService.subsVar =
        this.eventEmitterService.invokeInfoComponentFunction.subscribe(
          (language: string) => {
            if (this.router.url === "/info") {
              this.getInfo(language);
            }
          }
        );
    }

    const language = localStorage.getItem(environment.selectedLang);
    this.getInfo(language);
    // this.route.params.subscribe( async (result) => {
    //   const title = result['title'];
    // });
  }

  checkDuplicate() {
    const data = {
      countryOfPassport: 184,
      passportNumber: "519790550",
    };

    this.rest.CheckDuplicate(data).subscribe((result) => {
      console.log("Check Duplicate: ", result);

      if (result.status == "success") {
        this.router.navigate(["/review"]);
      } else {
        this.dialog.open(PopUpComponent, {
          data: result.data,
          backdropClass: "backdropBackground",
        });
      }
    });
  }

  getInfo(lang) {
    this.rest.getInfo(lang).subscribe(async (rs) => {
      this.model = await this.sanitizer.bypassSecurityTrustHtml(
        rs[0].article_detail
      );

    });
  }
}
