import * as moment from 'moment';
import * as i0 from "@angular/core";
export class CookiesService {
    constructor() { }
    setCookie(cname, cvalue, exhours) {
        const d = moment().add(exhours, 'hours').format('ddd, DD MMM YYYY HH:mm:ss[Z]');
        const expires = 'expires=' + d; // 'Thu, 18 Dec 2019 12:00:00 UTC';
        return document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    }
    getCookie(cname) {
        const name = cname + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }
}
CookiesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CookiesService_Factory() { return new CookiesService(); }, token: CookiesService, providedIn: "root" });
