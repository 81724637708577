import { Location, PlatformLocation, isPlatformBrowser } from "@angular/common";
import {
  Component,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { LOCAL_STORAGE } from "@ng-toolkit/universal";
import { TranslateService } from "@ngx-translate/core";
import { Lightbox } from "ngx-lightbox";
import { AppComponent } from "src/app/app.component";
import { FormData, Person } from "src/app/models/formData";
import { CountCartService } from "src/app/services/count-cart.service";
import { FormDataService } from "src/app/services/form-data.service";
import { RestService } from "src/app/services/rest.service";
import { ServiceSEOService } from "src/app/services/service-seo.service";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";
@Component({
  selector: "app-cart",
  templateUrl: "./cart.component.html",
  styleUrls: ["./cart.component.css"],
  providers: [NgbModalConfig, NgbModal],
})
export class CartComponent implements OnInit {
  node_static_url = environment.node_static_url;
  person: Person;
  mDataArrayPerson: any[];
  invoice_id = 0;
  total = 0;
  countTotal = 0;
  isEmptyCart = false;
  timeLeft = 0;
  interval: any; //  NodeJS.Time
  model: any;
  isCheckOut = false;
  proofileCol: boolean = false;

  @Input() formData: FormData;
  @ViewChild("bcelForm", { static: false }) testFormEl;
  mPaymentMethod: any[];
  selected_payment_method: string;
  selected_payment_active: string;
  selected_payment_method_text: string;
  _albumsPhoto = [];
  _albumsPassport = [];
  _albumsCover = [];
  _albumProoFile = [];

  data = {
    session_id: "",
    input_id: "",
    input_value: "",
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private app: AppComponent,
    private router: Router,

    private route: ActivatedRoute,
    private rest: RestService,
    private formDataService: FormDataService,
    private _location: Location,
    private location: PlatformLocation,
    private sanitizer: DomSanitizer,
    private countIncart: CountCartService,
    private seo: ServiceSEOService,
    private _lightbox: Lightbox,
    private translateService: TranslateService,
    config: NgbModalConfig,
    private modalService: NgbModal,
    @Inject(LOCAL_STORAGE) private localStorage: any
  ) {
    this.seo.addMetaTags();
    config.backdrop = "static";
    config.keyboard = false;
  }

  ngOnInit() {
    this.person = this.formDataService.getFormData();
    const localAuth = localStorage.getItem(environment.localAuthenInfo);
    if (localAuth) {
      const authInfo = JSON.parse(localAuth);
      if (authInfo) {
        this.person.token = authInfo.token;
        this.getData();
        this.selected_payment_method = "";
        this.selected_payment_method_text = "";
        this.selected_payment_active = "";
      }
    } else {
      this.router.navigate(["/apply"]);
    }
  }
  getData() {
    this.person.currentActiveForm = "3";
    this.countIncart.funcCountCart();
    // this.getPaymentMethods();
    this.getPersonInfo();
  }
  addMorePerson() {
    this.formData = this.formDataService.resetFormData();
    this.router.navigate(["/application"]);
    this.data.session_id = this.localStorage.getItem("session_id");
    this.data.input_id = "addMorePeople";
    this.data.input_value = "add_more_people";

    // this.rest.updateUserSession(this.data).subscribe((res) => {
    //   console.log("Result Update Session when focus input:", res);
    // });
  }

  // TODO: Use this instead of getPersonInfo
  // async getCartItems() {
  //   const cartItems = await this.rest.getCartItemsService();
  //   if (cartItems.length > 0) {
  //     this.mDataArrayPerson = cartItems;
  //     this.calculateTotal();
  //     this.invoice_id = cartItems[0].invoice_id;

  //     let totalPrice = 0;

  //     this.proofileCol = cartItems.some((item) => item.race == 304);
  //     for (const item of cartItems) {
  //       const srcPhoto = `${this.node_static_url}/${item.photo_name}`;
  //       const srcPassport = `${this.node_static_url}/${item.passport_name}`;
  //       const srcProofFile = `${this.node_static_url}/${item.proof_of_laotian}`;
  //       const srcCover = item.passport_cover_name;
  //       const captionPhoto = "Photo ";
  //       const captionPassport = "Passport ";
  //       const captionCover = "Cover ";
  //       const captionProofFile = "Proof of laotian ";

  //       const albumPhoto = {
  //         src: srcPhoto,
  //         caption: captionPhoto,
  //       };
  //       const albumPassport = {
  //         src: srcPassport,
  //         caption: captionPassport,
  //       };
  //       const albumProoFile = {
  //         src: srcProofFile,
  //         caption: captionProofFile,
  //       };
  //       const albumCover = {
  //         src: srcCover,
  //         caption: captionCover,
  //       };

  //       this._albumsPhoto.push(albumPhoto);
  //       this._albumsPassport.push(albumPassport);
  //       this._albumsCover.push(albumCover);
  //       this._albumProoFile.push(albumProoFile);
  //       totalPrice += Number(item.price);
  //       this.total = totalPrice;
  //     }
  //   } else {
  //     this.mDataArrayPerson = cartItems;
  //     this.isEmptyCart = true;
  //     this.total = 0;
  //   }
  // }

  getPaymentMethods() {
    this.rest.getPaymentMethods().subscribe((result) => {
      this.mPaymentMethod = result;
    });
  }
  calculateTotal() {
    this.countTotal = this.mDataArrayPerson.length;
  }
  testPay() {
    const payment = {
      invoice_id: 3155,
      payment_method: "cbs",
      pay_from: null,
    };
    console.log({ payment });
  }
  // proceedCheckOut(e: any) {
  //   if (
  //     this.selected_payment_active === "no" ||
  //     this.selected_payment_method === ""
  //   ) {
  //     return;
  //   }
  //   Swal.fire({
  //     title: "Payment Confirmation",
  //     text:
  //       "You sure to make the payment via  " +
  //       this.selected_payment_method_text +
  //       " ?",
  //     type: "question",
  //     showCancelButton: true,
  //     confirmButtonColor: "#d33",
  //     cancelButtonColor: "#3085d6",
  //     cancelButtonText: "Cancel",
  //     confirmButtonText: "Pay",
  //   }).then((result) => {
  //     if (result.value) {
  //       this.data.session_id = this.localStorage.getItem("session_id");
  //       this.data.input_id = e.target.id;
  //       this.data.input_value = "to_payment_review";
  //       this.rest.updateUserSession(this.data).subscribe((res) => {
  //         console.log("Result Update Session when focus input:", res);
  //       });
  //       const data = {
  //         invoice_id: this.invoice_id,
  //         payment_method: this.selected_payment_method,
  //       };
  //       this.rest.payNow(data).subscribe(async (result) => {
  //         this.model = await result;
  //         setTimeout((_) => this.testFormEl.nativeElement.submit());
  //         setTimeout((_) => {
  //           this.getData();
  //         });
  //       });
  //     }
  //   });
  // }

  proceedContinue(e: any) {
    this.data.session_id = this.localStorage.getItem("session_id");
    this.data.input_id = e.target.id;
    this.data.input_value = "to_payment_review";

    // this.rest.updateUserSession(this.data).subscribe((res) => {
    //   console.log("Result Update Session when focus input:", res);
    // });

    this.router.navigate(["/continue-review"]);
  }

  deletePerson(index: number, application_id, first_name) {
    this.data.session_id = this.localStorage.getItem("session_id");
    this.data.input_id = "deletePerson";
    this.data.input_value = "delete_person";

    // this.rest.updateUserSession(this.data).subscribe((res) => {
    //   console.log("Result Update Session when focus input:", res);
    // });

    Swal.fire({
      title: "Are you sure?",
      text: `Are you sure to remove ${first_name} ?`,
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      cancelButtonText: "Cancel",
      confirmButtonText: "Remove",
    }).then((result) => {
      if (result.value) {
        this.rest.deletePerson(application_id).subscribe((rs) => {
          if (rs.status == "error") {
            this.isCheckOut = true;
            // this.getCartItems();
            this.getPersonInfo();
            this.countIncart.funcCountCart();
            return Swal.fire({
              type: "error",
              title: `${rs.message}`,
              showConfirmButton: true,
            });
          } else {
            this.calculateTotal();
            this._albumsPhoto.splice(index, 1);
            this._albumsPassport.splice(index, 1);
            if (rs.status == "success") {
              // this.getCartItems();
              this.getPersonInfo();
              this.countIncart.funcCountCart();
            }
          }
        });
      }
    });
  }

  open(content) {
    this.startTimer();
    this.modalService.open(content, { centered: true });
  }
  openPhoto(index: number): void {
    if (isPlatformBrowser(this.platformId)) {
      this._lightbox.open(this._albumsPhoto, index, { centerVertically: true });
    }
  }
  openPassport(index: number): void {
    if (isPlatformBrowser(this.platformId)) {
      this._lightbox.open(this._albumsPassport, index, {
        centerVertically: true,
      });
    }
  }
  openProoFile(idx: number): void {
    console.log(idx);
    if (isPlatformBrowser(this.platformId)) {
      this._lightbox.open(this._albumProoFile, idx, {
        centerVertically: true,
      });
    }
  }
  openCover(index: number): void {
    if (isPlatformBrowser(this.platformId)) {
      this._lightbox.open(this._albumsCover, index, { centerVertically: true });
    }
  }
  closeImgPreview(): void {
    this._lightbox.close();
  }

  startTimer() {
    this.interval = setInterval(() => {
      this.timeLeft += 20;
      if (this.timeLeft === 100) {
        clearInterval(this.interval);
        this.modalService.dismissAll();
        // this.getCartItems();
        this.getPersonInfo();
        this.countIncart.funcCountCart();
      }
    }, 1000);
  }

  // TODO: use getCartItems instead for next version
  getPersonInfo() {
    this.rest.getPersonForPayment().subscribe(async (result) => {
      if (result.length > 0) {
        this.mDataArrayPerson = result;
        this.calculateTotal();
        this.invoice_id = result[0].invoice_id;

        let m_total = 0;
        for (let i = 0; i < result.length; i++) {
          m_total += result[i].price;
        }
        this.total = Math.round(m_total * 100) / 100 || 0;

        for (let i = 0; i < result.length; i++) {
          const srcPhoto =
            result[i].version == "2"
              ? await this.getBase64Image(result[i].photo_name, "photo")
              : result[i].photo_name;
          const srcPassport =
            result[i].version == "2"
              ? await this.getBase64Image(result[i].passport_name, "passport")
              : result[i].passport_name;
          const srcCover = result[i].passport_cover_name;
          this.mDataArrayPerson[i].photo_name = srcPhoto;
          this.mDataArrayPerson[i].passport_name = srcPassport;
          const captionPhoto = "Photo ";
          const captionPassport = "Passport ";
          const captionCover = "Cover ";

          const albumPhoto = {
            src: srcPhoto,
            caption: captionPhoto,
          };
          const albumPassport = {
            src: srcPassport,
            caption: captionPassport,
          };
          const albumCover = {
            src: srcCover,
            caption: captionCover,
          };

          this._albumsPhoto.push(albumPhoto);
          this._albumsPassport.push(albumPassport);
          this._albumsCover.push(albumCover);
        }
      } else {
        this.mDataArrayPerson = result;
        this.isEmptyCart = true;
        this.total = 0;
      }
    });
  }

  async getBase64Image(imageName: string, image: "photo" | "passport") {
    const result = await this.rest
      .getBase64ImageService(imageName, image)
      .toPromise();
    return result[`${image}_name`];
  }
}
