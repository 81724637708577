import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { LOCAL_STORAGE } from "@ng-toolkit/universal";
import { Observable } from "rxjs";
import "rxjs/add/observable/throw";
import "rxjs/add/operator/catch";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class RestService {
  private baseUrl = environment.node_static_url;
  private paymentUrl = `${environment.node_payment_url}/v1`;
  private apiUrl = `${this.baseUrl}/v1`;
  private apiUrl2 = `${this.baseUrl}/v2`;

  private headers = new HttpHeaders({ "Content-Type": "application/json" });
  private uploadPhotoImageUrl = `${this.apiUrl}/upload_photo_file`;
  private uploadPassportImageUrl = `${this.apiUrl}/upload_passport_file`;
  private uploadPassportCoverImageUrl = `${this.apiUrl}/upload_passport_cover_file`;
  private uploadHoteldocUrl = `${this.apiUrl}/upload_hotel_file`;
  private ocrMRZUrl = `http://10.2.100.131:30107/evisa-ocr-api/v1/mrz`;

  constructor(
    @Inject(LOCAL_STORAGE) private localStorage: any,
    private http: HttpClient
  ) {}
  // get maintenance url
  getMaintenance(): Observable<any> {
    const getCaptchaUrl = `${this.apiUrl}/maintenance`;
    return this.http
      .get<{ maintenance: boolean }>(getCaptchaUrl)
      .catch(this.handleError);
  }
  // get image to page apply
  getCaptchaImage(): Observable<Blob> {
    const getCaptchaUrl = `${this.apiUrl}/secure/captchasrc`;
    return this.http
      .get(getCaptchaUrl, { responseType: "blob" })
      .catch(this.handleError);
  }
  // get image to page contact
  getCaptchaImageContact(): Observable<Blob> {
    const getCaptchaUrl = `${this.apiUrl}/contact/captchasrc`;
    return this.http
      .get(getCaptchaUrl, { responseType: "blob" })
      .catch(this.handleError);
  }

  // get image to page contact
  getCaptchaImageReApply(): Observable<Blob> {
    const getCaptchaUrl = `${this.apiUrl}/re_apply/captchasrc`;
    return this.http
      .get(getCaptchaUrl, { responseType: "blob" })
      .catch(this.handleError);
  }
  // get image to page contact
  getCaptchaImageCheckStatus(): Observable<Blob> {
    const getCaptchaUrl = `${this.apiUrl}/check_status/captchasrc`;
    return this.http
      .get(getCaptchaUrl, { responseType: "blob" })
      .catch(this.handleError);
  }
  // get token to page apply
  getToken() {
    const getGenerateTokenUrl = `${this.apiUrl}/secure/generateToken`;
    return this.http.get(getGenerateTokenUrl);
  }
  // verify captcha in page apply
  verifyCaptcha(credential): Observable<any> {
    const verifyUrl = `${this.apiUrl}/secure/captchaVerify`;
    return this.http.post<any>(verifyUrl, credential);
  }
  // verify captcha in page apply
  verifyPin(credential): Observable<any> {
    const verifyUrl = `${this.apiUrl}/secure/verify_pin`;
    return this.http.post<any>(verifyUrl, credential);
  }
  // verify captcha in page apply
  verifyCaptchaContact(credential): Observable<any> {
    const verifyUrl = `${this.apiUrl}/contact/captchaVerify`;
    return this.http.post<any>(verifyUrl, credential);
  }

  verifyTokenService(credential): Observable<any> {
    const verifyUrl = `${this.apiUrl}/contact/captchaVerify`;
    return this.http.post<any>(verifyUrl, credential);
  }

  verifyToken(credential): Observable<any> {
    const verifyUrl = `${this.apiUrl}/secure/verify_email`;
    return this.http
      .post<any>(verifyUrl, { token: credential })
      .catch(this.handleError);
  }

  // verify captcha in page apply
  verifyCaptchaReApply(credential): Observable<any> {
    const verifyUrl = `${this.apiUrl}/re_apply/captchaVerify`;
    return this.http.post<any>(verifyUrl, credential);
  }
  // verify captcha in page apply
  verifyCaptchaCheckStatus(credential): Observable<any> {
    const verifyUrl = `${this.apiUrl}/check_status/captchaVerify`;
    return this.http.post<any>(verifyUrl, credential);
  }
  verifyRetrieveApplication(credential): Observable<any> {
    const verifyUrl = `${this.apiUrl}/retrieve_application/captchaVerify`;
    return this.http.post<any>(verifyUrl, credential);
  }
  handleError(error: HttpErrorResponse) {
    return Observable.throw(
      "My handle err: " + error.message || "Server Error"
    );
  }

  // Component data
  getCountry() {
    const getContryUrl = `${this.apiUrl}/component/getCountry`;
    return this.http.get<any>(getContryUrl);
  }
  getCountryPriceCalculator() {
    const getContryUrl = `${this.apiUrl}/component/getCountryPriceCalculator`;
    return this.http.get<any[]>(getContryUrl);
  }
  getCountryBirth() {
    const getContryUrl = `${this.apiUrl}/component/getCountryBirth`;
    return this.http.get<any>(getContryUrl);
  }

  getFAQ(language): Observable<any> {
    const url = `${this.apiUrl}/component/getFAQ/${language}`;
    return this.http.get<any>(url).catch(this.handleError);
  }

  getVisaType() {
    const getVisaTypeUrl = `${this.apiUrl}/component/getVisaType`;
    return this.http.get<any[]>(getVisaTypeUrl);
  }
  getPortEntry() {
    const getPortEntryUrl = `${this.apiUrl}/component/getPortEntry`;

    return this.http.get<any[]>(getPortEntryUrl);
  }
  getPurpose() {
    const url = `${this.apiUrl}/component/getPurpose`;
    return this.http.get<any[]>(url);
  }
  getCallingCode() {
    const getCallingCodeUrl = `${this.apiUrl}/component/getCallingCode`;
    return this.http.get<any[]>(getCallingCodeUrl);
  }

  uploadPhotoFile(data) {
    return this.http.post<any>(this.uploadPhotoImageUrl, data);
  }
  uploadPassportFile(data) {
    return this.http.post<any>(this.uploadPassportImageUrl, data);
  }
  uploadPassportCoverFile(data) {
    return this.http.post<any>(this.uploadPassportCoverImageUrl, data);
  }

  submitApplicationForm(credential): Observable<any> {
    const postUrl = `${this.apiUrl}/core/submit_form_application`;
    return this.http.post<any[]>(postUrl, credential).catch(this.handleError);
  }
  getPersonForPayment() {
    const getPersoneUrl = `${this.apiUrl2}/core/get_person?version=2`;
    return this.http.get<any[]>(getPersoneUrl);
  }

  getCountCart() {
    const url = `${this.apiUrl}/core/count_cart/`;
    return this.http.get<any>(url);
  }

  deletePerson(credential): Observable<any> {
    const data = { application_id: credential };
    const postUrl = `${this.apiUrl}/core/delete_person`;
    return this.http.post<any[]>(postUrl, data);
  }
  checkExistData(token: string) {
    const data = { token: token };
    const getPersoneUrl = `${this.apiUrl}/core/check_person/`;
    return this.http.post<any[]>(getPersoneUrl, data);
  }

  getContactSubject() {
    const url = `${this.apiUrl}/component/getContactSubject`;
    return this.http.get<any>(url).catch(this.handleError);
  }
  getCountrySubject() {
    const url = `${this.apiUrl}/component/getCountrySubject`;
    return this.http.get<any>(url).catch(this.handleError);
  }

  // getTermCondition
  getTermConditions(language): Observable<any> {
    const url = `${this.apiUrl}/component/getTermConditions/${language}`;
    return this.http.get<any>(url).catch(this.handleError);
  }

  // Language
  getLanguage(): Observable<any> {
    const url = `${this.apiUrl}/component/language`;
    return this.http.get<any>(url);
  }

  // check status
  checkStatus(data): Observable<any> {
    const url = `${this.apiUrl}/check_status/status_enquiry`;
    return this.http.post(url, data);
  }

  retrieveApplication(data): Observable<any> {
    const url = `${this.apiUrl}/retrieve_application/send_application_id`;
    return this.http.post(url, data);
  }

  uploadHoteldoc(uploadData): Observable<any> {
    return this.http.post(this.uploadHoteldocUrl, uploadData);
  }
  uploadFlightdoc(uploadData): Observable<any> {
    const uploadFlightdocUrl = `${this.apiUrl}/upload_flight_file`;
    return this.http.post(uploadFlightdocUrl, uploadData);
  }
  uploadThirdVisadoc(uploadData): Observable<any> {
    const url = `${this.apiUrl}/upload_third_visa_file`;
    return this.http.post(url, uploadData);
  }
  uploadGuarantordoc(uploadData): Observable<any> {
    const url = `${this.apiUrl}/upload_guarantor_file`;
    return this.http.post(url, uploadData);
  }
  uploadBankStatementdoc(uploadData): Observable<any> {
    const url = `${this.apiUrl}/upload_bank_statement_file`;
    return this.http.post(url, uploadData);
  }
  // Dealer
  dealerLogin(data): Observable<any> {
    const url = `${this.apiUrl}/dealer/login`;
    return this.http.post(url, data);
  }

  // Article
  getArticle(): Observable<any> {
    const url = `${this.apiUrl}/component/article_list`;
    return this.http.get<any>(url);
  }
  getArticleDetail(title, lang): Observable<any> {
    const url = `${this.apiUrl}/component/article_detail/${title}/${lang}`;
    return this.http.get<any>(url);
  }

  getUserRejectedById(data): Observable<any> {
    const url = `${this.apiUrl}/re_apply/getStatus`;
    return this.http.post(url, data);
  }

  getApplicationInfo(application_id): Observable<any> {
    const data = { application_id: application_id };
    const authInfo = JSON.parse(
      this.localStorage.getItem(environment.localAuthenInfo)
    );
    this.headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      "x-access-token": authInfo.token,
    });
    const url = `${this.apiUrl}/re_apply/getApplicationInfo`;
    return this.http.post(url, data, { headers: this.headers });
  }

  checkPayment(invoice_id): Observable<any> {
    const url = `${this.apiUrl}/payment/check_payment`;
    return this.http.post<any[]>(url, invoice_id);
  }

  checkRePay(invoice_id): Observable<any> {
    const url = `${this.apiUrl}/payment/check_repay`;
    return this.http.post<any[]>(url, invoice_id);
  }

  payNow(invoice_id): Observable<any> {
    const url = `${this.apiUrl}/payment/paynow`;
    return this.http.post<any[]>(url, invoice_id);
  }
  RePayNow(invoice_id): Observable<any> {
    const url = `${this.apiUrl}/payment/repay`;
    return this.http.post<any[]>(url, invoice_id);
  }

  // Info page
  getInfo(lang): Observable<any> {
    const url = `${this.apiUrl}/component/info/${lang}`;
    return this.http.get<any>(url);
  }
  // About page
  getAbout(lang): Observable<any> {
    const url = `${this.apiUrl}/component/about/${lang}`;
    return this.http.get<any>(url);
  }
  getPaymentMethods(): Observable<any> {
    const url = `${this.apiUrl}/component/payment_method`;
    return this.http.get<any>(url);
  }

  save_partner_access(partner_id): Observable<any> {
    const data = { partner_id: partner_id };
    const postUrl = `${this.apiUrl}/partner/save_access`;
    return this.http.post<any>(postUrl, data).catch(this.handleError);
  }

  // Partner
  partnerLogin(data): Observable<any> {
    const url = `${this.apiUrl}/partner/login`;
    return this.http.post(url, data);
  }
  getAllData(partner_id): Observable<any> {
    const data = { partner_id: partner_id };
    const authInfo = JSON.parse(this.localStorage.getItem("partnerAuthen"));
    this.headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      "x-access-token": authInfo.token,
    });
    const url = `${this.apiUrl}/partner/getData`;
    return this.http.post(url, data, { headers: this.headers });
  }
  getAllVisit(partner_id): Observable<any> {
    const data = { partner_id: partner_id };
    const authInfo = JSON.parse(this.localStorage.getItem("partnerAuthen"));
    this.headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      "x-access-token": authInfo.token,
    });
    const url = `${this.apiUrl}/partner/getVisit`;
    return this.http.post(url, data, { headers: this.headers });
  }

  isLoggedIn() {
    const authenInfo = JSON.parse(this.localStorage.getItem("partnerAuthen"));
    return authenInfo != null;
  }
  getExceptMessage(): Observable<any> {
    const url = `${this.apiUrl}/component/except_message`;
    return this.http.get<any>(url);
  }

  // Download pdf
  downloadPDF(data): Observable<any> {
    const url = `${this.apiUrl2}/approval-letter`;
    return this.http.post(url, data, {
      responseType: "blob",
      headers: new HttpHeaders().append("Content-Type", "application/json"),
    });
  }

  newDownloadPDF(data): Observable<any> {
    const url = `${this.apiUrl}/download-approval`;
    return this.http.post(url, data, {
      responseType: "blob",
      headers: new HttpHeaders().append("Content-Type", "application/json"),
    });
  }

  // Calculate price
  getCalculatePrice(country_id): Observable<any> {
    const data = { country_id: country_id };
    const getPriceUrl = `${this.apiUrl}/component/price_calculate`;
    return this.http.post<any[]>(getPriceUrl, data);
  }
  // get status can apply
  getIsCloseApply(): Observable<any> {
    const url = `${this.apiUrl}/component/close_apply_status`;
    return this.http.get<any>(url);
  }

  login(form: any): Observable<any> {
    const url = `${this.apiUrl}/secure/login`;
    return this.http.post<any>(url, form);
  }

  CheckDuplicate(form: any) {
    const url = `${this.apiUrl2}/check-duplicate`;
    return this.http.post<any>(url, form);
  }

  CheckArrivalDate(form: any) {
    const url = `${this.apiUrl2}/check-arrival`;
    return this.http.post<any>(url, form);
  }

  newCheckDuplicate(form: any) {
    const url = `${this.apiUrl}/check-duplicate`;
    return this.http.post<any>(url, form);
  }

  newCheckArrivalDate(form: any) {
    const url = `${this.apiUrl}/check-arrival`;
    return this.http.post<any>(url, form);
  }

  getFAQGroup(language) {
    const url = `${this.apiUrl2}/component/getFAQ/${language}`;
    return this.http.get<any>(url).catch(this.handleError);
  }

  ocrMRZDetection(image) {
    return this.http.post<any>(this.ocrMRZUrl, image);
  }

  async uploadProofFileService(data: FormData) {
    type Response = {
      status: string;
      message: string;
      name: string;
    };
    const url = `${this.apiUrl2}/prooffile_upload`;
    try {
      const response = await this.http.post<Response>(url, data).toPromise();
      return response;
    } catch (e) {
      console.log(e);
    }
  }

  addUserSession(session_id: any) {
    const data = {
      session_id,
    };
    const url = `${this.apiUrl2}/user_session`;
    return this.http.post<any>(url, data);
  }

  updateUserSession(data: any) {
    const url = `${this.apiUrl2}/update/user_session`;
    return this.http.post(url, data);
  }

  continueReviewService(data: any) {
    return this.http.post(`${this.apiUrl2}/continue-review`, data);
  }

  payNowV2Service(data: any) {
    const url = `${this.paymentUrl}/payment/paynow`;
    return this.http.post<any>(url, data);
  }

  newBcelService(data: any) {
    return this.http.post<any>(`${this.paymentUrl}/payment/bcel`, data);
  }

  getBase64ImageService(imageName: string, image: "photo" | "passport") {
    const data = {};
    data[`${image}_name`] = imageName;
    return this.http.post<string>(`${this.apiUrl2}/get_${image}`, data);
  }

  getMaintainance() {
    return this.http.get(`${this.apiUrl2}/maintainance`);
  }
}
