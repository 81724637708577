<app-nav></app-nav>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white">Please Wait...</p>
</ngx-spinner>
<!-- Main form -->
<main class="page page-security-verification" *ngIf="!have_result">
  <!-- <aside class="header-image-half"></aside> -->
  <div *ngIf="maintenance" class="maintenance">
    <app-countdown [date_maintenance]="date_maintenance"></app-countdown>
  </div>

  <div *ngIf="!maintenance">
    <div class="container content-section">
      <h1 class="content-section-title text-center text-uppercase" style="margin-top: 140px" translate="status_enquiry">
        Status Enquiry
      </h1>
      <form #checkForm="ngForm" id="checkForm" (ngSubmit)="checkForm.form.valid && onSubmit(checkForm)" novalidate>
        <div class="row">
          <div class="col-lg-6 offset-lg-3">
            <input id="application_id" required [(ngModel)]="model_verify.application_id" #application="ngModel"
              pattern="[a-zA-Z0-9]*" class="form-control form-control-lg" [ngClass]="{
                'is-invalid': checkForm.submitted && application.invalid
              }" name="application_id" [placeholder]="'Enter Application ID' | translate" translate />
            <div class="invalid-feedback">
              <div *ngIf="application.errors?.required">
                Application ID is required
              </div>
              <div *ngIf="application.errors?.pattern">
                Application ID invalid
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 offset-lg-3">
            <input id="passport_number" required [(ngModel)]="model_verify.passport_number" #passport="ngModel"
              pattern="[a-zA-Z0-9]*" class="form-control form-control-lg" [ngClass]="{
                'is-invalid': checkForm.submitted && passport.invalid
              }" name="passport_number" [placeholder]="'Enter Passport Number' | translate" />
            <div class="invalid-feedback">
              <div *ngIf="checkForm.submitted && passport.errors?.required">
                Passport number is required
              </div>
              <div *ngIf="checkForm.submitted && passport.errors?.pattern">
                Passport number invalid
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 offset-lg-3">
            <div class="border img-rounded p-2 captcha">
              <img *ngIf="captchaImage" [src]="captchaImage" alt="" />
              &nbsp;
              <i class="fa fa-refresh" (click)="reloadCaptcha()" style="cursor: pointer; color: #1a77ba"></i>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 offset-lg-3">
            <input autocomplete="off" (keyup)="message = ''" type="text" pattern="[0-9]+" maxlength="10"
              id="verify_code" required [ngClass]="{
                'is-invalid': checkForm.submitted && verify_codes.invalid
              }" [(ngModel)]="model_verify.verify_code" #verify_codes="ngModel"
              [placeholder]="'Enter security code' | translate" name="verify_code" class="form-control form-control-lg"
              id="verify_code" />
            <div *ngIf="
                (checkForm.submitted && verify_codes.invalid) ||
                this.isNotSuccess
              " class="invalid-feedback">
              <div *ngIf="verify_codes.invalid">Security code is required</div>
            </div>
            <div style="font-size: 80%" class="text-danger" *ngIf="verify_codes.valid && this.isNotSuccess">
              <p class="text-dark" [innerHTML]="message"></p>
            </div>
          </div>
        </div>
        <!-- TODO: ADD CAPTCHA -->

        <div class="row">
          <div class="col-lg-6 offset-lg-3 text-center mt-1">
            <button type="submit" class="btn btn-primary" translate>
              Check
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</main>
<!-- Pedding Status -->
<main class="page page-status-enquiry" *ngIf="have_result && rejected">
  <!-- * -->
  <aside class="header-image-half"></aside>
  <div class="container content-section">
    <h1 class="content-section-title text-right mt-0" translate="status_enquiry">
      STATUS ENQUIRY
    </h1>
    <hr />
    <div class="row mt-5">
      <div class="col-lg-5 text-center column">
        <strong translate>YOUR VISA STATUS</strong>
        <!-- <p>{{action_status}}</p> -->
        <div class="modal-check-visa-status-approval pending" translate>
          PENDING
        </div>
        <div *ngIf="action_status === 'Active'" translate="pending_text">
          <p class="text-dark" [innerHTML]="status_message"></p>
        </div>
      </div>
      <div class="col-lg-6 offset-lg-1 column">
        <div class="row">
          <div class="col-7 label" translate="app_id">Application ID:</div>
          <div class="col-5">{{ model_verify.application_id }}</div>
        </div>
        <div class="row mt-1">
          <div class="col-7 label" translate="chk_ppn">Passport No:</div>
          <div class="col-5">{{ model_verify.passport_number }}</div>
        </div>
        <div class="row mt-1">
          <div class="col-7 label" translate="ccd">Case creation date:</div>
          <div class="col-5">{{ created_date }}</div>
        </div>
        <div class="row mt-1">
          <div class="col-7 label" translate="sud">Status updated date:</div>
          <div class="col-5">{{ updated_date }}</div>
        </div>
      </div>
    </div>

    <footer class="text-muted mt-lg-4">
      <div class="pl-5 font-weight-light" *ngIf="action_status == 'NOT_PASS' || action_status == 'Rejected'">
        <li class="text-secondary" style="font-size: 14px" *ngFor="let reason of reasons">
          {{ reason.reason_rejected }}
        </li>
        <br />
        <li class="text-secondary" style="font-size: 14px; list-style: none">
          <b translate="adcomment">Additional Comment:</b> {{ comment }}
        </li>
      </div>
      <hr />

      <button *ngIf="
          rejected &&
          (action_status == 'NOT_PASS' || action_status == 'Rejected') &&
          can_edit == true
        " class="btn btn-warning btn-sm pull-left" (click)="editApplication()">
        <i class="fa fa-edit"></i>
        <span translate="edit">Edit</span>
      </button>
      <button class="btn btn-primary btn-sm pull-right" (click)="back()">
        <i class="fa fa-chevron-left"></i>
        <span translate="back">Back</span>
      </button>
    </footer>
  </div>
</main>
<!-- Approval -->
<main class="page page-status-enquiry" *ngIf="have_result && approved">
  <aside class="header-image-half"></aside>
  <div class="container content-section">
    <h1 class="content-section-title text-right mt-0" translate="status_enquiry">
      STATUS ENQUIRY
    </h1>
    <hr />
    <div class="row mt-5">
      <div class="col-lg-5 text-center column mb-3">
        <strong>YOUR VISA STATUS</strong>
        <div class="modal-check-visa-status-approval approved" translate>
          APPROVED
        </div>
        <p class="text-dark" translate [innerHTML]="status_message"></p>

        <div class="text-center">
          <button [disabled]="clicked" (click)="downloadPdf(); clicked = true" class="btn btn-success">
            <i class="fa fa-arrow-circle-down" aria-hidden="true"></i>Download
          </button>
        </div>
      </div>
      <div class="col-lg-6 offset-lg-1 column">
        <div class="row">
          <div class="col-7 label" translate="app_id">Application ID:</div>
          <div class="col-5">{{ model_verify.application_id }}</div>
        </div>
        <div class="row mt-1">
          <div class="col-7 label" translate="chk_ppn">Passport No:</div>
          <div class="col-5">{{ model_verify.passport_number }}</div>
        </div>
        <div class="row mt-1">
          <div class="col-7 label" translate="ccd">Case creation date:</div>
          <div class="col-5">{{ created_date }}</div>
        </div>
        <div class="row mt-1">
          <div class="col-7 label" translate="sud">Status updated date:</div>
          <div class="col-5">{{ updated_date }}</div>
        </div>
        <div class="row mt-5">
          <div class="col-12 text-center">
            <button class="btn btn-primary btn-sm" (click)="back()">
              <i class="fa fa-chevron-left"></i>
              <span translate="back">Back</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<!-- Banned -->
<main class="page page-status-enquiry" *ngIf="have_result && banned">
  <aside class="header-image-half"></aside>

  <div class="container content-section">
    <h1 class="content-section-title text-right mt-0" translate="status_enquiry">
      STATUS ENQUIRY
    </h1>

    <hr />

    <div class="row mt-5">
      <div class="col-lg-5 text-center column">
        <strong translate>YOUR VISA STATUS</strong>
        <div class="modal-check-visa-status-approval rejected" translate>
          REJECTED
        </div>
      </div>
      <div class="col-lg-6 offset-lg-1 column">
        <div class="row">
          <div class="col-7 label" translate="app_id">Application ID:</div>
          <div class="col-5">{{ model_verify.application_id }}</div>
        </div>
        <div class="row mt-1">
          <div class="col-7 label" translate="chk_ppn">Passport No:</div>
          <div class="col-5">{{ model_verify.passport_number }}</div>
        </div>
        <div class="row mt-1">
          <div class="col-7 label" translate="ccd">Case creation date:</div>
          <div class="col-5">{{ created_date }}</div>
        </div>
        <div class="row mt-1">
          <div class="col-7 label" translate="sud">Status updated date:</div>
          <div class="col-5">{{ updated_date }}</div>
        </div>
      </div>
    </div>

    <footer class="text-muted mt-lg-4">
      <div class="pl-5 font-weight-light">
        <!-- <li class="text-secondary" *ngFor="let reason of reasons" style="font-size: 14px;"> {{reason.reason_rejected}}
        </li>
        <br> -->
        <!-- <li class="text-secondary" style="font-size: 14px;list-style: none;">
          <b translate="adcomment">Additional Comment:</b> {{comment}}
        </li> -->
      </div>
      <button class="btn btn-primary btn-sm pull-right" (click)="back()">
        <i class="fa fa-chevron-left"></i>
        <span translate="back">Back</span>
      </button>
    </footer>
  </div>
</main>

<!-- Pedding Status -->
<main class="page page-status-enquiry" *ngIf="have_result && processing">
  <!-- * -->
  <aside class="header-image-half"></aside>
  <div class="container content-section">
    <h1 class="content-section-title text-right mt-0" translate="status_enquiry">
      STATUS ENQUIRY
    </h1>
    <hr />
    <div class="row mt-5">
      <div class="col-lg-5 text-center column">
        <strong>YOUR VISA STATUS</strong>
        <div class="modal-check-visa-status-approval pending" translate>
          PENDING
        </div>
        <p class="text-dark" translate="pending_text" [innerHTML]="status_message"></p>
      </div>
      <div class="col-lg-6 offset-lg-1 column">
        <div class="row">
          <div class="col-7 label" translate="app_id">Application ID:</div>
          <div class="col-5">{{ model_verify.application_id }}</div>
        </div>
        <div class="row mt-1">
          <div class="col-7 label" translate="chk_ppn">Passport No:</div>
          <div class="col-5">{{ model_verify.passport_number }}</div>
        </div>
        <div class="row mt-1">
          <div class="col-7 label" translate="ccd">Case creation date:</div>
          <div class="col-5">{{ created_date }}</div>
        </div>
        <div class="row mt-1">
          <div class="col-7 label" translate="sud">Status updated date:</div>
          <div class="col-5">{{ updated_date }}</div>
        </div>
      </div>
    </div>

    <footer class="text-muted mt-lg-4">
      <button class="btn btn-primary btn-sm pull-right" (click)="back()">
        <i class="fa fa-chevron-left"></i>
        <span translate="back">Back</span>
      </button>
    </footer>
  </div>
</main>

<!-- Unpaid Status -->
<main class="page page-status-enquiry" *ngIf="have_result && unpaid">
  <!-- * -->
  <aside class="header-image-half"></aside>
  <div class="container content-section">
    <h1 class="content-section-title text-right mt-0" translate="status_enquiry">
      STATUS ENQUIRY
    </h1>
    <hr />
    <div class="row mt-5">
      <div class="col-lg-5 column">
        <div class="text-center">
          <strong translate>YOUR VISA STATUS</strong>
          <div class="modal-check-visa-status-approval pending" translate>
            UNPAID
          </div>
        </div>
        <div class="row">
          <app-paynow [invoiceId]="invoice_id" payFrom="check_status"></app-paynow>
        </div>
      </div>
      <div class="col-lg-6 offset-lg-1 column">
        <div class="row">
          <div class="col-7 label" translate="app_id">Application ID:</div>
          <div class="col-5">{{ model_verify.application_id }}</div>
        </div>
        <div class="row mt-1">
          <div class="col-7 label" translate="chk_ppn">Passport No:</div>
          <div class="col-5">{{ model_verify.passport_number }}</div>
        </div>
        <div class="row mt-1">
          <div class="col-7 label" translate="ccd">Case creation date:</div>
          <div class="col-5">{{ created_date }}</div>
        </div>
        <div class="row mt-1">
          <div class="col-7 label" translate="sud">Status updated date:</div>
          <div class="col-5">{{ updated_date }}</div>
        </div>
      </div>
    </div>

    <footer class="text-muted mt-lg-4">
      <button class="btn btn-primary btn-sm pull-right" (click)="back()">
        <i class="fa fa-chevron-left"></i>
        <span translate="back">Back</span>
      </button>
    </footer>
  </div>
</main>

<!-- Not found Status -->
<main class="page page-status-enquiry" *ngIf="have_result && notfound">
  <!-- * -->
  <aside class="header-image-half"></aside>
  <div class="container content-section">
    <h1 class="content-section-title text-right mt-0" translate="status_enquiry">
      STATUS ENQUIRY
    </h1>
    <hr />
    <div class="row mt-5">
      <div class="col-lg-5 column">
        <div class="text-center">
          <strong translate>YOUR VISA STATUS</strong>
          <div class="modal-check-visa-status-approval pending" translate="notfound">
            NOT FOUND
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="text-center text-dark">
              <p class="text-dark" [innerHTML]="status_message"></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer class="text-muted mt-lg-4">
      <button class="btn btn-primary btn-sm pull-right" (click)="back()">
        <i class="fa fa-chevron-left"></i>
        <span translate="back">Back</span>
      </button>
    </footer>
  </div>
</main>

<!-- Cancelled Status -->
<main class="page page-status-enquiry" *ngIf="have_result && cancelled">
  <!-- * -->
  <aside class="header-image-half"></aside>
  <div class="container content-section">
    <h1 class="content-section-title text-right mt-0" translate="status_enquiry">
      STATUS ENQUIRY
    </h1>
    <hr />
    <div class="row mt-5">
      <div class="col-lg-5 column">
        <div class="text-center">
          <strong translate>YOUR VISA STATUS</strong>
          <div class="modal-check-visa-status-approval rejected" translate>
            CANCALLED
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="text-center text-dark font-weight-light">
              <p class="text-dark" [innerHTML]="status_message"></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer class="text-muted mt-lg-4">
      <button class="btn btn-primary btn-sm pull-right" (click)="back()">
        <i class="fa fa-chevron-left"></i>
        <span translate="back">Back</span>
      </button>
    </footer>
  </div>
</main>

<app-footer></app-footer>
