import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
  Inject,
} from "@angular/core";
import { FormDataService } from "src/app/services/form-data.service";
import { FormData, Person, CustomerImage } from "../../models/formData";
import { RestService } from "src/app/services/rest.service";
import { NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { NgbDateCustomParserFormatter } from "src/app/functions/ngb-date-fr-parser-formatter";
import { ApplicationComponent } from "../application/application.component";
import Swal from "sweetalert2";
import { Location } from "@angular/common";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { AppComponent } from "src/app/app.component";
import { _do } from "rxjs-compat/operator/do";
import * as moment from "moment";
import { CountCartService } from "src/app/services/count-cart.service";
import { CookiesService } from "src/app/services/cookies.service";
import { PopUpComponent } from "../pop-up/pop-up.component";
import { LOCAL_STORAGE } from "@ng-toolkit/universal";

@Component({
  selector: "app-application-review",
  templateUrl: "./application-review.component.html",
  styleUrls: ["./application-review.component.css"],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
  ],
})
export class ApplicationReviewComponent implements OnInit {
  @Input() formData: FormData;
  passportSrc: string;
  person: Person;
  custImage: CustomerImage;

  countryBirth: any;
  currentToken: any;

  loading = false;

  dob;
  issue_date;
  expired_date;
  intended_date;
  update_success = false;
  data = {
    session_id: "",
    input_id: "",
    input_value: "",
  };

  constructor(
    private app: AppComponent,
    private router: Router,
    private route: ActivatedRoute,
    private rest: RestService,
    private formDataService: FormDataService,
    private _location: Location,
    private cookie: CookiesService,
    private translateService: TranslateService,
    @Inject(LOCAL_STORAGE) private localStorage: any
  ) {}

  ngOnInit() {
    this.person = this.formDataService.getFormData();
    this.custImage = this.formDataService.getFormData();
    const authInfo = JSON.parse(
      localStorage.getItem(environment.localAuthenInfo)
    );
    if (authInfo) {
      this.person.token = authInfo.token;
      if (this.person.firstName === "") {
        this.router.navigate(["/apply"]);
      }
    } else {
      this.router.navigate(["/apply"]);
    }
    const m_dob = new Date(
      this.person.DOB.year,
      this.person.DOB.month - 1,
      this.person.DOB.day
    );
    const is = new Date(
      this.person.issueDate.year,
      this.person.issueDate.month - 1,
      this.person.issueDate.day
    );
    const ex = new Date(
      this.person.expiredDate.year,
      this.person.expiredDate.month - 1,
      this.person.expiredDate.day
    );
    const intd = new Date(
      this.person.intendedDate.year,
      this.person.intendedDate.month - 1,
      this.person.intendedDate.day
    );

    this.dob = moment(m_dob).format("DD-MM-YYYY");
    this.issue_date = moment(is).format("DD-MM-YYYY");
    this.expired_date = moment(ex).format("DD-MM-YYYY");
    this.intended_date = moment(intd).format("DD-MM-YYYY");
    this.person.currentActiveForm = "2";
    console.log("This person info:", this.person);
  }
  onActivate(event) {
    window.scroll(0, 0);
  }
  verifyToken(credential) {
    this.rest.verifyToken(credential).subscribe((result) => {
      if (result.responseStatus === "fail") {
        this.router.navigate(["/apply"]);
      } else {
      }
    });
  }
  goBack(e: any) {
    this._location.back();

    this.data.session_id = this.localStorage.getItem("session_id");
    this.data.input_id = e.target.id;
    this.data.input_value = "back_to_application";

    // this.rest.updateUserSession(this.data).subscribe((res) => {
    //   console.log("Result Update Session:", res);
    // });
  }
  goHome() {
    this.router.navigate(["/index"]);
  }
  submitApplicationForm(e: any) {
    this.loading = true;

    this.person.imgPass = "";
    this.person.imgPhoto = "";
    this.person.proof_file_preview = "";

    const cookie = this.cookie.getCookie("P_ID");
    if (cookie !== "") {
      this.person.partner_id = cookie;
    }

    console.log("Final Person Data: ", this.person);
    this.rest.submitApplicationForm(this.person).subscribe(
      (result) => {
        this.loading = false;

        if (result.status === "success" && result.submit_type === "insert") {
          this.formDataService.resetFormData();
          this.router.navigate(["/cart"], { replaceUrl: true });

          this.data.session_id = this.localStorage.getItem("session_id");
          this.data.input_id = e.target.id;
          this.data.input_value = "continue_to_Cart";

          // this.rest.updateUserSession(this.data).subscribe((res) => {
          //   console.log("Result Update Session:", res);
          // });
        } else if (
          result.status === "success" &&
          result.submit_type === "update"
        ) {
          this.update_success = true;
          this.formDataService.resetFormData();
          this.data.session_id = this.localStorage.getItem("session_id");
          this.data.input_id = e.target.id;
          this.data.input_value = "continue_to_Cart";

          // this.rest.updateUserSession(this.data).subscribe((res) => {
          //   console.log("Result Update Session:", res);
          // });
        } else if (result.status === "error") {
          return Swal.fire({
            title: "Warning",
            text: result.message,
            type: "warning",
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonColor: "#E63714",
            cancelButtonText: "OK",
          });
        } else {
          this.router.navigate(["/checkstatus"]);
        }
      },
      (err) => {
        if (err.auth === false) {
          this.router.navigate(["/apply"]);
        }
      }
    );
  }
}
