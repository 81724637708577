import { Component, OnInit, Input, ViewChild, Inject, ElementRef } from "@angular/core";
import {
  NgbDatepickerConfig,
  NgbDateParserFormatter,
} from "@ng-bootstrap/ng-bootstrap";
import { NgbDateCustomParserFormatter } from "src/app/functions/ngb-date-fr-parser-formatter";
import { RestService } from "src/app/services/rest.service";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import * as moment from "moment";
import { Person } from "src/app/models/formData";
import { LOCAL_STORAGE } from "@ng-toolkit/universal";
import { saveAs } from "file-saver";
import { ServiceSEOService } from "src/app/services/service-seo.service";
import { AppComponent } from "src/app/app.component";
import Swal from "sweetalert2";
import { MatRadioChange } from "@angular/material/radio";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-retrieve-application',
  templateUrl: './retrieve-application.component.html',
  styleUrls: ['./retrieve-application.component.css'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
  ],
})
export class RetrieveApplicationComponent implements OnInit {
  maintenance: boolean;
  date_maintenance: string;
  captchaImage: any;
  isImageLoading: boolean;
  suspenseDownload: boolean = false;
  email;
  passport_number;
  can_edit = false;
  model_verify: any = {};
  message: string;
  isNotSuccess: boolean;
  have_result: boolean;
  isSuccess: boolean;
  @Input() formData: FormData;
  @ViewChild("client_email", { static: false }) client_email: ElementRef;
  isMail: string;
  isGmail: boolean = false;
  isYahooMail: boolean = false;
  your_email: string;

  node_static_url = environment.node_static_url;
  person: Person;
  mDataArrayPerson: any[];
  invoice_id = 0;
  total = 0;
  countTotal = 0;
  isEmptyCart = false;
  timeLeft = 0;
  cancelled: boolean;
  action_status: any;
  clicked = false;
  status_message: any;
  my_uuid = "";
  constructor(
    @Inject(LOCAL_STORAGE) private localStorage: any,
    config: NgbDatepickerConfig,
    private router: Router,
    private rest: RestService,
    private seo: ServiceSEOService,
    private app: AppComponent,
    public spinner: NgxSpinnerService
  ) {
    this.seo.addMetaTags();
    config.minDate = { year: 1900, month: 1, day: 1 };
  }

  ngOnInit() {
    this.getImageFromService();
    this.getMaintenanceService();
  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        this.captchaImage = reader.result;
      },
      false
    );

    if (image) {
      reader.readAsDataURL(image);
    }
  }
  getImageFromService() {
    this.isImageLoading = true;
    this.rest.getCaptchaImage().subscribe(
      (data) => {
        this.createImageFromBlob(data);
        this.isImageLoading = false;
      },
      (error) => {
        this.isImageLoading = false;
        console.log(error);
      }
    );
  }

  getMaintenanceService() {
    // this.rest.getMaintenance().subscribe(
    //   (data) => {
    //     this.maintenance = data.maintenance;
    //     this.date_maintenance = data.date_maintenance;
    //   },
    //   (error) => {
    //     console.log(error);
    //   }
    // );
  }

  reloadCaptcha() {
    this.getImageFromService();
  }

  onSubmit(form: any) {
    this.clicked = false;
    if (form.valid) {
      this.rest
        .verifyRetrieveApplication(this.model_verify)
        .subscribe((result) => {
          if (result.responseStatus === false) {
            this.isNotSuccess = true;
            this.message = "Security code is wrong!";
            this.getImageFromService();
          } else {
            this.getImageFromService();
            this.localStorage.setItem(
              environment.localAuthenInfo,
              JSON.stringify(result)
            );
            this.rest.retrieveApplication(this.model_verify).subscribe((rs) => {
              if (rs.status === "success") {
                const domain_email = this.model_verify.email.toLowerCase().split("@")[1];
                this.have_result = true;
                this.isSuccess = true;
                console.log("Result Status form Back End:", rs);
                this.your_email = this.model_verify.email;
                this.isMail = domain_email;
                this.isGmail = domain_email.includes("gmail");
                this.isYahooMail =
                  domain_email.includes("myyahoo") ||
                  domain_email.includes("yahoo") ||
                  domain_email.includes("ymail");
                this.localStorage.setItem("email", this.model_verify.email);
              } else {
                Swal.fire({
                  title: "Not Found",
                  text: rs.message,
                  type: "error",
                  showCancelButton: true,
                  showConfirmButton: false,
                  cancelButtonColor: "#6E7881",
                  cancelButtonText: "OK",
                });// 'success' | 'error' | 'warning' | 'info' | 'question'
              }
              if (this.app.SelectedLang == "en") {
                this.status_message = result.message;
              } else if (this.app.SelectedLang == "cn") {
                this.status_message = result.message_cn;
              } else if (this.app.SelectedLang == "fr") {
                this.status_message = result.message_fr;
              }
            });
          }
        });
    }

    console.log('this.model_verify', this.model_verify);
  }

  back() {
    this.have_result = false;
    this.model_verify.verify_code = "";
    this.clicked = false;
  }

  // DownLoad PDF
  // downloadPdf() {
  //   const app_id = this.model_verify.email;
  //   const passport_number = this.model_verify.passport_number;
  //   const verify_code = this.model_verify.verify_code;
  //   const data_download = {
  //     application_id: app_id,
  //     passport_number: passport_number,
  //     verify_code: verify_code,
  //     id: this.my_uuid,
  //   };
  //   this.spinner.show();
  //   this.rest.downloadPDF(data_download).subscribe(async (result) => {
  //     const filename = app_id;
  //     saveAs(result, this.my_uuid + ".pdf");
  //     this.spinner.hide();
  //   });

  //   // TODO: use in new user node (ts)
  //   // this.rest.newDownloadPDF(data_download).subscribe(async (result) => {
  //   //   const filename = app_id;
  //   //   if (result && !result.status) {
  //   //     this.clicked = false;
  //   //     this.spinner.hide();
  //   //     saveAs(result, this.my_uuid + ".pdf");
  //   //   }
  //   // });
  // }
  openInbox() {
    const domain_email = this.model_verify.email.toLowerCase().split("@")[1];
    let url = "";
    if (domain_email.includes("gmail")) {
      url = "https://mail.google.com/mail/u/0/#inbox";
    } else if (
      domain_email.includes("myyahoo") ||
      domain_email.includes("yahoo") ||
      domain_email.includes("ymail")
    ) {
      url = `https://mail.yahoo.${domain_email.split(".").slice(1).join(".") || "com"
        }`;
    } else if (
      domain_email.includes("outlook") ||
      domain_email.includes("hotmail")
    ) {
      url = "https://outlook.live.com/mail/0/";
    } else if (domain_email.split(".")[0].includes("qq")) {
      url = `https://mail.qq.com/`;
    }
    window.open(url, "_blank");
  }
}
