import { Component, OnInit, Inject } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { EventEmitterService } from 'src/app/services/event-emitter.service';
import { Router } from '@angular/router';
import { ServiceSEOService } from 'src/app/services/service-seo.service';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.css']
})
export class TermsConditionsComponent implements OnInit {

  constructor(
    private seo: ServiceSEOService,
    private rest: RestService, private eventEmitterService: EventEmitterService, private router: Router   ) {
      this.seo.addMetaTags();
    }
  model: string;
  ngOnInit() {
    if (this.eventEmitterService.subsVar === undefined) {
      this.eventEmitterService.subsVar = this.eventEmitterService.
      invokeTermConditionsComponentFunction.subscribe((language: string) => {
        if (this.router.url === '/term') {
          this.getTermConditions(language);
        }
      });
    }


    const language = localStorage.getItem('selectedLang');
    this.getTermConditions(language);
  }

  getTermConditions(language: string) {
    this.rest.getTermConditions(language).subscribe(result => {
      if (result.length > 0) {
      this.model = result[0].term_detail;
      }
    });
  }

}
